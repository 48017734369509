import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Button, Grid, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'Utils/Yup';
import { Btn, InputController, setProgress } from 'Components';
import { countries_list } from 'Constants/CountryArray';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { USERPROFILE } from 'Constants/QueriesKeys';
import { LocalStorage } from 'Utils/LocalStorage';
import { toast } from 'react-toastify';
import ProfileImageController from 'Components/FormControls/Controllers/ProfileImageController';
import { useDispatch } from 'react-redux';

const storage = new LocalStorage();
const SAVE_BTN = 'SAVE_BTN'

const useStyle = makeStyles((theme) => ({
    title: {
        fontWeight: '600',
        fontSize: '30px',
        lineHeight: '36px',
        color: '#000022',
    },
    tabContainer: {
        padding: "50px"
    },
    tabs: {
        fontSize: '18px !important',
        lineHeight: '22px',
        textTransform: "capitalize"
    },
    addBtn: {
        color: '#fff',
        backgroundColor: '#133FE3 !important',
        borderRadius: '8px',
        padding: '8px 34px'
    },
    cancelBtn: {
        color: '#133FE3 !important',
        borderRadius: '8px',
        background: '#fff',
        border: '1px solid #133FE3',
        padding: '6px 25px',
        '&:hover': {
            background: '#fff',
        }
    },
    logoImage: {
        width: '100%',
        auto: 'auto',
        borderRadius: '25%'
    }
}));

const schema = yup.object().shape({

});


const Profile = () => {
    const classes = useStyle();
    const { t } = useTranslation();
    const [value, setValue] = useState('1');
    const companyId = storage.get("user")?._id
    const [disabled, setDisabled] = useState(false)
    const [profileRefetch, setProfileRefetch] = useState(true)

    const dispatch = useDispatch();

    const form = useForm({
        resolver: yupResolver(schema),
    });
    const { control, handleSubmit, setError, reset, errors } = form;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const companyDetails = storage.get('companyDetails');

    const profileDetailsQuery = useQuery([USERPROFILE], () =>
        http.get(`/api/v1/company/${companyId}`, {}).then(({ data }) => {
            setProfileRefetch(false);
            dispatch({
                type: 'SET_COMPANY_DETAILS',
                companyDetails: data?.data?.data,
            });
            reset({
                images: data?.data?.profilePicture,
                firstName: data.data?.firstName,
                lastName: data.data?.lastName,
                email: data.data?.email,
                mobileNo: data.data?.mobileNo,
                countryCode: data.data?.countryCode,
            });
            return data;
        }),
        {
            enabled: profileRefetch ? true : false,
        },
    );
    const handleFormSubmit = async (values) => {
        setDisabled(true)
        setProgress(SAVE_BTN, true);
        let formData = new FormData();

        if(values?.profilePicture?.length) {
            formData.append("attachment", values?.profilePicture[0]);
            try {
                await http.post(`/api/v1/file-upload`, formData)
                    .then(async (res) => {
                        values['profilePicture'] = res?.data?.data[0]?.location;
                        delete values?.images;
                        try {
                            await http
                                .put(`/api/v1/company/${companyId}`, { ...values })
                                .then(async (res) => {
                                    storage.set("companyDetails", res?.data?.data);
                                    dispatch({
                                        type: 'SET_COMPANY_DETAILS',
                                        companyDetails: res?.data?.data,
                                    });
                                    toast.success('Profile Updated Successfully');
                                })
                                .catch((res) => {
                                    toast.error(res?.response?.data?.message);
                                });
                        } catch (res) {
                            toast.error(res?.response?.data?.message);
                        }
                    });
            } catch (res) {
                toast.error(res?.response?.data?.message)
            }
        }
        else {
            values['profilePicture'] = values?.images;
            delete values?.images;
            try {
                await http
                    .put(`/api/v1/company/${companyId}`, { ...values })
                    .then(async (res) => {
                        storage.set("companyDetails", res?.data?.data);
                        toast.success('Profile Updated Successfully');
                        dispatch({
                            type: 'SET_COMPANY_DETAILS',
                            companyDetails: res?.data?.data,
                        });
                    })
                    .catch((res) => {
                        toast.error(res?.response?.data?.message);
                    });
            } catch (res) {
                toast.error(res?.response?.data?.message);
            }
        }
        setDisabled(false)
        setProgress(SAVE_BTN, false);
    }


    return (
        <Grid container>
            <Grid item xs={12}>
                <h3 className={classes.title}>{t('profile.profile')}</h3>
            </Grid>
            <Grid item xs={12}>
                <form className="space-y-4" onSubmit={handleSubmit(handleFormSubmit)}>
                    <Box sx={{ width: '100%', typography: 'body1' }} className={classes.tabContainer}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label={t('profile.generalInfo')} value="1" className={classes.tabs} />
                                </TabList>
                            </Box>
                            <TabPanel value="1" style={{ padding: "30px 0" }}>
                                <Grid container spacing={4}>
                                    <Grid item style={{ marginBottom: '10px' }}>
                                        <ProfileImageController
                                            filesLimit={1}
                                            acceptedFiles
                                            form={form}
                                            errors={errors}
                                            control={control}
                                            name='profilePicture'
                                            image={companyDetails?.profilePicture}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputController
                                            control={control}
                                            fullWidth
                                            name="firstName"
                                            label={t('formLabels.firstName')}
                                            style={{ marginBottom: '9px' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputController
                                            control={control}
                                            fullWidth
                                            name="lastName"
                                            label={t('formLabels.lastName')}
                                            style={{ marginBottom: '9px' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputController
                                            control={control}
                                            fullWidth
                                            name="email"
                                            type="email"
                                            label={t('formLabels.email')}
                                            style={{ marginBottom: '9px' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={2}>
                                                <InputController
                                                    control={control}
                                                    fullWidth
                                                    select
                                                    name="countryCode"
                                                    label={t('formLabels.countryCode')}
                                                    style={{ marginBottom: '9px',textAlign:'center' }}
                                                >
                                                    {countries_list?.map((item,index) => {
                                                        return (
                                                            <MenuItem value={item?.Dial} style={{ textAlign:'center' }} key={index}>
                                                                {`${item?.Dial}`}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </InputController>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <InputController
                                                    control={control}
                                                    fullWidth
                                                    type="tel"
                                                    name="mobileNo"
                                                    label={t('formLabels.phoneNo')}
                                                    style={{ marginBottom: '9px' }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </TabContext>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container justifyContent="flex-end" spacing={2}>
                                    <Grid item>
                                        <Button fullWidth={false} color="primary" variant='outlined' style={{ height: "100%" }}
                                            className={classes.cancelBtn} disabled={disabled}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Btn type="submit" fullWidth={false} name={SAVE_BTN} className={classes.addBtn}>
                                            Save
                                        </Btn>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Grid>
        </Grid>
    )
}

export default Profile