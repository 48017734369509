import { Card, Grid, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useHistory } from 'react-router-dom';
import { http } from 'Utils/Http/Http';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { ITEMSLIST } from 'Constants/QueriesKeys';

const useStyle = makeStyles((theme) => ({
  card: {
    padding: '10px',
    boxShadow: '0px 1px 16px rgba(189, 189, 189, 0.25)',
    border: '2px solid #EFEFF4',
    borderRadius: '10px',
  },
  mainDiv: {
    position: 'relative',
  },
  editIcon: {
    color: '#000',
    backgroundColor: '#fff',
    padding: '3px',
    borderRadius: '50px',
    position: 'absolute',
    zIndex: 1,
    right: '10px',
    marginTop: '10px',
    cursor: 'pointer',
  },
  menuImage: {
    textAlign: 'center',
    width: '100%',
    height: '225px',
    borderRadius: '10px',
    cursor: 'pointer'
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '16px'
  },
  time: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '14px',
    color: 'rgba(0, 0, 0, 0.6)'
  }
}));

const MenuCard = ({ item, langPref, setSelectedMenu, menuListRefetch, setAddMenuModal }) => {

  
  const history = useHistory();

  const classes = useStyle();

  const handleEdit = (e) => {
    e.preventDefault();
    setSelectedMenu(item)
    setAddMenuModal(true)
  }

  const categoryCounts = {};

  item?.categories.forEach(category => {
    const itemCount = category.items.length;
    categoryCounts[category._id] = (categoryCounts[category._id] || 0) + itemCount;
  })

  const totalItemsCount = Object.values(categoryCounts).reduce((sum, count) => sum + count, 0);


  const handleDisable = (event) => {
    try {
      http.put(`/api/v1/menu/${item?._id}`, { isEnabled: event.target.checked })
        .then(async (res) => {
          if (res?.data?.data?.isEnabled === true) {
            toast.success("Menu Enabled");
            menuListRefetch()
          } else {
            toast.success("Menu Disabled");
            menuListRefetch()
          }
        });
    } catch (res) {
      toast.error(res?.response?.data?.message)
    }
  }


  return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card className={classes.card}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={5}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className={classes.mainDiv}>
                  <div className={classes.editIcon}>
                    <EditOutlinedIcon onClick={handleEdit} />
                  </div>
                  <img src={item?.image} className={classes.menuImage} onClick={() => history.push(`/admin/menu/${item?._id}`)} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  spacing={5}
                >
                  <Grid item>
                    <h1 className={classes.title}>{item[langPref[0].title?.toLowerCase()]?.title?.length > 10  ? item[langPref[0].title?.toLowerCase()]?.title.slice(0, 10) + "..." : item[langPref[0].title?.toLowerCase()]?.title}</h1>
                  </Grid>
                  <Grid item>
                    <Switch checked={item?.isEnabled} onChange={handleDisable} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  spacing={5}
                >
                  <Grid item>
                    <h3 className={classes.itemCount}>{totalItemsCount ? totalItemsCount: 0} Item</h3>
                  </Grid>
                  <Grid item>
                    <h3 className={classes.time}>{item?.visibilityTime[0]?.start} - {item?.visibilityTime[0]?.end}</h3>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default MenuCard;
