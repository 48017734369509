import { ReactComponent as DashIcon}  from '../Assets/Icons/dash.svg';
import { ReactComponent as MenuIcon} from '../Assets/Icons/menu.svg';
import { ReactComponent as ThemeIcon} from '../Assets/Icons/theme.svg';
import {ReactComponent as SideDishIcon} from '../Assets/Icons/side-dish.svg';
import {ReactComponent as SettingsIcon} from '../Assets/Icons/settings.svg';
import {ReactComponent as QRCodeIcon} from '../Assets/Icons/qr-code.svg';
import {ReactComponent as SupportIcon} from '../Assets/Icons/support.svg';

export const NavLinks = [
  // {
  //   name: 'Dashboard',
  //   path: '/admin/dashboard',
  //   current: true,
  //   Icon: DashIcon,
  // },
  { name: 'Menu', path: '/admin/menu', current: true, Icon: MenuIcon },
  {
    name: 'Side Dish',
    path: '/admin/side-dish',
    current: true,
    Icon: SideDishIcon,
  },
  { name: 'Theme', path: '/admin/theme', current: true, Icon: ThemeIcon },
  { name: 'QR Code', path: '/admin/qr-code', current: true, Icon: QRCodeIcon },
  {
    name: 'Settings',
    path: '/admin/settings',
    current: true,
    Icon: SettingsIcon,
  },
];
