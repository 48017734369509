import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {
    FormControl,
    Grid,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import {
    Btn,
    ConfirmationDialog,
    InputController,
    setProgress,
} from 'Components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import yup from 'Utils/Yup';
import { handleError, http } from 'Utils/Http/Http';
import { LocalStorage } from 'Utils/LocalStorage';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

const storage = new LocalStorage();
const SAVE_BTN = 'SAVE_BTN';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '65%',
    borderRadius: '10px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const useStyle = makeStyles((theme) => ({
    card: {
        padding: '10px',
        boxShadow: 'none',
        border: '2px solid #EFEFF4',
        borderRadius: '5px',
    },
    heading: {
        color: '#1A1A1A',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '30px',
    },
    tagline: {
        color: '#767676',
        fontFamily: 'Inter',
        fontWeight: '400',
        fontStyle: 'normal',
        fontSize: '13px',
        lineHeight: '26px',
    },
    divider: {
        margin: '10px 0px 5px 0px',
    },
    time: {
        padding: '0px 15px 0px 15px',
    },
    autoFill: {
        color: '#133FE3',
        cursor: 'pointer',
    },
    spacing: {
        padding: '10px',
    },
    addBtn: {
        color: '#fff',
        backgroundColor: '#133FE3 !important',
        borderRadius: '8px',
        padding: '8px 34px'
      },
      cancelBtn: {
        color: '#133FE3 !important',
        borderRadius: '8px',
        background:'#fff',
        border: '1px solid #133FE3',
        padding: '6px 25px',
        '&:hover': {
          background:'#fff',
      }
      },
    languageSelect: {
        padding: '5px',
    },
    language: {
        border: '1px solid #D0D5DD',
        boxSizing: 'border-box',
        borderRadius: '8px',
    },
    radioBtn: {
        '& .MuiTypography-root': {
            fontFamily: 'Inter !important',
            fontStyle: 'normal !important',
            fontWeight: '400 !important',
            fontSize: '14px !important',
            lineHeight: '18px !important',
        },
        '& .MuiRadio-root': {
            color: '#D8D8D8',
        },
        '& .MuiRadio-root.Mui-checked': {
            color: '#000000',
        },
    },
    deleteBtn: {
        color: '#E31313',
        cursor: 'pointer'
    },
    timePicker: {
        '& .MuiOutlinedInput-root': {
            width: '150px',
            height: '50px',
            flexDirection: 'row-reverse !important'
        }
    },
    chooseFileBtn: {
        border: '1px solid #1967D2',
        padding: '5px 25px',
        textTransform: 'none'
    },
}));


const companyId = storage.get('user')?._id;

const schema = yup.object().shape({
});

const AddSubCategoryModal = ({
    open,
    handleClose,
    promptModal,
    setPromptModal,
    menuId,
    item,
    languages,
    subCategoryListRefetch,
    categoryId,
}) => {
    const classes = useStyle();
    const { t } = useTranslation();
    const history = useHistory();

    const storeData = useSelector((state) => state?.globalStore);

    const primaryLanguage = storeData?.primaryLanguage.charAt(0).toUpperCase() + storeData?.primaryLanguage.slice(1);

    const langPref = languages ? languages : storeData?.companyDetails?.languagePreference;

    const [autoFill, setAutoFill] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false)
    const [selectedLanguage, setSelectedLanguage] = useState(primaryLanguage)
    const [direction, setDirection] = useState(storeData?.primaryLanguage == "arabic" ? "rtl" : "ltr")
    const [cancelDisabled, setCancelDisabled] = useState(false)


    const form = useForm({
        resolver: yupResolver(schema),
        defaultValues: item ? item : null
    });



    const handleLanguage = (e) => {
        const selectedLanguages = langPref.find(
            (item) => item?.title === e.target.value
        );
        setSelectedLanguage(e.target.value);
        setDirection(selectedLanguages?.direction);
    };


    const handleDelete = () => {
        setDeleteModal(true)
    }

    useEffect(() => {
        setSelectedLanguage(primaryLanguage)
        setDirection(storeData?.primaryLanguage == "arabic" ? "rtl" : "ltr")
        if (item) {
            reset(item)
        } else {
            reset({})
        }
    }, [open])

    const {
        control,
        handleSubmit,
        setError,
        reset,
        errors,
        setValue,
        watch,
        getValues
    } = form;


    useEffect(() => {
        if (autoFill === true) {
            setValue(`${storeData?.primaryLanguage}.title`, 'Veg Starters');
        }
    }, [autoFill]);

 



    const handlePrompt = () => {
        try {
            setPromptModal(false);
            handleClose();
            toast.success('Menu Saved Successfully');
            history.push('/admin/menu');
        } catch (error) {
            toast.error('Error, failed !! ');
        }
    }


    const handleFormSubmit = async (data) => {

        if(data[storeData?.primaryLanguage]?.title?.length == 0){
            toast?.error('Please fill primary language details.')
          }
          else{

            for (let i = 0; i < langPref.length; i++) {
                const langTitle = langPref[i].title?.toLowerCase();
                if (langTitle && data.hasOwnProperty(langTitle) && data[langTitle].title === "") {
                  delete data[langTitle];
                }
              }

            // if(data.hasOwnProperty(langPref[1].title?.toLowerCase()) && data[langPref[1].title?.toLowerCase()].title === ""){
            //   delete data[langPref[1].title?.toLowerCase()]
            // }
            // if(data.hasOwnProperty(langPref[2].title?.toLowerCase()) && data[langPref[2].title?.toLowerCase()].title === ""){
            //   delete data[langPref[2].title?.toLowerCase()]
            // }

        data['categoryId'] = categoryId;
        data['menuId'] = menuId;

        setProgress(SAVE_BTN, true);

        if (item) {
            ['_id', 'companyId', 'createdAt', 'updatedAt', 'isDeleted', '__v', 'updatedBy'].forEach(e => delete data[e]);
            try {
                await http.put(`/api/v1/sub-category/${item?._id}`, data)
                    .then(async (res) => {
                        toast.success("Sub-Category Updated")
                        handleClose()
                        subCategoryListRefetch()
                    });
            } catch (res) {
                toast.error(res?.response?.data?.message)
            }
        }
        else {
            try {
                await http.post(`/api/v1/sub-category`, data)
                    .then(async (res) => {
                        toast.success("Sub-Category Added")
                        handleClose()
                        subCategoryListRefetch()
                    });
            } catch (res) {
                toast.error(res?.response?.data?.message)
            }
        }
        setCancelDisabled(true)
        setProgress(SAVE_BTN, false);
    }
    }


    const handleSoftDelete = async () => {
        try {
            await http.delete(`/api/v1/sub-category/${item?._id}`)
                .then(async (res) => {
                    toast.success(t('toast.success.deletedSuccessfully'))
                    setDeleteModal(false)
                    handleClose();
                    subCategoryListRefetch()
                    history.push(`/admin/menu/${menuId}`);
                });
        } catch (error) {
            handleError(error);
        }
    };


    return (
        <>
            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <form className='space-y-4' onSubmit={handleSubmit(handleFormSubmit)}>
                            <Grid
                                container
                                direction='row'
                                justifyContent='center'
                                alignItems='center'
                                className={classes.spacing}
                                spacing={5}
                            >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid
                                        container
                                        justifyContent='space-between'
                                        alignItems='center'
                                    >
                                        <Grid item>
                                            <h1 className={classes.heading}>{item ? "Edit Sub-Category" : "Add Sub-Category"}</h1>
                                            <Typography className={classes.tagline}>
                                                {item ? "Edit" : "Add"} a sub-category to your account.
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid
                                                container
                                                justifyContent='space-between'
                                                alignItems='center'
                                            >
                                                <Grid item>
                                                    <FormControl
                                                        variant='standard'
                                                        sx={{ m: 1, minWidth: 100 }}
                                                    >
                                                        <Select
                                                            variant='outlined'
                                                            control={control}
                                                            className={classes.language}
                                                            name="language"
                                                            value={selectedLanguage}
                                                            size='small'
                                                            onChange={handleLanguage}
                                                        >
                                                            {langPref?.map((item, index) => {
                                                                return (
                                                                    <MenuItem value={item?.title} key={index}>
                                                                        {item?.title == primaryLanguage ? item?.title + " " + "(primary)" : item?.title}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                {
                                                    !item ?
                                                        <Grid item>
                                                            <span
                                                                className={classes.autoFill}
                                                                onClick={() => setAutoFill(true)}
                                                            >
                                                                Auto fill
                                                            </span>
                                                        </Grid>
                                                        : null
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {langPref?.map((langItem,index) => {
                                        return (
                                            selectedLanguage === langItem?.title ?
                                                <InputController
                                                    key={index}
                                                    dir={direction}
                                                    control={control}
                                                    label={"Add a Sub-Category title*"}
                                                    name={`${langItem?.title?.toLowerCase()}.title`}
                                                />
                                                : null
                                        )
                                    })}
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid
                                        container
                                        justifyContent={item ? 'space-between' : 'flex-end'}
                                        alignItems='center'
                                    >
                                        {
                                            item ?
                                                <Grid item>
                                                    <span className={classes.deleteBtn} onClick={handleDelete}>Delete</span>
                                                </Grid>
                                                : null
                                        }
                                        <Grid item>
                                            <Grid container justifyContent="flex-end" spacing={3}>
                                                <Grid item>
                                                    <Btn
                                                        className={classes.cancelBtn}
                                                        onClick={handleClose}
                                                    >
                                                        Cancel
                                                    </Btn>{' '}
                                                </Grid>
                                                <Grid item>
                                                    <Btn type='submit' className={classes.addBtn} name={SAVE_BTN}>
                                                        {item ? "Update" : "Add"}
                                                    </Btn>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Fade>
            </Modal>
            <ConfirmationDialog isOpen={deleteModal} title={'Are you sure ?'} text={'Click continue to delete this Sub-Category'} onReject={() => setDeleteModal(false)} onResolve={handleSoftDelete} />
            <ConfirmationDialog
                isOpen={promptModal}
                title={'Are you sure ?'}
                text={
                    'Do you want to submit menu without adding in diffrent languages ?'
                }
                onReject={() => setPromptModal(false)}
                onResolve={handlePrompt}
            />
        </>
    );
};

export default AddSubCategoryModal;
