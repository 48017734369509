import { IconButton, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { SketchPicker } from 'react-color';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  colorCustomInput: {
    '&>div': {
      width: '115px',
    },
    '& div input': {
      width: '115px',
      height: '31px',
      padding: '4px 4px 4px 36px',
      borderRadius: '0px',
      fontWeight: 400,
      fontSize: '14px',
      color: '#000000',
    },
    '& fieldset': {
      width: '115px',
      borderRadius: '0px',
    },
  },
  colorCustomInputBox: {
    width: '27px',
    height: '27px',
    position: 'absolute',
    top: '19px',
    transform: 'translateY(-50%)',
    left: '4px',
    zIndex: 1,
    borderRadius: '0px',
  },
  popover: {
    position: 'absolute',
    zIndex: '10',
    bottom: 'calc(100% + 8px)',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
}));

const ColorPicker = ({
  color = '#000000',
  label = '',
  onChangeComplete = () => {},
}) => {
  const classes = useStyles();

  const [showColorPicker, setShowColorPicker] = useState(false);
  const [colorPickerColor, setColorPickerColor] = useState(color);

  const openColorPicker = () => {
    setShowColorPicker(true);
  };

  const closeColorPicker = () => {
    setShowColorPicker(false);
  };

  const handleChangeComplete = (label, color) => {
    setColorPickerColor(color.hex);
    onChangeComplete(label, color);
  };

  const handleInputColorChange = () => {
    // if(color.length === 6) {
    //   setColorPickerColor(color);
    //   onChangeComplete(label, color);
    // }
    setShowColorPicker(true);
  }

  return (
    <Box position={'relative'}>
      <TextField
        fullWidth
        value={color}
        disabled
        className={classes.colorCustomInput}
        onMouseDown={handleInputColorChange}
      />
      <IconButton
        onClick={openColorPicker}
        sx={{ p: '10px' }}
        aria-label='color-picker'
        className={classes.colorCustomInputBox}
        style={{
          backgroundColor: color,
        }}
      ></IconButton>
      {showColorPicker ? (
        <div className={classes.popover}>
          <div className={classes.cover} onClick={closeColorPicker} />
          <SketchPicker
            color={colorPickerColor}
            onChangeComplete={(color) => handleChangeComplete(label, color)}
          />
        </div>
      ) : null}
    </Box>
  );
};

export default ColorPicker;
