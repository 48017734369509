import React, { useState } from 'react';
import { Card, Container, Grid, CircularProgress, Hidden } from '@mui/material';
import styles from "./Styles.module.css"
import { useTranslation } from 'react-i18next';
import { http } from 'Utils/Http/Http';
import { useForm } from 'react-hook-form';
import Yup from 'Utils/Yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Btn, InputController, setProgress } from 'Components';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmenuLogo from "../../Assets/EmenuLogo.svg"
import { makeStyles } from '@mui/styles';


const useStyle = makeStyles((theme) => ({
addBtn: {
    color: '#fff',
    backgroundColor: '#133FE3 !important',
    borderRadius: '8px',
    padding: '8px 34px'
  }
}));

const RESET_LINK_BTN = 'RESET_LINK_BTN';

const schema = Yup.object().shape({
    email: Yup.string().email().required(),
});

export default function SendResetLinkPage() {

    const classes = useStyle();
    const { t } = useTranslation();
    const history = useHistory();
    const [linkSent, setLinkSent] = useState(false)
    const { control, handleSubmit, setError } = useForm({
        resolver: yupResolver(schema),
    });
    const handleForgotPassword = (values) => {
        setProgress(RESET_LINK_BTN, true);
        http.post('/api/v1/session/forget-password', values, {
            baseURL: process.env.REACT_APP_BASE_URL,
        })
            .then(async (res) => {
                setLinkSent(true)
                setTimeout(() => {
                    history.push('/sign-in');
                }, 3000);
            }).catch((res) => {
                 toast.error(res?.message === "Request failed with status code 400" ? "Entered email is not registered with the system" : res?.message)
            })
        setProgress(RESET_LINK_BTN, false);
    }

    return (
        <div>
            {linkSent ?
                <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center px-2 md:px-0">
                    <img className="w-auto mx-auto mb-5" src={EmenuLogo} alt="logo" style={{ height: "40px" }} />
                    <Container className="max-w-lg">
                        <Card style={{ padding: '25px 20px', borderRadius: '20px', boxShadow: '1px 1px 10px 1px rgba(21,124,15,0.4)' }}>
                            <Grid container style={{ marginTop: "10px", display: 'flex', justifyContent: 'center', flexDirection: "column" }} spacing={2}>
                                <h6 style={{ textAlign: 'center' }}>Reset Link Have Been Sent To Your Email.</h6>
                                <h6 style={{ textAlign: 'center' }}>Please Check Your Email.</h6>
                                <br />
                                <h7 style={{ color: "rgba(0,0,0,0.6)", display: "flex", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ width: '20px', height: '20px' }} /> &nbsp; Redirecting to Login Page</h7>
                            </Grid>
                        </Card>
                    </Container>
                </div>
                :
                <Grid container className={styles.main} alignItems="center">
                    <Grid item xs={12} sm={6} className={styles.left}>
                        <Grid container>
                            <Grid item xs={12}><img src={EmenuLogo} alt="EmenuLogo" style={{ position: "absolute", top: "30px", cursor: "pointer" }} onClick={() => history.push("/")} /></Grid>
                            <Grid item xs={12}>
                                <h3 className={styles.title}>{t('password.resetPassword')}</h3>
                                <p className={styles.text}>{t('password.followSteps')}</p>
                                <form onSubmit={handleSubmit(handleForgotPassword)}>
                                    <Grid container spacing={9}>
                                        <Grid item xs={12}>
                                            <InputController
                                                control={control}
                                                name="email"
                                                label={t('password.emailAddress')}
                                                placeholder={t('password.enterYourEmailAddress')}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Btn type="submit" name={RESET_LINK_BTN} fullWidth={true} className={classes.addBtn}>
                                                {t('password.sendResetLink')}
                                            </Btn>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'center', marginTop: '15px' }}>
                                <Link className={styles.forgotLink} to="/sign-in">Go back to sign-in</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Hidden only={['xs']}>
                        <Grid item xs={12} sm={6} className={styles.right}>
                        </Grid>
                    </Hidden>
                </Grid>
            }
        </div>
    );
}
