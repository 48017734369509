import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import {
  Btn,
  CheckboxGroupController,
  InputController,
  setProgress,
} from 'Components';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import yup from 'Utils/Yup';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { http } from 'Utils/Http/Http';
import { LocalStorage } from 'Utils/LocalStorage';
import ReactSelect from 'react-select';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';

const storage = new LocalStorage();

const CHANGE_LINK_BTN = 'CHANGE_LINK_BTN';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '500px',
  borderRadius: '20px',
  bgcolor: 'background.paper',
  // borderRadius:"20px",
  boxShadow: 24,
  p: 4,
};

const useStyle = makeStyles((theme) => ({
  card: {
    padding: '25px',
    boxShadow: '1px 2px 6px 2px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
  },
  title: {
    fontSize: '23px',
    color: 'rgba(0, 0, 0, 1)',
    fontWeight: '500',
  },
  btn_submit: {
    color: '#fff',
    background: '#133FE3',
    borderRadius: '8px',
    padding: '8px 34px',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#133FF5',
    },
  },
  btn_cancel: {
    backgroundColor: 'rgba(0, 186, 241, 1)',
    fontSize: '12px',
    color: '#000',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#0863a3',
    },
  },
  tagline: {
    color: '#767676',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '13px',
  },
  spacing: {
    padding: '10px',
  },
  primary: {
    border: '1px solid #D0D5DD',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },
}));

const schema = yup.object().shape({
  primaryLanguage: yup.object().required('This is required'),
  otherLanguage: yup.array(),
});

const AddLanguageModal = ({ open, handleClose, menuListRefetch }) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const [languagesData, setLanguagesData] = useState();
  const [addMenuModal, setAddMenuModal] = useState(false);
  // const [oldPasswordType, setOldPasswordType] = useState('password')
  // const [passwordType, setPasswordType] = useState('password')
  // const [confirmType, setConfirmType] = useState('password')

  const companyId = storage.get('user')?._id;

  const dispatch = useDispatch();

  const form = useForm({
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit, setError, reset, errors } = form;

  useEffect(() => {
    http
      .get('api/v1/language')
      .then(({ data }) => setLanguagesData(data?.data));
  }, []);


  const handleSubmitLanguage = async (values) => {
    const primaryLanguage = [
      {
        title: values.primaryLanguage.title,
        direction: values.primaryLanguage.direction,
        isPrimary:true
      },
    ];

    if (values.otherLanguage) {
      var otherLanguage = values.otherLanguage.map((item,index) => ({
        title: item?.title,
        direction: item?.direction,
        isPrimary:false
      }));

      var allLanguages = primaryLanguage.concat(otherLanguage);
    }

    const data = {
      languagePreference: values.otherLanguage ? allLanguages : primaryLanguage,
      // primaryLanguage: values.primaryLanguage,
      // otherLanguage : values.otherLanguage
      // isArabic: values?.otherLanguage?.find((item) => item === "Arabic") ? true : false,
      // isSpanish: values?.otherLanguage?.find((item) => item === "Spanish") ? true : false,
      // isFrench: values?.otherLanguage?.find((item) => item === "French") ? true : false,
    };
    setProgress(CHANGE_LINK_BTN, true);
    try {
      await http
        .put(`/api/v1/company/${companyId}`, { ...data })
        .then(async (res) => {
          dispatch({
            type: 'SET_COMPANY_DETAILS',
            companyDetails: res?.data?.data,
        });
          handleClose();
          setAddMenuModal(true);
          toast.success('Language saved successfully');
          reset({});
        })
        .catch((res) => {
          toast.error(res?.response?.data?.message);
        });
    } catch (res) {
      toast.error(res?.response?.data?.message);
    }
    setProgress(CHANGE_LINK_BTN, false);
  };

  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Grid
              container
              justifyContent='space-between'
              alignItems='center'
              className={classes.spacing}
              spacing={5}
            >
              <Grid item>
                <h3 className={classes.title}>Add Language</h3>
                <Typography className={classes.tagline}>
                  Add a Language to your account
                </Typography>
              </Grid>
              {/* <Grid item>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid> */}
            </Grid>
            {/* <Divider fullwidth style={{ margin: "10px 0", backgroundColor: "rgba(0, 0, 0, 0.2)" }} /> */}
            <form
              className='space-y-4'
              onSubmit={handleSubmit(handleSubmitLanguage)}
            >
              <Grid container style={{ marginTop: '10px' }} spacing={5}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {/* <InputController
                                    control={control}
                                    type='text'
                                    name="primaryLanguage"
                                    label="Primary Language"
                                /> */}
                  {/* <Autocomplete
                                    control={control}
                                    fullwidth
                                    name="primaryLanguage"
                                    options={languagesData}
                                    getOptionLabel={(option) => option.title}
                                    getOptionValue={(options) => options}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Primary Language" placeholder="Choose Primary Language" />
                                    )}
                                /> */}
                  <Controller
                    name='primaryLanguage'
                    isClearable
                    control={control}
                    className={classes.primary}
                    // style={{ height: '100px !important' }}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        placeholder='Select Primary Language'
                        // style={{width:'100% !important',height:'56px !important'}}
                        className={classes.primary}
                        options={languagesData}
                        getOptionValue={(options) => options}
                        getOptionLabel={(options) => options.title}
                      />
                    )}
                  />
                  {errors?.primaryLanguage && (
                    <p
                      style={{
                        color: '#f44336',
                        margin: '4px 14px 0 14px',
                        fontSize: '0.75rem',
                      }}
                    >
                      {errors?.primaryLanguage?.message}
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {/* <InputController
                                    control={control}
                                    type='text'
                                    name="primaryLanguage"
                                    label="Primary Language"
                                /> */}
                  {/* <Controller
                                    name="otherLanguage"
                                    isClearable
                                    control={control}
                                    render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    // control={control}
                                    fullwidth
                                    // name="otherLanguage"
                                    // multiple
                                    options={languagesData}
                                    getOptionValue={(options) => options}
                                    getOptionLabel={(option) => option.title}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Other Languages" placeholder="Choose Other Languages" />
                                    )}
                                />
                                    )}
                                /> */}

                  <Controller
                    name='otherLanguage'
                    isClearable
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        {...field}
                        isMulti
                        placeholder='Select Other Languages'
                        options={languagesData}
                        getOptionValue={(options) => options}
                        getOptionLabel={(options) => options.title}
                      />
                    )}
                  />
                  {errors?.otherLanguage && (
                    <p
                      style={{
                        color: '#f44336',
                        margin: '4px 14px 0 14px',
                        fontSize: '0.75rem',
                      }}
                    >
                      {errors?.otherLanguage?.message}
                    </p>
                  )}
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CheckboxGroupController
                                    label="Other Languages"
                                    control={control}
                                    name="otherLanguage"
                                    row
                                    options={[{ label: "Arabic", value: 'Arabic' }, { label: "Spanish", value: 'Spanish' }, { label: "French", value: 'French' }]}
                                />
                            </Grid> */}
              </Grid>
              <Grid
                container
                style={{ marginTop: '10px' }}
                justifyContent='flex-end'
                alignItems='center'
              >
                <Grid item>
                  <Button className={classes.cancelBtn} onClick={handleClose}>
                    Cancel
                  </Button>{' '}
                  &nbsp;
                  <Button
                    type='submit'
                    name={CHANGE_LINK_BTN}
                    className={classes.btn_submit}
                  >
                    Add
                  </Button>
                </Grid>
                {/* <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
                                <Btn className={classes.btn_cancel} >
                                    {t('changePassword.cancel')}
                                </Btn>
                            </Grid> */}
              </Grid>
            </form>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AddLanguageModal;
