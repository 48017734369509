import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Hidden, InputAdornment, Link, SvgIcon } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Btn from 'Components/Buttons/Btn';
import { LocalStorage } from "Utils/LocalStorage";
import InputController from 'Components/FormControls/Controllers/InputController';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { http } from 'Utils/Http/Http';
import { setProgress } from 'Utils/ProgressUtils/ProgressSvc';
import yup from 'Utils/Yup';
import { toast } from 'react-toastify';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useState } from 'react';
import styles from "./Styles.module.css"
import { CheckboxController } from 'Components';
import EmenuLogo from "../../Assets/EmenuLogo.svg"
import { makeStyles } from '@mui/styles';


const useStyle = makeStyles((theme) => ({
addBtn: {
    color: '#fff',
    backgroundColor: '#133FE3 !important',
    borderRadius: '8px',
    padding: '8px 34px'
  }
}));

const storage = new LocalStorage();

const SIGN_IN_BTN = 'SIGN_IN_BTN';

const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
});

export default function SignInPage() {

    const { control, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });
    const history = useHistory();
    const classes = useStyle();
    const { t } = useTranslation();
    const [passwordType, setPasswordType] = useState('password')


    const togglePasswordType = (val) => {
        if (val === 'password') {
            if (passwordType === 'password') {
                setPasswordType('text')
            } else if (passwordType === 'text') {
                setPasswordType('password')
            }
        }
    }

    const handleLogin = async (values) => {
        setProgress(SIGN_IN_BTN, true);
        try {
            await http.post(
                '/api/v1/session/login',
                { ...values },
                {
                    baseURL: process.env.REACT_APP_BASE_URL,
                },
            ).then(async (res) => {
                storage.set("user", res?.data?.data);
                storage.set("userToken", res.data.data.loginData.token);
                storage.set("languagePreference", res?.data?.data?.languagePreference);
                storage.set("companyDetails", res?.data?.data);
                history.push('/admin/menu')
            }).catch((res) => {
                toast.error(res?.response?.data?.message)
            })
        } catch (error) {
            // handle errors
        }
        setProgress(SIGN_IN_BTN, false);
    };

    return (
        <div>
            <Grid container className={styles.main} alignItems="center">
                <Grid item xs={12} sm={6} className={styles.left}>
                    <Grid container>
                        <Grid item xs={12}><img src={EmenuLogo} alt="EmenuLogo" style={{ position: "absolute", top: "30px", cursor:"pointer" }} onClick={()=>history.push("/")}/></Grid>
                        <Grid item xs={12}>
                            <h3 className={styles.title}>Log In</h3>
                            <p className={styles.text}>Welcome Back, Please enter Email ID and Password</p>
                            <form onSubmit={handleSubmit(handleLogin)}>
                                <Grid container spacing={9}>
                                    <Grid item xs={12}>
                                        <InputController control={control} name="email" label={t('formLabels.email')} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputController
                                            type={passwordType}
                                            control={control}
                                            name="password"
                                            label={t('formLabels.password')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('password')} style={{ cursor: 'pointer' }}>
                                                            {passwordType === 'password' ?
                                                                <VisibilityOutlinedIcon />
                                                                :
                                                                <VisibilityOffOutlinedIcon />
                                                            }
                                                        </SvgIcon>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="space-between" className='mt-4' alignItems="center">
                                    <Grid item>
                                        <CheckboxController
                                            control={control}
                                            name="rememberMe"
                                            label={t('signIn.rememberMe')}
                                            className={styles.rememberMe}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Link className={styles.forgotLink} component={RouterLink} to="/forgot-password">{t('signIn.forgotYourPassword')}</Link>
                                    </Grid>
                                </Grid>
                                <Btn type="submit" name={SIGN_IN_BTN} className={classes.addBtn}>
                                    {t('signIn.login')}
                                </Btn>
                            </form>
                            <p className={styles.createAccount}>Not Registered Yet ? <Link component={RouterLink} to="/sign-up">{t('signIn.createAccount')}</Link></p>
                        </Grid>
                        <Grid item xs={12}>
                            <p className={styles.rightsReserved} style={{ position: "absolute", bottom: "37px" }}>@2022 Emenu, All Right Reserved</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Hidden only={['xs']}>
                    <Grid item xs={12} sm={6} className={styles.right}>
                    </Grid>
                </Hidden>
            </Grid>
        </div>
    );
}
