import { CircularProgress, Button, Grid } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react";
import CategoryAccordion from "./CategoryAccordion";
import AddCategoryModal from "./AddCategoryModal";
import { makeStyles } from '@mui/styles';
import { useParams } from "react-router-dom";
import { CATEGORYLIST, USERPROFILE } from 'Constants/QueriesKeys';
import { LocalStorage } from 'Utils/LocalStorage';
import { http } from "Utils/Http/Http";
import { useQuery } from "react-query";
import AddLanguageModal from "./AddLanguageModal";
import ItemsList from "./ItemsList";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const storage = new LocalStorage();


const useStyle = makeStyles((theme) => ({
    heading: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '25px',
        lineHeight: '30px',
        letterSpacing: '-1.15556px'
    },
    addCategoryBtn: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '24px',
    },
    spacing: {
        marginTop: '10%'
    }
}));


const MenuDetails = () => {

    const classes = useStyle();
    const { t } = useTranslation();
    const { menuId } = useParams();

    const storeData = useSelector((state) => state?.globalStore);

    const [addCategoryModal, setAddCategoryModal] = useState(false);
    const [addLanguageModal, setAddLanguageModal] = useState(false);
    const [langPref, setLangPref] = useState(storeData?.companyDetails?.languagePreference)
    const [categoryList, setCategoryList] = useState();
    const [categoryListRefetch, setCategoryListRefetch] = useState(true)
    const [selectedCategory, setSelectedCategory] = useState(categoryList ? categoryList[0] : {})
    const [selectedSubCategory, setSelectedSubCategory] = useState()
    const [profileRefetch, setProfileRefetch] = useState(true)
    const [menuTitle, setMenuTitle] = useState();


    useEffect(() => {
        http.get(`/api/v1/menu/${menuId}`).then(({ data }) => {
            setMenuTitle(data?.data?.[storeData?.primaryLanguage]?.title)
        }
        );
    }, [])


    const categoryListRender = useQuery([CATEGORYLIST, { menuId }], () =>

        http.get(`/api/v1/category`, {
            params: {
                holdingSort: true,
                page: 1,
                limit: 10,
                menuId: menuId
            }
        }).then((data) => {
            setCategoryListRefetch(false)
            setCategoryList(data?.data?.data?.records);
            setSelectedCategory(data?.data?.data?.records[0])
            return (data)
        }),
        {
            enabled: categoryListRefetch ? true : false,
        },
    )

    const [expanded, setExpanded] = useState(0);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
        setSelectedSubCategory()
    };

    const handleAddCategory = (e) => {
        e.preventDefault();
        if (langPref?.length === 0) {
            setAddLanguageModal(true);
        } else {
            setSelectedCategory()
            setAddCategoryModal(true);
        }
    };


    return (
        <>
            {categoryListRender?.isRefetching ?
                <Grid container>
                    <h3 style={{ fontSize: "18px" }}><CircularProgress style={{ height: "18px", width: "18px" }} /> {t('common.loading')}</h3>
                </Grid>
                :
                <Grid
                    container
                    direction='row'
                    justifyContent='center'
                    alignItems='flex-start'
                    spacing={5}
                >
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                        <Grid
                            container
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <Grid item>
                                <h1 className={classes.heading}>{menuTitle ? menuTitle?.charAt(0).toUpperCase() + menuTitle?.slice(1) : "-"}</h1>
                            </Grid>
                            <Grid item>
                                <Button onClick={handleAddCategory} className={classes.addCategoryBtn}>
                                    <AddIcon fontSize='small' /> Add Category
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <Grid
                                container
                                justifyContent='center'
                                alignItems='center'
                                className={classes.spacing}
                            >
                                {
                                    categoryList?.length ?
                                        categoryList?.map((item, index) => {
                                            return (
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                                                    <CategoryAccordion
                                                        item={item}
                                                        index={index}
                                                        langPref={langPref}
                                                        expanded={expanded}
                                                        handleChange={handleChange}
                                                        selectedCategory={selectedCategory}
                                                        setSelectedCategory={setSelectedCategory}
                                                        setAddCategoryModal={setAddCategoryModal}
                                                        categoryListRefetch={() => categoryListRender.refetch()}
                                                        selectedSubCategory={selectedSubCategory}
                                                        setSelectedSubCategory={setSelectedSubCategory}
                                                    />
                                                </Grid>
                                            );
                                        })
                                        :
                                        "No Category Added"
                                }
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <ItemsList
                            langPref={langPref}
                            categoryId={selectedCategory?._id}
                            subCategoryId={selectedSubCategory?._id}
                            menuId={menuId}
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                            selectedSubCategory={selectedSubCategory}
                            setSelectedSubCategory={setSelectedSubCategory}
                        />
                    </Grid>
                </Grid>
            }
            <AddCategoryModal
                open={addCategoryModal}
                handleClose={() => setAddCategoryModal(false)}
                categoryListRefetch={() => categoryListRender.refetch()}
                item={selectedCategory}
                menuId={menuId}
                langPref={langPref}
                setSelectedCategory={setSelectedCategory}
            />
            <AddLanguageModal
                open={addLanguageModal}
                handleClose={() => setAddLanguageModal(false)}
            />
        </>
    )
}
export default MenuDetails;