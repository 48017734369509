import { Typography, Card, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles((theme) => ({
  qRCard: {
    background: '#FFFFFF',
    boxShadow: '0px 1px 16px rgba(189, 189, 189, 0.25)',
    borderRadius: '6px',
    padding: '24px 24px 24px 12px',
    width: '100%',
    maxWidth: '900px',
    overflow: 'visible',
  }
}));

const QRCard = ({ children = null, className="" }) => {
  const classes = useStyle();
  return (
    <Card className={classes.qRCard + " " + className}>
      <Grid container justifyContent='space-between' alignItems='flex-start'>
        <Grid flex="1" item>
          {children && children}
        </Grid>
      </Grid>
    </Card>
  );
};

export default QRCard;
