import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React from 'react'
import menu from 'Assets/menu.svg';


const useStyle = makeStyles((theme) => ({
    menuImage: {
        textAlign: 'center',
        width: '104px',
        height: '100%',
        borderRadius: '10px',
    },
    title: {
        fontstyle: 'normal',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '30px',
        marginBottom: "13px"
    },
    price: {
        fontstyle: 'normal',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '30px',
    }
}))

const SideDishCard = ({ item, langPref, sideDishArray }) => {
    const classes = useStyle();
    const dishDetails = sideDishArray?.find((sideDish) => sideDish?._id === item)

   
    return (
        <Grid container justifyContent="space-between">
            <Grid item>
                <Grid container spacing={4}>
                    <Grid item>
                        <img src={dishDetails?.images[0]?.url ? dishDetails?.images[0]?.url : menu} alt="image" className={classes.menuImage} />
                        {/* <img src={dishDetails?.image[0]?.src} alt="image" /> */}
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item xs={12}>
                                {/* <h3 className={classes.title}>{dishDetails[langPref[0]?.title.toLowerCase()]?.title}</h3> */}
                            </Grid>
                            <Grid item xs={12}>
                                <p className={classes.price}>SAR {dishDetails?.price}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SideDishCard