import React, { useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Divider, Grid, IconButton, InputAdornment, SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Btn, InputController, setProgress } from 'Components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import yup from 'Utils/Yup';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { http } from 'Utils/Http/Http';
import { LocalStorage } from "Utils/LocalStorage";


const storage = new LocalStorage();


const CHANGE_LINK_BTN = 'CHANGE_LINK_BTN';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    maxWidth: "500px",
    borderRadius: "20px",
    bgcolor: 'background.paper',
    // borderRadius:"20px",
    boxShadow: 24,
    p: 4,
};

const useStyle = makeStyles((theme) => ({
    heading: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '25px',
        lineHeight: '36px'
    },
    addBtn: {
        color: '#fff',
        backgroundColor: '#133FE3 !important',
        borderRadius: '8px',
        padding: '8px 34px'
      },
      cancelBtn: {
        color: '#133FE3 !important',
        borderRadius: '8px',
        background:'#fff',
        border: '1px solid #133FE3',
        padding: '6px 25px',
        '&:hover': {
          background:'#fff',
      }
      },
    btnText: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '15px',
        lineHeight: '22px'
    },
    textfield:{
        paddingRight:'15%',
        "& .MuiFormLabel-root": {
            color:'#979797'
        }
    },
    buttonWrap:{
        paddingTop:'3%',
        paddingRight:'15%'
    }
}));


const schema = yup.object().shape({
    password: yup.string().required(),
    newPassword: yup.string().required(),
    confirmNewPassword: yup.string().required().oneOf([yup.ref('newPassword')], 'confirmNewPassword'),
});



const ChangePassword = () => {

    const classes = useStyle();
    const { t } = useTranslation();

    const [oldPasswordType, setOldPasswordType] = useState('password')
    const [passwordType, setPasswordType] = useState('password')
    const [confirmType, setConfirmType] = useState('password')

    const form = useForm({
        resolver: yupResolver(schema),
    });


    const { control, handleSubmit, setError, reset, errors } = form;


    const togglePasswordType = (val) => {
        if (val === 'old') {
            if (oldPasswordType === 'password') {
                setOldPasswordType('text')
            } else if (oldPasswordType === 'text') {
                setOldPasswordType('password')
            }
        } else if (val === 'password') {
            if (passwordType === 'password') {
                setPasswordType('text')
            } else if (passwordType === 'text') {
                setPasswordType('password')
            }
        } else if (val === 'confirm') {
            if (confirmType === 'password') {
                setConfirmType('text')
            } else if (confirmType === 'text') {
                setConfirmType('password')
            }
        }
    }

    const handleForgotPassword = async (values) => {
        const data = {
            oldPassword: values.password,
            newPassword: values.newPassword
        }
        setProgress(CHANGE_LINK_BTN, true);
        try {
            await http.post(`/api/v1/company/change-password`,
                { ...data },
                {
                    baseURL: process.env.REACT_APP_BASE_URL,
                },
            ).then(async (res) => {
                // handleClose()
                toast.success("password changed successfully")
                reset({
                    password: "",
                    newPassword: "",
                    confirmNewPassword: ""
                })
            }).catch((res) => {
                toast.error(res?.response?.data?.message)
            })
        } catch (res) {
            toast.error(res?.response?.data?.message)
        }
        setProgress(CHANGE_LINK_BTN, false);
    };

    const handleCancel = () => {
        reset({
            password: "",
            newPassword: "",
            confirmNewPassword: ""
        })
    }


    return (
        <>
            <Grid container justifyContent='center' alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h1 className={classes.heading}>Change Password</h1>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <form className="space-y-4" onSubmit={handleSubmit(handleForgotPassword)}>
                        <Grid container style={{ marginTop: "10px" }} spacing={5}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputController
                                    control={control}
                                    type={oldPasswordType}
                                    name="password"
                                    label="Current Password"
                                    className={classes.textfield}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('old')} style={{ cursor: 'pointer' }}>
                                                    {oldPasswordType === 'password' ?
                                                        <VisibilityOutlinedIcon />
                                                        :
                                                        <VisibilityOffOutlinedIcon />
                                                    }
                                                </SvgIcon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputController
                                    control={control}
                                    type={passwordType}
                                    name="newPassword"
                                    label="New Password"
                                    className={classes.textfield}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('password')} style={{ cursor: 'pointer' }}>
                                                    {passwordType === 'password' ?
                                                        <VisibilityOutlinedIcon />
                                                        :
                                                        <VisibilityOffOutlinedIcon />
                                                    }
                                                </SvgIcon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputController
                                    control={control}
                                    type={confirmType}
                                    name="confirmNewPassword"
                                    label="Confirm New Password"
                                    className={classes.textfield}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('confirm')} style={{ cursor: 'pointer' }}>
                                                    {confirmType === 'password' ?
                                                        <VisibilityOutlinedIcon />
                                                        :
                                                        <VisibilityOffOutlinedIcon />
                                                    }
                                                </SvgIcon>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.buttonWrap} justifyContent="flex-end" alignItems="center">
                            <Grid item xs={12}>
                                <Grid container justifyContent="flex-end" spacing={2}>
                                    <Grid item>
                                    <Button className={classes.cancelBtn} onClick={handleCancel} >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                    <Button type="submit" className={classes.addBtn}>
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid container className={classes.buttonWrap} justifyContent="flex-end" alignItems="center">
                            <Grid item>
                                <Button className={classes.cancelBtn} onClick={handleCancel} >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item >
                                <Button type="submit" className={classes.addBtn}>
                                    <span className={classes.btnText}>Save</span>
                                </Button>
                            </Grid>
                            // <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
                            //     <Btn className={classes.btn_cancel} >
                            //         {t('changePassword.cancel')}
                            //     </Btn>
                            // </Grid>
                        </Grid> */}
                    </form>
                </Grid>
            </Grid>
        </>
    )
}
export default ChangePassword;