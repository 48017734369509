import React, { useState } from 'react'
import { Grid, InputAdornment, MenuItem, SvgIcon } from '@mui/material'
import { InputController } from 'Components'
import { useTranslation } from 'react-i18next';
import styles from "../Signup.module.css"
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { countries_list } from 'Constants/CountryArray';

const SecondStep = (props) => {
  const { control, form, errors, countryCode } = props;
  const { t } = useTranslation();
  const [passwordType, setPasswordType] = useState('password')
  const [confirmType, setConfirmType] = useState('password')

  const togglePasswordType = (val) => {
    if (val === 'password') {
      if (passwordType === 'password') {
        setPasswordType('text')
      } else if (passwordType === 'text') {
        setPasswordType('password')
      }
    } else if (val === 'confirm') {
      if (confirmType === 'password') {
        setConfirmType('text')
      } else if (confirmType === 'text') {
        setConfirmType('password')
      }
    }
  }
  

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h3 className={styles.formTitle}>{t('signUp.formdata.form2.title')}</h3>
          <p className={styles.formText}>{t('signUp.formdata.form2.text')}</p>
        </Grid>
        <Grid item xs={12}>
          <InputController
            control={control}
            fullWidth
            name="firstName"
            label={t('formLabels.firstName')}
            style={{ marginBottom: '9px' }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputController
            control={control}
            fullWidth
            name="lastName"
            label={t('formLabels.lastName')}
            style={{ marginBottom: '9px' }}
          />
        </Grid>
        <Grid item xs={12} >
          <Grid container spacing={2}>
            <Grid item xs={2} md={3}>
              <InputController
                control={control}
                fullWidth
                select
                name="countryCode"
                label={t('formLabels.countryCode')}
                defaultValue={countryCode[0]?.Dial}
                className={'dial'}
              >
                {countries_list?.map((item,index) => {
                  return (
                    <MenuItem value={item?.Dial} key={index} style={{textAlign: 'center'}}>
                      {`(${item?.Dial})`}
                    </MenuItem>
                  );
                })}
              </InputController>
            </Grid>
            <Grid item xs={10} md={9}>
              <InputController
                control={control}
                fullWidth
                type="tel"
                name="mobileNo"
                label={t('formLabels.phoneNo')}
                style={{ marginBottom: '9px' }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InputController
            control={control}
            fullWidth
            name="email"
            type="email"
            label={t('formLabels.email')}
            style={{ marginBottom: '9px' }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputController
            control={control}
            name="password"
            fullWidth
            label={t('formLabels.password')}
            type={passwordType}
            style={{ marginBottom: '9px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                 <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('password')} style={{ cursor: 'pointer' }}>
                    {passwordType === 'password' ?
                      <VisibilityOutlinedIcon />
                      :
                      <VisibilityOffOutlinedIcon />
                    }
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputController
            control={control}
            name="confirmPassword"
            fullWidth
            label={t('formLabels.confirmPassword')}
            type={confirmType}
            style={{ marginBottom: '9px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                 <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('confirm')} style={{ cursor: 'pointer' }}>
                    {confirmType === 'password' ?
                      <VisibilityOutlinedIcon />
                      :
                      <VisibilityOffOutlinedIcon />
                    }
                </SvgIcon>
              </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default SecondStep