var initialState = {
    companyDetails: {},
    primaryLanguage:""
};

export const globalStore = (state = initialState, action) => {


    switch (action.type) {
        case 'SET_COMPANY_DETAILS':
            return {
                ...state,
                companyDetails: action?.companyDetails
            }
            case 'SET_PRIMARY_LANG':
                return {
                    ...state,
                    primaryLanguage: action?.primaryLanguage
                }

            case 'SET_LOGOUT':
                return {
                    companyDetails: {},
                    primaryLanguage:""
                }

        default: return state;
    }
}

