import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid, Typography, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { makeStyles } from '@mui/styles';
import AddSubCategoryModal from "./AddSubCategoryModal";
import { http } from "Utils/Http/Http";
import { useParams } from "react-router-dom";
import { SUBCATEGORYLIST } from 'Constants/QueriesKeys';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';


const useStyle = makeStyles((theme) => ({
    selectedPanel: {
        width: '100%',
        height: 'auto',
        background: '#EAEEFF',
        transition: "all ease-in-out 0.3s",
        borderLeft: '3px solid #133FE3',
        boxShadow: '0px 1px 16px rgba(189, 189, 189, 0.25)',
        borderRadius: '6px',
    },
    panel: {
        width: '100%',
        height: 'auto',
        background: '#FFF',
        boxShadow: '0px 1px 16px rgba(189, 189, 189, 0.25)',
        borderRadius: '6px',
    },
    category: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '22px',
        lineHeight: '30px'
    },
    selected: {
        background: '#EAEEFF',
        transition: "all ease-in-out 0.3s",
        borderLeft: '3px solid #133FE3'
    },
    deSelected: {
        transition: "all ease-in-out 0.3s"
    },
    subCategory: {
        padding: '15px 5px 15px 5px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        cursor: 'pointer',
        flex: 1
    },
    subCategorySelected: {
        padding: '15px 5px 15px 5px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        cursor: 'pointer',
    },
    details: {
        marginBottom: '5%'
    },
    addSubCategory: {
        color: '#3A6AFF',
        padding: '15px 5px 15px 5px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '19px',
        cursor: 'pointer'
    },
    editIcon: {
        cursor: 'pointer',
        margin: "0 10px"
    }
}));

const CategoryAccordion = ({ item,selectedCategory, setSelectedCategory, index, setAddCategoryModal, selectedSubCategory, langPref, setSelectedSubCategory,handleChange, expanded,categoryListRefetch }) => {

    const classes = useStyle();
    const { menuId } = useParams();
    const categoryId = item._id

    const [addSubCategoryModal, setAddSubCategoryModal] = useState(false);
    // const [addCategoryModal,setAddCategoryModal] = useState(false);
    const [subCategory, setSubCategory] = useState('veg');
    const [subCategoryList, setSubCategoryList] = useState();
    const [subCategoryListRefetch, setSubCategoryListRefetch] = useState(true);
    const [categoryPanel, setCategoryPanel] = useState(0);


    const handleEdit = () => { 
        setSelectedCategory(item)
        setAddCategoryModal(true)
    }

    const handleEditSubCategory = (items) => {
        setSelectedSubCategory(items)
        setAddSubCategoryModal(true)
    }

    const handleSubCategorySelection = (items) => {
        setSelectedCategory(item)
        setSelectedSubCategory(items)
    }


    const subCategoryListRender = useQuery([SUBCATEGORYLIST, { categoryId }], () =>
        http.get(`/api/v1/sub-category`, {
            params: {
                holdingSort: true,
                page: 1,
                limit: 10,
                categoryId: categoryId,
                menuId: menuId
            }
        }).then((data) => {
            setSubCategoryListRefetch(false)
            setSubCategoryList(data?.data?.data?.records)
            return (data)
        }),
        {
            enabled: subCategoryListRefetch ? true : false,
        },
    )

    const handleAddSubCategory = (e) => {
        setSelectedSubCategory()
        setAddSubCategoryModal(true);
    };

    const handleDisable = (event) => {
        try {
            http.put(`/api/v1/category/${item?._id}`, { isEnabled: event.target.checked })
                .then(async (res) => {
                    if (res?.data?.data?.isEnabled === true) {
                        toast.success("Category Enabled");
                        categoryListRefetch()
                    } else {
                        toast.success("Category Disabled");
                        categoryListRefetch()
                    }
                });
        } catch (res) {
            toast.error(res?.response?.data?.message)
        }
    }

    

    return (
        <>
            <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={5}
            >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.details}>
                    <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className={selectedCategory === item ? classes.selectedPanel : classes.panel} 
                            onClick={()=>{setSelectedCategory(item); setSelectedSubCategory()}}
                        >
                            <Grid container direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                                spacing={5}

                            >
                                <Grid item>
                                    <Typography className={classes.category}  >
                                        {item[langPref?.find((lang) => lang.isPrimary)?.title?.toLowerCase()]?.title}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <EditOutlinedIcon className={classes.editIcon} onClick={handleEdit} />
                                    <Switch checked={item?.isEnabled} onChange={handleDisable} />
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails >
                            {subCategoryList?.map((items,index) => {
                                return (
                                    <div key={index}>
                                        <Divider />
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            className={selectedSubCategory === items ? classes.selected : classes.deSelected}
                                        >
                                            <Grid item style={{flexGrow:'1'}}>
                                                <Typography className={selectedSubCategory === items ? classes.subCategorySelected : classes.subCategory} onClick={() =>handleSubCategorySelection(items)}>
                                                    {items[langPref?.find((lang) => lang.isPrimary)?.title?.toLowerCase()]?.title}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <EditOutlinedIcon className={classes.editIcon} onClick={() => handleEditSubCategory(items)} />
                                            </Grid>
                                        </Grid>
                                    </div>
                                )
                            })}
                            <Divider />
                            <Typography className={classes.addSubCategory} onClick={handleAddSubCategory}>ADD NEW SUB CATEGORY</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <AddSubCategoryModal
                open={addSubCategoryModal}
                handleClose={() => setAddSubCategoryModal(false)}
                categoryId={item?._id}
                languages={langPref}
                menuId={menuId}
                item={selectedSubCategory}
                setSelectedSubCategory={setSelectedSubCategory}
                subCategoryListRefetch={() => subCategoryListRender.refetch()}
            />
        </>
    )
}
export default CategoryAccordion;