import { Checkbox, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react'
import menu from 'Assets/menu.svg';


const useStyle = makeStyles((theme) => ({
    menuImage: {
        textAlign: 'center',
        width: '104px',
        height: '100%',
        borderRadius: '10px',
    },
    title: {
        fontstyle: 'normal',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '30px',
        marginBottom: "13px"
    },
    price: {
        fontstyle: 'normal',
        fontWeight: '700',
        fontSize: '20px',
        lineHeight: '30px',
    }
}))

const SideDishCardModal = ({ item, selectedSideDish, setSelectedSideDish, langPref, showCheckbox = false }) => {
    const classes = useStyle();
    const [checked, setChecked] = useState(selectedSideDish?.find((findItem) => findItem === item?._id) ? true : false);
    const toggleCheckbox = () => {
        if (checked) {
            setSelectedSideDish(selectedSideDish.filter((dish) => dish !== item?._id))
        } else {
            setSelectedSideDish([...selectedSideDish, item?._id])
        }
        setChecked(!checked);
    };

    return (
        <Grid container justifyContent="space-between">
            <Grid item>
                <Grid container spacing={4}>
                    <Grid item>
                        <img src={item?.images[0]?.url ? item?.images[0]?.url : menu} alt="image" className={classes.menuImage} />
                        {/* <img src={item?.image[0]?.src} alt="image" /> */}
                    </Grid>
                    <Grid item >
                        <h3 className={classes.title}>{item[langPref[0]?.title.toLowerCase()]?.title}</h3>
                        <p className={classes.price}>SAR {item?.price}</p>
                    </Grid>
                </Grid>
            </Grid>
            {showCheckbox ?
                <Grid item>
                    <Checkbox
                        checked={checked}
                        onChange={toggleCheckbox}
                    />
                </Grid>
                : null}
        </Grid>
    )
}

export default SideDishCardModal