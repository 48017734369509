import {
  Button,
  Card,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import QRCard from './Components/QRCard';
import { makeStyles } from '@mui/styles';
import { Btn, setProgress } from 'Components';
import { http } from 'Utils/Http/Http';
import { LocalStorage } from 'Utils/LocalStorage';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import yup from 'Utils/Yup';
import { useEffect, useRef, useState } from 'react';
import ImageController from 'Components/FormControls/Controllers/ImageController';
import { Box } from '@mui/system';
import { ReactComponent as CopyIcon } from './copy.svg';
import ColorPicker from './Components/ColorPicker';
import { QRCodeSVG } from 'qrcode.react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useDispatch, useSelector } from 'react-redux';

const SAVE_BTN = 'SAVE_BTN';
const storage = new LocalStorage();

const schema = yup.object().shape({});

const API = {
  qrCode: {
    restaurantUrl: 'Emenu.co/RK-kitchen',
    color: '#0000ff',
    backgroundColor: '#00ff00',
    default: {
      color: '#000000',
      backgroundColor: '#ffffff',
    },
  },
};


const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 600,
    fontSize: '30px',
    textTransform: 'capitalize',
    color: '#1A1A1A',
  },
  tagline: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '26px',
    color: '#767676',
  },
  subHeading: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    textTransform: 'capitalize',
    color: '#1A1A1A',
    marginBottom: '20px',
  },
  qrPageCard: {
    height: '100%',
    '& label': {
      fontWeight: '500!important',
      fontSize: '16px!important',
      display: 'flex!important',
      color: '#000000!important',
      marginBottom: '8px!important',
    },
    '& label span': {
      fontWeight: '500!important',
      fontSize: '16px!important',
      display: 'flex!important',
      color: '#000000!important',
    },
  },
  headingImage: {
    color: '#1A1A1A',
    fontWeight: '500',
    fontSize: '18px',
  },
  taglineImage: {
    color: '#767676',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '26px',
  },
  chooseFileBtn: {
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '15px',
    color: '#133FE3',
    background: '#FFFFFF',
    border: '1px solid #1967D2',
    padding: '8px 44px',
    boxShadow: '0px 1px 16px rgba(189, 189, 189, 0.25)',
    borderRadius: '6px',
  },
  formControlColor: {
    flexDirection: 'row!important',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '12px!important',
    width: '100%',
  },

  qrCodeWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  qrCodeWrap__heading: {
    fontWeight: 700,
    fontSize: '60px',
    lineHeight: '57px',
    color: '#000000',
    marginBottom: '10px',
  },
  qrCodeWrap__subHeading: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '38px',
    color: '#000000',
    marginBottom: '16px',
    textAlign: 'center'
  },
  qrCodeWrap__text: {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '20px',
    color: '#000000',
  },
  qrCodeWrap__btn: {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '25px',
    textAlign: 'center',
    color: '#FFFFFF',
    background: '#133FE3',
    borderRadius: '10px',
    padding: '14px 80px 14px 80px',
    marginBottom: '8px',
    '&:hover': {
      color: '#FFFFFF',
      background: '#133FE3',
    },
    '&:focus': {
      color: '#FFFFFF',
      background: '#133FE3',
    },
  },
  qrCodeInputBlock: {
    marginBottom: '30px',
  },
  saveBtn: {
    color: '#fff',
    backgroundColor: '#133FE3 !important',
    borderRadius: '8px',
    padding: '8px 34px'
  },
  cancelBtn: {
    color: '#133FE3 !important',
    borderRadius: '8px',
    background: '#fff',
    border: '1px solid #133FE3',
    padding: '6px 25px',
    '&:hover': {
      background: '#fff',
    }
  },
  heading1: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '38px',
    textAlign: 'center'
  },
  heading2: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '60px',
    lineHeight: '57px',
    textAlign: 'center'
  },
  linkText: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '35px',
    textAlign: 'center',
    flexWrap: 'wrap'
  },
  qRCard: {
    background: '#FFFFFF',
    boxShadow: '0px 1px 16px rgba(189, 189, 189, 0.25)',
    borderRadius: '6px',
    padding: '24px 24px 24px 24px',
    width: '100%',
    // maxWidth: '900px',
    overflow: 'visible',
  }
}));

const QRPage = () => {
  const classes = useStyles();
  const form = useForm({
    resolver: yupResolver(schema),
  });

  const storeData = useSelector((state) => state?.globalStore);

  const companyDetails = storeData?.companyDetails ? storeData?.companyDetails : storage.get('companyDetails')
  const companyId = storeData?.companyDetails?.shareLink;
  const id = storeData?.companyDetails?._id;
  const qrValue = `${process.env.REACT_APP_FRONTEND_BASE_URL}/${companyId}`;

  const [disabled, setDisabled] = useState(false);
  const copyUriInputRef = useRef(null);
  const [qrState, setQrState] = useState({
    color: '',
    backgroundColor: '',
    logo: '',
  });

  const dispatch = useDispatch();


  useEffect(() => {
    setQrState({
      ...qrState,
      // color: API?.qrCode?.color || API?.qrCode?.default.color,
      // backgroundColor:
      //   API?.qrCode?.backgroundColor || API?.qrCode?.default?.backgroundColor,
      logo: companyDetails?.qrCodeInfo?.logo || API?.qrCode?.default?.logo ,
      color: companyDetails?.qrCodeInfo?.color || API?.qrCode?.default.color,
      backgroundColor: companyDetails?.qrCodeInfo?.bgColor || API?.qrCode?.default?.backgroundColor
    });
  }, []);


  const {
    control,
    handleSubmit,
    setError,
    reset,
    errors,
    setValue,
    watch,
    getValues,
  } = form;


  const handleFormSubmit = async (values) => {
    const data = {
      qrCodeInfo : {
        menuLink : `${process.env.REACT_APP_FRONTEND_BASE_URL}/${companyId}`,
        color : qrState?.color,
        bgColor : qrState?.backgroundColor,
      }
    }
 
    setDisabled(true);
    setProgress(SAVE_BTN, true);


    if (values?.logo?.length) {
      let formData = new FormData();
      formData.append("attachment", values?.logo[0]);
        try {
            await http.post(`/api/v1/file-upload`, formData)
                .then(async (res) => {
                  data.qrCodeInfo['logo'] = res?.data?.data[0]?.location;
                    // delete values?.images;
                    try {
                        await http
                            .put(`/api/v1/company/${id}`, { ...data })
                            .then(async (res) => {
                              dispatch({
                                type: 'SET_COMPANY_DETAILS',
                                companyDetails: res?.data?.data,
                            });
                                storage.set("companyDetails", res?.data?.data);
                                toast.success('QR Code Updated Successfully');
                            })
                            .catch((res) => {
                                toast.error(res?.response?.data?.message);
                            });
                    } catch (res) {
                        toast.error(res?.response?.data?.message);
                    }
                });
        } catch (res) {
            toast.error(res?.response?.data?.message)
        }
    }
    else {
        try {
            await http
                .put(`/api/v1/company/${id}`, { ...data })
                .then(async (res) => {
                    storage.set("companyDetails", res?.data?.data);
                    toast.success('QR Code Updated Successfully');
                })
                .catch((res) => {
                    toast.error(res?.response?.data?.message);
                });
        } catch (res) {
            toast.error(res?.response?.data?.message);
        }
    }
    setDisabled(false)
    setProgress(SAVE_BTN, false);
}



  const handleChangeComplete = (color, changedColor) => {
    setQrState({
      ...qrState,
      [color]: changedColor.hex,
    });
  };


  const downloadQRCode = () => {

    setProgress(SAVE_BTN, true);
 
      const input = document.getElementById('qr-gen');
            html2canvas(input)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF();
                    let imgWidth = 130;
                    let imgHeight = (canvas.height * imgWidth) / canvas.width;
                  const pdfWidth = pdf.internal.pageSize.getWidth();
                  const pdfHeight = (imgHeight * pdfWidth) / imgWidth;
                  const x = (pdfWidth - imgWidth) / 2;
                  const y = (pdfHeight - imgHeight) / 2;
                  pdf.addImage(imgData, "PNG",  x, y, imgWidth, imgHeight);
                    window.open(pdf.output('bloburl', { filename: 'qrcode.pdf' }), '_blank');
                    setProgress(SAVE_BTN, false);
                });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={10}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container justifyContent='flex-start' alignItems='center'>
            <Grid item>
              <h1 className={classes.heading}>QR Menu</h1>
              <Typography className={classes.tagline}>
                You can change your QR as per your branding
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Card className={classes.qRCard}>
            <Grid container direction="row" spacing={5}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card className={classes.qRCard}>
                  <Grid container direction="row" spacing={5}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography className={classes.subHeading}>
                        Menu Link
                      </Typography>
                      <Box position={'relative'}>
                        <TextField
                          fullWidth
                          value={`${process.env.REACT_APP_FRONTEND_BASE_URL}/${companyId}`}
                          disabled
                          ref={copyUriInputRef}
                          InputProps={{
                            endAdornment: <CopyIcon style={{ cursor: 'pointer' }} onClick={() => {
                              navigator.clipboard.writeText(
                                `${process.env.REACT_APP_FRONTEND_BASE_URL}/${companyId}`
                              );
                              toast.success('Restaurant URL Copied.');
                            }} />
                          }}
                        />
                        {/* <IconButton
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  `${process.env.REACT_APP_FRONTEND_BASE_URL}/${companyId}`
                                );
                                toast.success('Restaurant URL Copied.');
                              }}
                              sx={{ p: '10px' }}
                              aria-label='copy'
                              style={{
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                right: '4px',
                                zIndex: '1',
                              }}
                            >
                              <CopyIcon />
                            </IconButton> */}
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card className={classes.qRCard}>
                  <Grid container direction="row" spacing={5}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box>
                        <Grid
                          container
                          direction='row'
                          justifyContent='flex-start'
                          alignItems='center'
                          spacing={10}
                        >
                          <Grid item>
                            <ImageController
                              filesLimit={1}
                              acceptedFiles
                              form={form}
                              errors={errors}
                              control={control}
                              name='logo'
                              image={qrState?.logo ? qrState?.logo : storeData?.companyDetails?.qrCodeInfo?.logo}
                            />
                          </Grid>
                          <Grid item>
                            <Grid
                              container
                              direction='column'
                              justifyContent='space-between'
                              alignItems='start'
                              spacing={2}
                            >
                              <Grid item>
                                <h1 className={classes.headingImage}>Add logo</h1>
                                <Typography className={classes.taglineImage}>
                                  Upload Picture following this format PNG, JPG (max.
                                  800x800px)
                                </Typography>
                              </Grid>
                              {/* <Grid item>
                                    <Button
                                      className={classes.chooseFileBtn}
                                      onClick={ImageController.thumbs}
                                    >
                                      Choose File
                                    </Button>
                                  </Grid> */}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card className={classes.qRCard}>
                  <Grid container direction="row" spacing={5}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography className={classes.subHeading} component='h3'>
                        QR Code Color
                      </Typography>
                      <FormControl className={classes.formControlColor}>
                        <FormLabel className={classes.formControlLabel}>
                          Color
                        </FormLabel>
                        <ColorPicker
                          color={qrState?.color}
                          label='color'
                          onChangeComplete={handleChangeComplete}
                        />
                      </FormControl>
                      <FormControl className={classes.formControlColor}>
                        <FormLabel className={classes.formControlLabel}>
                          Background Color
                        </FormLabel>
                        <ColorPicker
                          color={qrState?.backgroundColor}
                          label='backgroundColor'
                          onChangeComplete={handleChangeComplete}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container direction="row" justifyContent="flex-start" spacing={5}>
                  <Grid item>
                    <Button
                      fullWidth={false}
                      color='primary'
                      variant='outlined'
                      style={{ height: '100%' }}
                      disabled={disabled}
                      className={classes.cancelBtn}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Btn
                      type='submit'
                      fullWidth={false}
                      name={SAVE_BTN}
                      className={classes.saveBtn}
                    >
                      Save
                    </Btn>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
          <Card className={classes.qRCard} style={{ height: '100%', display: "flex", alignItems: 'center', flexDirection: 'row' }} >
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
              <div id="qr-gen" styles={{margin:'auto'}}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className={classes.heading1} >
                  RK Kitchen
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className={classes.heading2}>
                  Menu
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography className={classes.linkText}>
                  Scan the QR code with your camera or go to
                </Typography>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography >
                    {`${process.env.REACT_APP_FRONTEND_BASE_URL}/${companyId}`}
                  </Typography>
                </Grid> */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <Box padding={'45px 0 45px 0'} >
                  <QRCodeSVG
                    value={qrValue}
                    imageSettings={{src:storage.get("companyDetails")?.qrCodeInfo?.logo,x: null, y:null,height:50,width:50, excavate:false}}
                    bgColor={qrState?.backgroundColor}
                    fgColor={qrState?.color}
                    title={companyId}
                    level='Q'
                    size={200}
                    sizes={{
                      small: 180,
                      medium: 360,
                      large: 920,
                    }}
                  />
                </Box>
              </Grid>
              </div>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Btn fullWidth className={classes.qrCodeWrap__btn} onClick={() => downloadQRCode()}>Download QR</Btn>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default QRPage;
