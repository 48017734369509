import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {
  Accordion,
  AccordionSummary,
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import {
  Btn,
  CheckboxGroupController,
  ConfirmationDialog,
  InputController,
  RadioGroupController,
  setProgress,
} from 'Components';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import yup from 'Utils/Yup';
import { http } from 'Utils/Http/Http';
import { LocalStorage } from 'Utils/LocalStorage';
import { useHistory } from 'react-router';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import ReactSelect from 'react-select';
import ItemPrice from './ItemPrice';
import ImageControllerFullWidth from 'Components/FormControls/Controllers/ImageControllerFullWidth';
import { useQuery, useQueryClient } from 'react-query';
import { CATEGORYLIST, SIDEDISHLIST, SUBCATEGORYLIST, USERPROFILE } from 'Constants/QueriesKeys';
import vegetarian from "Assets/menu/addItem/vegetarian.svg"
import vegan from "Assets/menu/addItem/vegan.svg"
import nutFree from "Assets/menu/addItem/nutFree.svg"
import glutenFree from "Assets/menu/addItem/glutenFree.svg"
import dairyFree from "Assets/menu/addItem/dairyFree.svg"
import SideDishListModal from './SideDishListModal';
import SideDishCard from './SideDishCard';
import { useParams } from "react-router-dom";
import VideoControllerFullWidth from 'Components/FormControls/Controllers/VideoControllerFullWidth';
import { useSelector } from 'react-redux';
// import AddSideDishModal from 'Pages/SideDish/Components/AddSideDishModal';

const storage = new LocalStorage();

const SAVE_BTN = 'SAVE_BTN';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: "935px",
  maxHeight: "90vh",
  overflow: "scroll",
  borderRadius: '10px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '20px',
};

const useStyle = makeStyles((theme) => ({
  accordionMain: {
    boxShadow: "none",
    border: '1px solid #D0D5DD',
    boxSizing: 'border-box',
    borderRadius: '4px'
  },
  accordionSummary: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  accordionDetails: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  divider: {
    marginBottom: "20px"
  },
  accordionTitle: {
    fontFamily: 'Urbanist',
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "30px",
  },
  label: {
    marginBottom: "6px",
    fontWeight: "400",
    fontSize: '14px',
    lineHeight: '18px',
  },
  a3_title: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '22px',
    marginBottom: "4px"
  },
  a3_text: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#667085',
    marginBottom: "24px"
  },
  heading: {
    color: '#1A1A1A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '30px',
  },
  tagline: {
    color: '#767676',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '26px',
  },
  autoFill: {
    color: '#133FE3',
    cursor: 'pointer',
  },
  spacing: {
    padding: '10px',
  },
  addBtn: {
    color: '#fff',
    backgroundColor: '#133FE3 !important',
    borderRadius: '8px',
    padding: '8px 34px'
  },
  cancelBtn: {
    color: '#133FE3 !important',
    borderRadius: '8px',
    background: '#fff',
    border: '1px solid #133FE3',
    padding: '6px 25px',
    '&:hover': {
      background: '#fff',
    }
  },
  language: {
    border: '1px solid #D0D5DD',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },
  ingredientWarning: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
  },
  optional: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#027A48',
    background: '#ECFDF3',
    borderRadius: '20px',
    padding: "2px 8px"
  }
}));

const schema = yup.object().shape({
  // title: yup.string().required(),
});

const AddItemModal = ({
  open,
  handleClose,
  promptModal,
  setPromptModal,
  selectedItem,
  refetchItemsList,
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  languages
}) => {
  const classes = useStyle();

  const storeData = useSelector((state) => state?.globalStore);

  const { t } = useTranslation();
  const langPref = languages ? languages : storeData?.companyDetails?.languagePreference;
  const primaryLanguage = storeData?.primaryLanguage.charAt(0).toUpperCase() + storeData?.primaryLanguage.slice(1);
  const history = useHistory();
  const { menuId } = useParams();
  const [cancelDisabled, setCancelDisabled] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(primaryLanguage)
  const [direction, setDirection] = useState(storeData?.primaryLanguage == "arabic" ? "rtl" : "ltr")
  const [deleteModal, setDeleteModal] = useState(false)
  const [profileRefetch, setProfileRefetch] = useState(true)

  // const [addSideDishModal, setAddSideDishModal] = useState(false);
  const [sideDishModalOpen, setSideDishModalOpen] = useState(false)
  const [sideDishArray, setSideDishArray] = useState()
  const [sideDishLishRefetch, setSideDishLishRefetch] = useState(true)
  const [preSelectedSideDish, setPreSelectedSideDish] = useState([])

  const [expanded, setExpanded] = useState('panel1');


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const form = useForm({
    resolver: yupResolver(schema),
  });

  const {
    control,
    handleSubmit,
    setError,
    reset,
    errors,
    setValue,
    watch,
  } = form;

  const ingredientWarningArray = [
    { label: <span className={classes.ingredientWarning} style={{ display: "flex" }}><img src={vegetarian} /> &nbsp; {t('admin.menu.addItem.vegetarian')}</span>, value: 'Vegetarian' },
    { label: <span className={classes.ingredientWarning} style={{ display: "flex" }}><img src={vegan} /> &nbsp; {t('admin.menu.addItem.vegan')}</span>, value: 'Vegan' },
    { label: <span className={classes.ingredientWarning} style={{ display: "flex" }}><img src={glutenFree} /> &nbsp; {t('admin.menu.addItem.glutenFree')}</span>, value: 'Gluten Free' },
    { label: <span className={classes.ingredientWarning} style={{ display: "flex" }}><img src={nutFree} /> &nbsp; {t('admin.menu.addItem.nutFree')}</span>, value: 'Nut Free' },
    { label: <span className={classes.ingredientWarning} style={{ display: "flex" }}><img src={dairyFree} /> &nbsp; {t('admin.menu.addItem.dairyFree')}</span>, value: 'Dairy Free' },
  ]

  const queryClient = useQueryClient();

  const fetchData = async () => {
    const data = await queryClient.fetchQuery([SIDEDISHLIST], () =>
      http.get(`api/v1/side-dish`, {
        params: {
          page: 1,
          limit: 100000,
        }
      }).then(({ data }) => {
        setSideDishLishRefetch(false)
        setSideDishArray(data?.data?.records);
        return (data)
      })
    );
  };


  const sideDishListRender = useQuery([SIDEDISHLIST, sideDishModalOpen], () =>
    http.get(`api/v1/side-dish`, {
      params: {
        page: 1,
        limit: 100000,
      }
    }).then(({ data }) => {
      setSideDishLishRefetch(false)
      setSideDishArray(data?.data?.records);
      return (data)
    }),
    {
      enabled: sideDishModalOpen,
    },
  );


  useEffect(() => {
    setSelectedLanguage(primaryLanguage)
    setDirection(storeData?.primaryLanguage == "arabic" ? "rtl" : "ltr")
    CategoryRender.refetch()
    if (selectedItem) {
      var selectedDataDetails = {
        foodType: selectedItem?.foodType,
        menuId: menuId,
        warning: selectedItem?.warning,
        type: [],
        category: {},
        subCategory: []
      }
      for (let q = 0; q < selectedItem?.type?.length; q++) {
        selectedDataDetails['type'][q] = {
          price: selectedItem?.type[q]?.price,
          size: { title: selectedItem?.type[q]?.size },
          calories: selectedItem?.type[q]?.calories,
        }
      }
      for (let d = 0; d < langPref.length; d++) {
        if (selectedItem[langPref[d]?.title?.toLowerCase()]?.title) {
          selectedDataDetails[langPref[d].title?.toLowerCase()] = selectedItem[langPref[d]?.title?.toLowerCase()]
        }
      }
      selectedDataDetails['category'] = categoryListing?.filter((item) => item._id === selectedItem?.categoryId)[0]
      for (let sub = 0; sub < selectedItem?.subCategoryId?.length; sub++) {
        selectedDataDetails['subCategory'][sub] = subCategoryListing?.filter((item) => item._id === selectedItem?.subCategoryId[sub])[0]
      }
      reset({
        ...selectedDataDetails
      })
      setPreSelectedSideDish(selectedItem?.sideDishId)
    } else {
      reset({})
    }
  }, [open])


  const [categoryListRefetch, setCategoryListRefetch] = useState(true)
  const [categoryListing, setCategoryListing] = useState()

  const CategoryRender = useQuery([CATEGORYLIST], () =>
    http.get(`api/v1/category`, {
      params: {
        page: 1,
        limit: 100000,
        menuId: menuId
        // searchString: searchArticle
      }
    }).then(({ data }) => {
      setCategoryListRefetch(false)
      setCategoryListing(data?.data?.records);
      return (data)
    }),
    {
      enabled: categoryListRefetch ? true : false,
    },
  );

  const watchCategory = watch('category')

  const [subCategoryListing, setSubCategoryListing] = useState()

  const SubCategoryRender = useQuery([SUBCATEGORYLIST, { watchCategory }], () =>
    http.get(`api/v1/sub-category`, {
      params: {
        page: 1,
        limit: 100000,
        categoryId: watchCategory?._id,
        menuId: menuId
      }
    }).then(({ data }) => {
      setSubCategoryListing(data?.data?.records);
      return (data)
    }),
  );

  const handleLanguage = (e) => {
    const selectedLanguageTemp = langPref.find(
      (item) => item?.title === e.target.value
    );
    setSelectedLanguage(e.target.value);
    setDirection(selectedLanguageTemp?.direction);
  };

  const handlePrompt = () => {
    try {
      setPromptModal(false);
      handleClose();
      toast.success('Menu Saved Successfully');
      history.push('/admin/menu');
    } catch (error) {
      toast.error('Error, failed !! ');
    }
  }


  const handleDelete = async () => {
    try {
      await http.delete(`/api/v1/item/${selectedItem?._id}`)
        .then((res) => {
          refetchItemsList()
          setSelectedLanguage(primaryLanguage)
          setDirection(storeData?.primaryLanguage == "arabic" ? "rtl" : "ltr")
          handleClose()
          toast.success("Item Deleted.")
        });
    } catch (res) {
      toast.error(res?.response?.data?.message)
    }
    setDeleteModal(false)
  }

  const handleFormSubmit = async (data) => {

    if (data?.subCategoryId[0] === undefined || data?.subCategoryId[0] === null) {
      data.subCategoryId = [];
    }

    if (selectedItem?._id) {
      try {
        await http.put(`/api/v1/item/${selectedItem?._id}`, data)
          .then((res) => {
            setSelectedLanguage(primaryLanguage)
            setDirection(storeData?.primaryLanguage == "arabic" ? "rtl" : "ltr")
            handleClose()
            toast.success("Item Updated")
            refetchItemsList()
            setPreSelectedSideDish([])
          });
      } catch (res) {
        toast.error(res?.response?.data?.message)
      }
    } else {
      try {
        await http.post(`/api/v1/item`, data)
          .then((res) => {
            refetchItemsList()
            setPreSelectedSideDish()
            setSelectedLanguage(primaryLanguage)
            setDirection(storeData?.primaryLanguage == "arabic" ? "rtl" : "ltr")
            handleClose()
            toast.success("Item Added")
          });
      } catch (res) {
        toast.error(res?.response?.data?.message)
      }
    }
    setCancelDisabled(false)
    setProgress(SAVE_BTN, false);
  };

  const handleVideoUpload = async (data, values) => {

    let videoFormData = new FormData();

    for (var i = 0; i < values.video?.length; i++) {
      videoFormData.append("video", values.video[i]);
    }

    if (values?.video?.length) {
      try {
        await http.post(`/api/v1/file-upload`, videoFormData)
          .then((res) => {
            data['video'] = {
              url: res?.data?.data[0]?.location,
              title: res?.data?.data[0]?.originalname,
            };
            if (values?.subCategory?.length > 1) {
              const subCategoryIds = values.subCategory.map(subCategory => subCategory._id);
              data.subCategoryId = subCategoryIds;
            }
            else {
              data['subCategoryId'][0] = values?.subCategory?._id
            }
            handleFormSubmit(data)
          });
      } catch (res) {
        toast.error(res?.response?.data?.message)
        setCancelDisabled(false)
        setProgress(SAVE_BTN, false);
      }
    } else {
      if (selectedItem?.video) {
        data['video'] = selectedItem?.video[0]
      }

      if (values?.subCategory !== [] && values?.subCategory?.length > 1 || values?.subCategory !== [] && values?.subCategory?.length == 1) {
        const subCategoryIds = values.subCategory.map(subCategory => subCategory._id);
        data.subCategoryId = subCategoryIds;
      }
      else if (typeof values?.subCategory === "object" || values?.subCategory !== []) {
        data['subCategoryId'][0] = values?.subCategory?._id
      }
      else {
        data.subCategoryId = []
      }
      handleFormSubmit(data)
    }
  }

  const handleImageUpload = async (values) => {

    if (values[langPref[0].title?.toLowerCase()]?.title?.length == 0) {
      toast?.error('Please fill primary language details.')
    }
    else {

      setProgress(SAVE_BTN, true);
      setCancelDisabled(true)


      var data = {
        type: [],
        foodType: values?.foodType,
        categoryId: values?.category?._id,
        subCategoryId: [],
        menuId: menuId,
        sideDishId: preSelectedSideDish,
        warning: values.warning ? values.warning : [],
        images: [],
        video: {}
      }
      for (let q = 0; q < values?.type?.length; q++) {
        data['type'][q] = {
          size: values?.type[q]?.size?.title,
          price: values?.type[q]?.price,
          calories: values?.type[q]?.calories
        }
      }

      for (let sub = 0; sub < values?.subCategory?.length; sub++) {
        data['subCategoryId'][sub] = values?.subCategory[sub]?._id
      }


      for (let d = 0; d < langPref.length; d++) {
        if (values[langPref[d]?.title?.toLowerCase()]?.title) {
          data[langPref[d].title?.toLowerCase()] = values[langPref[d]?.title?.toLowerCase()]
        }
      }

      let imageFormData = new FormData();

      for (var i = 0; i < values.images?.length; i++) {
        imageFormData.append("images", values.images[i]);
      }

      if (values?.images?.length) {
        try {
          await http.post(`/api/v1/file-upload`, imageFormData)
            .then((res) => {
              data['images'] = Array.from(res?.data?.data, (item) => {
                return {
                  url: item.location,
                  title: item.originalname,
                };
              });
              handleVideoUpload(data, values)
            });
        } catch (res) {
          toast.error(res?.response?.data?.message)
          setCancelDisabled(false)
          setProgress(SAVE_BTN, false);
        }
      } else {
        if (selectedItem?.images) {
          data['images'] = selectedItem?.images
          handleVideoUpload(data, values)
        } else {
          // toast.error("please select image")
          // setCancelDisabled(false)
          // setProgress(SAVE_BTN, false);
          handleVideoUpload(data, values)
        }
      }
    }
  }

  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form className='space-y-4' onSubmit={handleSubmit(handleImageUpload)}>
              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
                className={classes.spacing}
                spacing={5}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item>
                      <h1 className={classes.heading}>{selectedItem?._id ? t('admin.menu.addItem.editItem') : t('admin.menu.addItem.addItem')}</h1>
                      <Typography className={classes.tagline}>
                        {t('admin.menu.addItem.addItemText')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Grid item>
                          <FormControl
                            variant='standard'
                            sx={{ m: 1, minWidth: 100 }}
                          >
                            <Select
                              variant='outlined'
                              control={control}
                              className={classes.language}
                              name="language"
                              value={selectedLanguage}
                              size='small'
                              onChange={handleLanguage}
                            >
                              {langPref?.map((item, index) => {
                                return (
                                  <MenuItem value={item?.title} key={index}>
                                    {item?.title == primaryLanguage ? item?.title + " " + "(primary)" : item?.title}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={5}>
                    <Grid item xs={12}>
                      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={classes.accordionMain}>
                        <AccordionSummary className={classes.accordionSummary}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.accordionTitle}>{t('admin.menu.addItem.addItemDetails')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                          <Divider className={classes.divider} />
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              {langPref?.map((langItem, index) => {
                                return (
                                  selectedLanguage === langItem?.title ?
                                    <Grid container spacing={4} key={index}>
                                      <Grid item xs={12} style={{ direction: direction }}>
                                        <InputController
                                          control={control}
                                          name={`${langItem?.title?.toLowerCase()}.title`}
                                          placeholder={t('admin.menu.addItem.addItemTitle')}
                                          label={t('admin.menu.addItem.itemTitle')}
                                        />
                                      </Grid>
                                      <Grid item xs={12} style={{ direction: direction }}>
                                        <InputController
                                          control={control}
                                          name={`${langItem?.title?.toLowerCase()}.description`}
                                          multiline
                                          rows={4}
                                          placeholder={t('admin.menu.addItem.typeItemDesc')}
                                          label={t('admin.menu.addItem.itemDesc')}
                                        />
                                      </Grid>
                                    </Grid>
                                    : null
                                )
                              })}
                            </Grid>
                            <Grid item xs={12}>
                              <h6 className={classes.label}>{t('admin.menu.addItem.selectVegNonVeg') + "*"}</h6>
                              <RadioGroupController
                                control={control}
                                name="foodType"
                                row
                                options={[
                                  {
                                    label: t("admin.menu.addItem.veg"),
                                    value: "veg",
                                  },
                                  {
                                    label: t("admin.menu.addItem.nonVeg"),
                                    value: "non-veg",
                                  }
                                ]}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <h6 className={classes.label}>{t('admin.menu.addItem.selectCategory') + "*"}</h6>
                              <Controller
                                name='category'
                                isClearable
                                control={control}
                                // className={classes.primary}
                                defaultValue={selectedCategory}
                                // style={{ height: '100px !important' }}
                                render={({ field }) => (
                                  <ReactSelect
                                    {...field}
                                    placeholder={t('admin.menu.addItem.selectCategory')}
                                    className={classes.primary}
                                    options={categoryListing}
                                    getOptionValue={(options) => options}
                                    getOptionLabel={(options) => options[selectedLanguage?.toLowerCase()]?.title}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <h6 className={classes.label}>{t('admin.menu.addItem.selectSubCategory')}</h6>
                              <Controller
                                name='subCategory'
                                isClearable
                                control={control}
                                className={classes.primary}
                                defaultValue={selectedSubCategory}
                                // style={{ height: '100px !important' }}
                                render={({ field }) => (
                                  <ReactSelect
                                    {...field}
                                    placeholder={t('admin.menu.addItem.selectSubCategory')}
                                    // style={{width:'100% !important',height:'56px !important'}}
                                    isMulti
                                    className={classes.primary}
                                    options={subCategoryListing}
                                    getOptionValue={(options) => options}
                                    getOptionLabel={(options) => options[selectedLanguage?.toLowerCase()]?.title}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                      <Accordion className={classes.accordionMain} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary className={classes.accordionSummary}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography className={classes.accordionTitle}>{t('admin.menu.addItem.itemPrice')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                          <Divider className={classes.divider} />
                          <ItemPrice
                            form={form}
                            control={control}
                            errors={errors}
                            watch={watch}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion className={classes.accordionMain} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary className={classes.accordionSummary}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography className={classes.accordionTitle}>{t('admin.menu.addItem.itemImageVideo')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                          <Divider className={classes.divider} />
                          <Grid contianer>
                            <Grid item xs={12}>
                              <h3 className={classes.a3_title}>{t('admin.menu.addItem.itemImage') + "*"}</h3>
                              <p className={classes.a3_text}> {t('admin.menu.addItem.itemImageText')}</p>
                              <ImageControllerFullWidth
                                name="images"
                                filesLimit={5}
                                acceptedFiles
                                form={form}
                                errors={errors}
                                image={selectedItem?.images}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Divider style={{ margin: "25px 0" }} />
                            </Grid>
                            <Grid item xs={12}>
                              <h3 className={classes.a3_title}>{t('admin.menu.addItem.itemVideo')} <span className={classes.optional}>{t('admin.menu.addItem.optional')}</span></h3>
                              <p className={classes.a3_text}>{t('admin.menu.addItem.itemVideoText')}</p>
                              <VideoControllerFullWidth
                                name="video"
                                filesLimit={1}
                                acceptedFiles
                                form={form}
                                errors={errors}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion className={classes.accordionMain} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary className={classes.accordionSummary}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography className={classes.accordionTitle}>{t('admin.menu.addItem.addSideDish')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                          <Divider className={classes.divider} />
                          <Grid container justifyContent="flex-end">
                            {preSelectedSideDish?.map((sideDish, index) => {
                              return (
                                <Grid item xs={12} key={index}>
                                  <SideDishCard item={sideDish} langPref={langPref} sideDishArray={sideDishArray} />
                                  <Divider style={{ margin: "20px 0" }} />
                                </Grid>
                              )
                            })}
                            <Grid item xs={12}>
                              <Grid container justifyContent="flex-end">
                                <Button
                                  onClick={() => setSideDishModalOpen(true)}
                                  className="btn_addMore"
                                >
                                  {preSelectedSideDish?.length ? `+ ${t("admin.menu.addItem.addMore")}` : t('admin.menu.addItem.addSideDish')}
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                      <Accordion className={classes.accordionMain} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                        <AccordionSummary className={classes.accordionSummary}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography className={classes.accordionTitle}>{t('admin.menu.addItem.ingredientWarning')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                          <Divider className={classes.divider} />
                          <CheckboxGroupController
                            control={control}
                            name="warning"
                            row
                            options={ingredientWarningArray}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container justifyContent="space-between" alignItems="center">
                    {selectedItem?._id ?
                      <Grid item>
                        <Button className='btn_delete' onClick={() => setDeleteModal(true)}> Delete item</Button>
                      </Grid>
                      :
                      <Grid item></Grid>
                    }
                    <Grid item>
                      <Grid container justifyContent="flex-end" spacing={3}>
                        <Grid item>
                          <Btn className={classes.cancelBtn} disabled={cancelDisabled} onClick={handleClose}>Cancel</Btn>
                        </Grid>
                        <Grid item>
                          <Btn type='submit' className={classes.addBtn} name={SAVE_BTN}>
                            {selectedItem ? 'Update' : 'Add'}
                          </Btn>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Fade>
      </Modal>
      <ConfirmationDialog isOpen={deleteModal} title={'Are you sure ?'} text={'Click continue to delete this Item'} onReject={() => setDeleteModal(false)} onResolve={handleDelete} />
      <ConfirmationDialog
        isOpen={promptModal}
        title={'Are you sure ?'}
        text={
          'Do you want to add/edit item without adding in diffrent language?'
        }
        onReject={() => setPromptModal(false)}
        onResolve={handlePrompt}
      />

      <SideDishListModal
        open={sideDishModalOpen}
        handleClose={() => setSideDishModalOpen(false)}
        langPref={langPref}
        sideDishArray={sideDishArray}
        preSelectedSideDish={preSelectedSideDish}
        setPreSelectedSideDish={setPreSelectedSideDish}
        setSideDishModalOpen={setSideDishModalOpen}
      />
    </>
  );
};

export default AddItemModal;
