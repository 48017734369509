import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import clsx from 'clsx';
import SidebarForMobile from './SidebarForMobile';
import { TextField, InputAdornment, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ConfirmationDialog from 'Components/Dialogs/ConfirmationDialog';
import { LocalStorage } from 'Utils/LocalStorage';
import ProfileButton from './ProfileButton';
import PasswordChangeModal from './PasswordChangeModal';
import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';



const useStyle = makeStyles((theme) => ({
    desktopNav: {
        padding: '30px 0px',
        [theme.breakpoints.down("md")]: {
            padding: '20px 0px',
        },
    },
    desktopNavLeft: {
        padding: '0px 50px',
        maxWidth: "332px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down("md")]: {
            maxWidth: "100%",
            padding: '0px 10px',
        },
    },
    desktop: {
        display: "block",
        padding: '0px 50px',
        [theme.breakpoints.down("md")]: {
            display: "none",
            paddingLeft: "0",
        },
    },
    mobile: {
        display: "none",
        padding: '10px',
        [theme.breakpoints.down("md")]: {
            display: "block",
        },
    }
}));


const storage = new LocalStorage();

const user = {
    name: 'Chelsea Hagon',
    email: 'chelseahagon@example.com',
    imageUrl:
        'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
};

export default function Navbar({ links }) {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const classes = useStyle();
    const [logoutModal, setLogoutModal] = useState(false)
    const [passwordModal, setPasswordModal] = useState(false);

    const dispatch = useDispatch();

    const storeData = useSelector((state) => state?.globalStore);

    const changeLanguage = () => {
        storage.set('language', i18n.language === 'ar' ? 'en' : 'ar');
        i18n.changeLanguage(i18n.language === 'ar' ? 'en' : 'ar');
    };

    // const handleLogout = async () => {
    //     try {
    //         await http.post(`/auth/logout`, {})
    //             .then(async (res) => {
    //                 storage.remove('userToken');
    //                 storage.remove('user');
    //                 history.push('/sign-in');
    //             });
    //     } catch (error) {
    //         handleError(error);
    //     }
    // }
    const handleLogout = () => {
        try {
            dispatch({
                type: 'SET_LOGOUT',
            });
            storage.remove('userToken');
            storage.remove('user');
            setLogoutModal(false);
            toast.success(t("toast.success.logoutSucces"));
            history.push('/sign-in');
        } catch (error) {
            toast.error(t("toast.error.logoutFailed"));
        }
    }

    return (
        <Popover
            as="header"
            className={({ open }) =>
                clsx(
                    open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
                    'bg-white lg:static lg:overflow-y-visible',
                )
            }
            style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}
        >
            {({ open }) => (
                <>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.desktopNav}>
                        <Grid item xs={12} md={3} className={classes.desktopNavLeft}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" >
                                <Grid item >
                                    <a href="/admin/menu">
                                        <img className="block w-auto h-16" src={storeData?.companyDetails?.logo ? storeData?.companyDetails?.logo : storage.get('companyDetails')?.logo} alt="logo" />
                                    </a>
                                </Grid>

                                <Grid item>
                                    <a href="/admin/dashboard">
                                     <p>&nbsp; {storeData?.companyDetails?.title}</p>
                                    </a>
                                </Grid>
                            </Grid>
                            <div className="md:hidden">
                                <SidebarForMobile links={links} />
                            </div>
                        </Grid>
                        <Grid item style={{ flexGrow: "1" }}>
                            <div className={classes.desktop}>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center" >
                                    <Grid item >
                                        <div className="flex items-center">
                                            {/* <TextField
                                                InputLabelProps={{ shrink: true }}
                                                // className={classes.root}
                                                variant='outlined'
                                                placeholder='Search something....'
                                                // label="Search"
                                                size='small'
                                                style={{ width: '100%', borderRadius: '15px' }}
                                                // onChange={(e) => setSearchString(e.target.value)}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end"><SearchIcon style={{ cursor: 'pointer' }} /></InputAdornment>,
                                                }}
                                            /> */}
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div className="flex items-center">
                                            {/* <Button onClick={changeLanguage}>
                                    <LanguageIcon />&nbsp;{t('common.lang')}
                                </Button> */}
                                            {
                                                storage.get("user") ?
                                                        <ProfileButton setLogoutModal={setLogoutModal} setPasswordModal={setPasswordModal} profilePicture={storeData?.companyDetails?.profilePicture}/>
                                                    : ""
                                            }
                                            {/* <Tooltip title={t('common.logout')}>
                                    <IconButton onClick={() => setLogoutModal(true)}>
                                        <LogoutIcon />
                                    </IconButton>
                                </Tooltip> */}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className={classes.mobile}>
                                <Grid container direction="row" justifyContent="center" alignItems="center" >
                                    <Grid item xs={12} sm={12} md={12}>
                                        <div className="flex items-center">
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                // className={classes.root}
                                                variant='outlined'
                                                placeholder='Search something....'
                                                // label="Search"
                                                size='small'
                                                style={{ width: '100%', borderRadius: '15px' }}
                                                // onChange={(e) => setSearchString(e.target.value)}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end"><SearchIcon style={{ cursor: 'pointer' }} /></InputAdornment>,
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    {/* </div>
                    </div> */}
                    <Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
                        <div className="max-w-3xl px-2 pt-2 pb-3 mx-auto space-y-1 sm:px-4">
                            {links.map((item,index) => (
                                <a
                                    key={index}
                                    href={item.href}
                                    aria-current={item.current ? 'page' : undefined}
                                    className={clsx(
                                        item.current ? 'bg-gray-100 text-gray-900' : 'hover:bg-gray-50',
                                        'block rounded-md py-2 px-3 text-base font-medium',
                                    )}>
                                    {item.name}
                                </a>
                            ))}
                        </div>
                        <div className="pt-4 pb-3 border-t border-gray-200">
                            <div className="flex items-center max-w-3xl px-4 mx-auto sm:px-6">
                                <div className="flex-shrink-0">
                                    <img className="w-10 h-10 rounded-full" src={user.imageUrl} alt="" />
                                </div>
                                <div className="ml-3">
                                    <div className="text-base font-medium text-gray-800">{user.name}</div>
                                    <div className="text-sm font-medium text-gray-500">{user.email}</div>
                                </div>
                                <button
                                    type="button"
                                    className="flex-shrink-0 p-1 ml-auto text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500">
                                    <span className="sr-only">View notifications</span>
                                    {/* <BellIcon className="w-6 h-6" aria-hidden="true" /> */}
                                </button>
                            </div>
                            {/* <div className="max-w-3xl px-2 mx-auto mt-3 space-y-1 sm:px-4">
                                {userNavigation.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="block px-3 py-2 text-base font-medium text-gray-500 rounded-md hover:bg-gray-50 hover:text-gray-900">
                                        {item.name}
                                    </a>
                                ))}
                            </div> */}
                        </div>
                    </Popover.Panel>
                    <ConfirmationDialog isOpen={logoutModal} title={t('common.logout')} text={t('common.logoutText')} onReject={() => setLogoutModal(false)} onResolve={handleLogout} />
                    <PasswordChangeModal open={passwordModal} handleClose={() => setPasswordModal(false)} />
                </>
            )}
        </Popover>
    );
}
