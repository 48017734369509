import { Typography, Card, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles((theme) => ({
  themeCard: {
    background: '#FFFFFF',
    boxShadow: '0px 1px 16px rgba(189, 189, 189, 0.25)',
    borderRadius: '6px',
    padding: '24px 24px 24px 12px',
    width: '100%',
    maxWidth: '900px',
    overflow: 'visible'
  },
  themeCardHeading: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    textTransform: 'capitalize',
    color: '#1A1A1A',
  },
  themeCardTagline: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '22px',
    color: '#767676',
  }
}));

const ThemeCard = ({ heading = '', tagline = '', children = null, className="" }) => {
  const classes = useStyle();
  return (
    <Card className={classes.themeCard + " " + className}>
      <Grid container justifyContent='space-between' alignItems='flex-start'>
        <Grid flex="1" item>
          {heading && (
            <Typography className={classes.themeCardHeading} component='h2'>
              {heading}
            </Typography>
          )}
          {tagline && (
            <Typography
              style={{
                marginBottom: children ? '16px' : '0px',
              }}
              className={classes.themeCardTagline}
              component='p'
            >
              {tagline}
            </Typography>
          )}
          {children && children}
        </Grid>
      </Grid>
    </Card>
  );
};

export default ThemeCard;
