// import image from url
import defaultTheme from '../Assets/themes/default.png';
import theme1 from '../Assets/themes/theme1.png';
import theme2 from '../Assets/themes/theme2.png';
import theme3 from '../Assets/themes/theme3.png';

const themeAPI = {
  themes: [
    {
      "createdAt": 1650285483507,
      "_id": "6259550c0228ebd30c0323e8",
      "title": "default",
      "isActive": true,
      "previewImage": defaultTheme,
      "textColor": "#000000",
      "highlightColor": "#ff7f00",
      "bgColor": "#000000",
      "bgImage": defaultTheme,
      "isDeleted": false,
      "isDefault": true
    },
    {
      "createdAt": 1650285483507,
      "_id": "6259550c0228ebd30c0323e9",
      "title": "theme1",
      "isActive": true,
      "previewImage": theme1,
      "textColor": "#ff0000",
      "highlightColor": "#ff7f00",
      "bgColor": "#ff0000",
      "bgImage": theme1,
      "isDeleted": false,
      "isDefault": false
    },
    {
      "createdAt": 1650285483507,
      "_id": "6259550c0228ebd30c0323ea",
      "title": "theme2",
      "isActive": true,
      "previewImage": theme2,
      "textColor": "#00ff00",
      "highlightColor": "#ff7f00",
      "bgColor": "#00ff00",
      "bgImage": theme2,
      "isDeleted": false,
      "isDefault": false
    },
    {
      "createdAt": 1650285483507,
      "_id": "6259550c0228ebd30c0323eb",
      "title": "theme3",
      "isActive": true,
      "previewImage": theme3,
      "textColor": "#0000ff",
      "highlightColor": "#ff7f00",
      "bgColor": "#0000ff",
      "bgImage": theme3,
      "isDeleted": false,
      "isDefault": false
    }

  ],
};

export default themeAPI;
