import { Card, Grid, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { http } from 'Utils/Http/Http';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const useStyle = makeStyles((theme) => ({
  card: {
    padding: '10px',
    boxShadow: '0px 1px 16px rgba(189, 189, 189, 0.25)',
    border: '2px solid #EFEFF4',
    borderRadius: '10px',
  },
  mainDiv: {
    position: 'relative',
  },
  editIcon: {
    color: '#000',
    backgroundColor: '#fff',
    padding: '3px',
    borderRadius: '50px',
    position: 'absolute',
    zIndex: 1,
    right: '10px',
    marginTop: '10px',
    cursor: 'pointer',
  },
  menuImage: {
    textAlign: 'center',
    width: '100%',
    height: '185px',
    borderRadius: '10px',
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '16px'
  }
}));

const SideDishCard = ({ item, setSelectedSideDish, langPref, setAddSideDishModal,sideDishListRefetch }) => {
  const classes = useStyle();

  const storeData = useSelector((state) => state?.globalStore);

  const handleEdit = () => {
    setSelectedSideDish(item)
    setAddSideDishModal(true)
  }
  const handleDisable = (event) => {
    try {
      http.put(`/api/v1/side-dish/${item?._id}`, { isEnabled: event.target.checked })
        .then(async (res) => {
          if (res?.data?.data?.isEnabled === true) {
            toast.success("Side Dish Enabled");
            sideDishListRefetch()
          } else {
            toast.success("Side Dish Disabled");
            sideDishListRefetch()
          }
        });
    } catch (res) {
      toast.error(res?.response?.data?.message)
    }
  }
  return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card className={classes.card}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={5}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className={classes.mainDiv}>
                  <div className={classes.editIcon}>
                    <EditOutlinedIcon onClick={handleEdit} />
                  </div>
                  <img src={item?.images[0]?.url} className={classes.menuImage} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  spacing={5}
                >
                  <Grid item>
                    <h1 className={classes.title}>{item[storeData?.primaryLanguage]?.title}</h1>
                  </Grid>
                  <Grid item>
                    <Switch defaultChecked={item?.isEnabled} onChange={(event) => handleDisable(event)} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  spacing={5}
                >
                  <Grid item>
                    <h3>$ {item?.price}</h3>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default SideDishCard;
