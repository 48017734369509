import { Grid, MenuItem, Typography } from '@mui/material'
import { InputController } from 'Components'
import ImageController from 'Components/FormControls/Controllers/ImageController';
import { countries_list } from 'Constants/CountryArray';
import React from 'react'
import { useTranslation } from 'react-i18next';
import styles from "../Signup.module.css"

const FirstStep = (props) => {
  const { control, form, errors } = props;
  const { t } = useTranslation();


  
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <h3 className={styles.formTitle}>{t('signUp.formdata.form1.title')}</h3>
          <p className={styles.formText}>{t('signUp.formdata.form1.text')}</p>
        </Grid>
        <Grid item xs={12} >
          <InputController
            control={control}
            fullWidth
            select
            size="small"
            name="country"
            label={t('settings.selectCountry')}
            style={{ marginBottom: '9px' }}
          >
            {countries_list?.map((item,index) => {
              return (
                <MenuItem value={item?.Name} key={index}>
                   {item?.Name} {item?.Unicode}
                </MenuItem>
              );
            })}
          </InputController>
        </Grid>
        <Grid item xs={12} >
          <InputController
            control={control}
            size="small"
            fullWidth
            errors={errors}
            name="title"
            label={t('formLabels.businessName')}
            style={{ marginBottom: '9px' }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container
            justifyContent='flex-start'
            alignItems='center' spacing={3}>
            <Grid item>
              <ImageController
                name="businessLogo"
                filesLimit={1}
                acceptedFiles
                form={form}
                errors={errors}
              />
            </Grid>
            <Grid item>
              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={10}
              >
                <Grid item >
                  <h1 className={styles.title2}>{t("formLabels.businessLogo")}</h1>
                  <Typography className={styles.tagline}>
                    Upload Picture following this format PNG, JPG (max.
                    800x800px)
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default FirstStep