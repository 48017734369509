import { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material';
import ThemeCard from './Components/ThemeCard';
import { makeStyles } from '@mui/styles';
import ThemeBox from './Components/ThemeBox/ThemeBox';
import { http } from 'Utils/Http/Http';
import { LocalStorage } from 'Utils/LocalStorage';
import ImageController from 'Components/FormControls/Controllers/ImageController';
import { Btn } from 'Components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import yup from 'Utils/Yup';
import ColorPicker from '../QRPage/Components/ColorPicker';
import { Box } from '@mui/system';
import useGetTemplates from '../../Hooks/useGetTemplates';
import { useDispatch, useSelector } from 'react-redux';

const SAVE_BTN = 'SAVE_BTN';
const storage = new LocalStorage();

const schema = yup.object().shape({});

const useStyle = makeStyles((theme) => ({
  heading: {
    fontWeight: 600,
    fontSize: '30px',
    textTransform: 'capitalize',
    color: '#1A1A1A',
  },
  tagline: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '26px',
    color: '#767676',
  },
  formControlColor: {
    flexDirection: 'row!important',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '12px!important',
    marginLeft: '32px!important',
    width: 'calc(100% - 32px)',
  },
  themPageCard: {
    '& label': {
      fontWeight: '500!important',
      fontSize: '16px!important',
      display: 'flex!important',
      color: '#000000!important',
      marginBottom: '8px!important',
    },
    '& label span': {
      fontWeight: '500!important',
      fontSize: '16px!important',
      display: 'flex!important',
      color: '#000000!important',
    },
  },
  formControlColorInput: {
    marginTop: '0!important',
    '& input': {
      width: '28px',
      height: '28px',
      padding: '0',
    },
    '&:before': {
      border: 'none',
      display: 'none',
    },
    '&:after': {
      border: 'none',
      display: 'none',
    },
  },
  colorPicker1Wrapper: {
    '> div': {
      marginTop: 'initial',
      flexWrap: 'initial',
      minHeight: 'auto',
    },
  },
  headingImage: {
    color: '#1A1A1A',
    fontWeight: '500',
    fontSize: '18px',
  },
  taglineImage: {
    color: '#767676',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '26px',
  },
  chooseFileBtn: {
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '15px',
    color: '#133FE3',
    background: '#FFFFFF',
    border: '1px solid #1967D2',
    padding: '8px 44px',
    boxShadow: '0px 1px 16px rgba(189, 189, 189, 0.25)',
    borderRadius: '6px',
  },
  saveBtn: {
    color: '#fff',
    backgroundColor: '#133FE3 !important',
    borderRadius: '8px',
    padding: '8px 34px'
  },
  cancelBtn: {
    color: '#133FE3 !important',
    borderRadius: '8px',
    background:'#fff',
    border: '1px solid #133FE3',
    padding: '6px 25px',
    '&:hover': {
      background:'#fff',
  }
  },
}));
const ThemePage = () => {
  const classes = useStyle();

  const [disabled, setDisabled] = useState(false);

  const storeData = useSelector((state) => state?.globalStore);

  const dispatch = useDispatch();

  const [themeTextRadioSelected, setThemeTextRadioSelected] = useState('default');
  const [themeBgRadioSelected, setThemeBgRadioSelected] = useState('default');
  const [activeStyle, setActiveStyle] = useState({
    textColor: '',
    highlightColor: '',
    bgColor: '',
    bgImage: ''
  });

  const {
    themeList,
    defaultTheme,
    activeTheme,
    setActiveTheme,
    profileTheme,
    setProfileTheme,
  } = useGetTemplates();

  const [updateTheme, setUpdateTheme] = useState(false);

  const companyId = storeData?.companyDetails?._id ? storeData?.companyDetails?._id : storage.get('companyDetails');

  const companyDetails = storeData?.companyDetails ? storeData?.companyDetails : storage.get('companyDetails');

  const form = useForm({
    resolver: yupResolver(schema),
  });

  const {
    control,
    handleSubmit,
    errors,
  } = form;

  useEffect(() => {
    // console.log("testt : ",activeTheme?.bgColor === activeStyle?.bgColor ,  activeTheme?.bgImage === activeStyle?.bgImage);
    if (!activeTheme) {
      return;
    }
    if (
      activeTheme?.textColor === activeStyle?.textColor &&
      activeTheme?.highlightColor === activeStyle?.highlightColor
    ) {
      setThemeTextRadioSelected('default');
    } else {
      setThemeTextRadioSelected('custom');
    }
    if(activeTheme?.bgColor == activeStyle?.bgColor &&  activeTheme?.bgImage == activeStyle?.bgImage) {
      setThemeBgRadioSelected('default');
    } else if(activeTheme?.bgImage !== activeStyle?.bgImage && !activeStyle?.bgColor ) {
      setThemeBgRadioSelected('image');
    } else {
      setThemeBgRadioSelected('solid');
    }
  }, [activeTheme]);

  // console.log("test ",activeStyle,activeTheme);


  const handleChangeThemeTextRadioSelect = (e) => {
    setThemeTextRadioSelected(e.target.value);
    if (e.target.value === 'default') {
      setActiveStyle({
        ...activeStyle,
        textColor: activeTheme?.textColor,
        highlightColor: activeTheme?.highlightColor,
      });
    }
  };

  const handleChangeThemeBgRadioSelect = (e) => {
    setThemeBgRadioSelected(e.target.value);
    if (e.target.value === 'default') {
      setActiveStyle({
        ...activeStyle,
        bgColor: activeTheme?.bgColor,
        bgImage: activeTheme?.bgImage,
      });
    } else if (e.target.value === 'solid') {
      setActiveStyle({
        ...activeStyle,
        bgColor: activeTheme?.bgColor,
        bgImage: '',
      });
    } else if (e.target.value === 'image') {
      setActiveStyle({
        ...activeStyle,
        bgImage: activeTheme?.bgImage,
        bgColor: activeTheme?.bgColor,
      });
    }
  };


  const handleSetThemeClick = (theme) => {
    setActiveTheme(theme);
    setThemeBgRadioSelected('default');
    setThemeTextRadioSelected('default');
    setActiveStyle({
      ...activeStyle,
      textColor: theme.textColor,
      highlightColor: theme.highlightColor,
      bgColor: theme.bgColor,
      bgImage: theme.bgImage,
    });
  };

  const handleCancel = () => {};


  useEffect(() => {
    const fetchProfile = async () => {
      if (companyId) {
        const data  = await http
          .get(`/api/v1/company/${companyId}`)
          .catch((err) => {
            console.log(err);
          });
            if (data) {
              dispatch({
                type: 'SET_COMPANY_DETAILS',
                companyDetails: data?.data?.data,
              });
              const profileThemeLoaded = themeList.find((theme) => {
                return theme?.title == data?.data?.data?.template?.title;
              });
              setProfileTheme(profileThemeLoaded);
              setActiveTheme(profileThemeLoaded);
    
              const profileStyleLoaded = {
                textColor: data?.data?.data?.template?.textColor,
                bgColor: data?.data?.data?.template?.bgColor,
                highlightColor: data?.data?.data?.template?.highlightColor,
                bgImage: data?.data?.data?.template?.bgImage,
              };
    
              setActiveStyle({
                ...activeStyle,
                ...profileStyleLoaded,
              });
            } else {
              setActiveTheme(defaultTheme);
              setActiveStyle({
                ...activeStyle,
                textColor: defaultTheme?.textColor,
                bgColor: defaultTheme?.bgColor,
                highlightColor: defaultTheme?.highlightColor,
                bgImage: defaultTheme?.bgImage,
              });
            }
      }
    };
    fetchProfile();
  }, [themeList, companyId, defaultTheme]);

  useEffect(() => {
    const updateProfileTheme = async () => {
      if (companyId && activeTheme && updateTheme) {

        const themeData  = {
          template: {
            title: activeTheme?.title,
            previewImage: activeTheme?.previewImage,
            textColor: activeStyle?.textColor
              ? activeStyle?.textColor
              : activeTheme?.textColor,
            highlightColor: activeStyle?.highlightColor
              ? activeStyle?.highlightColor
              : activeTheme?.highlightColor,
            bgColor: activeStyle?.bgColor
              ? activeStyle?.bgColor
              : activeTheme?.bgColor,
            bgImage: activeStyle?.bgImage
              ? activeStyle?.bgImage
              : activeTheme?.bgImage,
            default: {
              textColor: activeTheme?.textColor,
              highlightColor: activeTheme?.highlightColor,
              bgColor: activeTheme?.bgColor,
              bgImage: activeTheme?.bgImage,
            },
          },
          logo: activeStyle?.businessLogo,
        }
        // console.log("data sss: ",!activeStyle?.bgImage, activeStyle?.bgColor);

        // if(themeBgRadioSelected == 'default'){
        //    themeData = themeData;
        // }
        // else if(!activeStyle?.bgImage){
        //   delete themeData.template.bgImage
        // }
        // else if(!activeStyle?.bgColor || activeStyle?.bgColor == "#ff0000"){
        //   delete themeData.template.bgColor
        // }

        if (themeBgRadioSelected !== 'default') {
          if (!activeStyle?.bgImage) {
            delete themeData.template.bgImage;
          }
        
          if (!activeStyle?.bgColor || activeStyle?.bgColor === "#ff0000") {
            delete themeData.template.bgColor;
          }
        }
        

        //  console.log("data 22222: ",themeData.template.bgColor,themeData.template.bgImage);
        await http
          .put(`/api/v1/company/${companyId}`, themeData)
          .then((res) => {
            dispatch({
              type: 'SET_COMPANY_DETAILS',
              companyDetails: res?.data?.data,
          });
          // console.log("res?.data?.data : ",res?.data?.data);
            storage.set('companyDetails',res?.data?.data)
            toast.success('Theme updated successfully');
            setUpdateTheme(false);
          })
          .catch((err) => {
            toast.error(`Error: ${err}`);
          });
      }
    };
    updateProfileTheme();
  }, [activeTheme, companyId, activeStyle, updateTheme]);



  const handleSubmitClick = (values) => {
    setUpdateTheme(true);
    let formData1 = new FormData();
    let formData2 = new FormData();
    formData1.append('bgImage', values?.bgImage?.[0]);
    formData2.append('businessLogo', values?.businessLogo?.[0]);
    if(values?.bgImage){
    http
      .post(`/api/v1/file-upload`, formData1)
      .then((res) => {
        if (res?.data?.data[0]?.location) {
          setActiveStyle({
            ...activeStyle,
            bgImage: res?.data?.data[0]?.location,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }  
    else if(values?.businessLogo){
    http
      .post(`/api/v1/file-upload`, formData2)
      .then((res) => {
        if (res?.data?.data?.[0]?.location) {
          setActiveStyle({
            ...activeStyle,
            businessLogo: res?.data?.data?.[0]?.location,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  const handleChangeComplete = (color, changedColor) => {
    setActiveStyle({
      ...activeStyle,
      [color]: changedColor.hex,
    });
  };

  const handlePreviewClick = (theme) => {
    const previewLink = `?previewTheme=${theme.title}`;
    window.open(
      `${process.env.REACT_APP_FRONTEND_BASE_URL}/restaurant` + previewLink,
      '_blank'
    );
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitClick)}>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={6}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <h1 className={classes.heading}>Theme</h1>
          <Typography className={classes.tagline}>
            You can change your theme for as per your branding
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ThemeCard
            heading='Templates'
            tagline='You can choose different template  for your system font'
          >
            <Grid
              container
              direction='row'
              justifyContent='left'
              alignItems='center'
              spacing={6}
            >
              {themeList?.slice(3, 4).map((theme, index) => (
                <ThemeBox
                  onPreviewClick={handlePreviewClick}
                  onThemeSetClick={handleSetThemeClick}
                  key={index}
                  theme={theme}
                  isSelected={theme?._id === activeTheme?._id}
                />
              ))}
            </Grid>
          </ThemeCard>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ThemeCard
            heading='Theme Color'
            tagline='You can color your Brand Color'
            className={classes.themPageCard}
          >
            <FormControl
              style={{
                width: '100%',
              }}
            >
              <RadioGroup
                aria-label='theme-text'
                defaultValue='default'
                name='text-radio-group'
                value={themeTextRadioSelected}
                onChange={handleChangeThemeTextRadioSelect}
              >
                <FormControlLabel
                  value='default'
                  name='textDefaultRadio'
                  control={<Radio control={control} />}
                  label='Default'
                />
                <FormControlLabel
                  value='custom'
                  name='textCustomRadio'
                  control={<Radio control={control} />}
                  label='Custom Color'
                />
              </RadioGroup>
            </FormControl>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              item
              style={{
                display: themeTextRadioSelected === 'custom' ? 'block' : 'none',
              }}
            >
              <FormControl className={classes.formControlColor}>
                <FormLabel className={classes.formControlLabel}>
                  Text Color
                </FormLabel>
                <ColorPicker
                  color={activeStyle?.textColor}
                  label='textColor'
                  onChangeComplete={handleChangeComplete}
                />
              </FormControl>
              <FormControl className={classes.formControlColor}>
                <FormLabel className={classes.formControlLabel}>
                  Text Highlight Color
                </FormLabel>
                <ColorPicker
                  color={activeStyle?.highlightColor}
                  label='highlightColor'
                  onChangeComplete={handleChangeComplete}
                />
              </FormControl>
            </Grid>
          </ThemeCard>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ThemeCard
            heading='Background Color'
            tagline='You can color your Brand Color'
            className={classes.themPageCard}
          >
            <FormControl
              style={{
                width: '100%',
              }}
            >
              <RadioGroup
                aria-label='theme-bg'
                defaultValue='default'
                name='bg-radio-group'
                value={themeBgRadioSelected}
                onChange={handleChangeThemeBgRadioSelect}
              >
                <FormControlLabel
                  value='default'
                  control={<Radio />}
                  label='Default'
                  className={classes.formControlLabel}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <FormControlLabel
                    value='solid'
                    control={<Radio />}
                    label='Solid Color'
                    className={classes.formControlLabel}
                  />
                  {themeBgRadioSelected === 'solid' && (
                    <ColorPicker
                      color={activeStyle?.bgColor}
                      label='bgColor'
                      onChangeComplete={handleChangeComplete}
                    />
                  )}
                </div>
                <FormControlLabel
                  value='image'
                  control={<Radio />}
                  label='Image'
                  className={classes.formControlLabel}
                />
              </RadioGroup>
            </FormControl>
            <Box
              style={{
                display: themeBgRadioSelected === 'image' ? 'block' : 'none',
              }}
            >
              <Grid
                container
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
                spacing={10}
              >
                <Grid item>
                  <ImageController
                    control={control}
                    form={form}
                    errors={errors}
                    name='bgImage'
                    filesLimit={1}
                    acceptedFiles={['image/*']}
                    // previewImage={activeStyle?.bgImage}
                    image={companyDetails?.template?.bgImage}
                  />
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction='column'
                    justifyContent='space-between'
                    alignItems='start'
                    spacing={2}
                  >
                    <Grid item>
                      <h1 className={classes.headingImage}>Background Image</h1>
                      <Typography className={classes.taglineImage}>
                        Upload Picture following this format PNG, JPG (max.
                        800x800px)
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </ThemeCard>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ThemeCard
            heading='Business Logo'
            tagline='Upload Logo following this format PNG, JPG 
          (max. 800x800px)'
            className={classes.themPageCard}
          >
            <Box>
              <Grid
                container
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
                spacing={10}
              >
                <Grid item>
                  <ImageController
                    control={control}
                    form={form}
                    errors={errors}
                    name='businessLogo'
                    filesLimit={1}
                    acceptedFiles={['image/*']}
                    image={companyDetails?.logo}
                  />
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction='column'
                    justifyContent='space-between'
                    alignItems='start'
                    spacing={2}
                  >
                    <Grid item>
                      <h1 className={classes.headingImage}>Add logo</h1>
                      <Typography className={classes.taglineImage}>
                        Upload Picture following this format PNG, JPG (max.
                        800x800px)
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </ThemeCard>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              marginTop: '24px',
              marginBottom: '24px',
            }}
          >
            <Grid container justifyContent={'flex-start'} spacing={2}>
              <Grid item>
                <Button
                  fullWidth={false}
                  color='primary'
                  variant='outlined'
                  style={{ height: '100%' }}
                  disabled={disabled}
                  className={classes.cancelBtn}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Btn
                  type='submit'
                  fullWidth={false}
                  name={SAVE_BTN}
                  className={classes.saveBtn}
                >
                  Save
                </Btn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ThemePage;
