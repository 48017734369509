import React from 'react';
import { Grid, Button } from '@mui/material';
import TickIcon from './tick.svg';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  themeBox: {
    overflow: 'visible',
    borderRadius: '10px',
    position: 'relative',
    '& figure': {
      width: '100%',
      paddingTop: '125%',
    },
    '& img': {
      borderRadius: '10px',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      objectFit: 'cover',
    },
    '&:hover': {
      '& figure + div': {
        opacity: 1,
        transform: 'translateY(0)',
      },
    },
  },
  isActiveTheme: {
    '& figure img': {
      border: '3px solid #133FE3',
      boxSizing: 'border-box',
      borderRadius: '12px',
    },
  },
  icon: {
    position: 'absolute!important',
    bottom: '100%!important',
    left: '100%!important',
    transform: 'translate(-70%, 90%)',
    width: '20px!important',
    height: '20px!important',
    zIndex: '1',
  },
  themeBoxOverlay: {
    transform: 'translateX(-100%)',
    opacity: 0,
    borderRadius: '10px',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.19)',
    display: 'flex',
    flexDirection: 'column!important',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    '& button': {
      width: '90px',
      height: '28px',
      background: '#FFFFFF',
      borderRadius: '15px',
      fontWeight: 400,
      fontSize: '9px',
      lineHeight: '22px',
      color: '#000000',
      margin: '6px 0px',
      textTransform: 'capitalize',
    },
    '& button:hover': {
      background: '#F5F5F5',
    },
  },
}));

const ThemeBox = ({
  theme,
  isSelected = false,
  onPreviewClick = () => {},
  onThemeSetClick = () => {},
}) => {
  const classes = useStyles();

  const handlePreviewClick = () => {
    onPreviewClick(theme);
  };
  const handleSetThemeClick = () => {
    onThemeSetClick(theme);
  };


  return (
    <Grid
      item
      xs={6}
      sm={3}
      key={theme?._id}
      className={
        classes.themeBox +
        ' ' +
        (isSelected ? classes.isActiveTheme : '')
      }
    >
      {isSelected && (
        <img className={classes.icon} src={TickIcon} alt={'tick'} />
      )}
      <Grid container position={'relative'} overflow='hidden'>
        <figure>
          <img src={theme?.previewImage} alt={theme.name} />
        </figure>
        <Grid className={classes.themeBoxOverlay}>
          <Button onClick={handlePreviewClick}>Preview</Button>
          <Button onClick={handleSetThemeClick}>Set Template</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ThemeBox;
