import React, { useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Grid } from '@mui/material';
import SideDishCardModal from './SideDishCardModal';
import AddSideDishModal from 'Pages/SideDish/Components/AddSideDishModal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: "735px",
    maxHeight: "85vh",
    overflow: "scroll",
    borderRadius: '10px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '30px',
};

const SideDishListModal = ({ open, handleClose, langPref, sideDishArray, preSelectedSideDish, setPreSelectedSideDish, sideDishListRefetch, setSideDishModalOpen }) => {

    const { t } = useTranslation();

    const [selectedSideDish, setSelectedSideDish] = useState(preSelectedSideDish?preSelectedSideDish:[])
    const [addSideDishModal, setAddSideDishModal] = useState(false);
    const handleSideDishCancel = () => {
        setPreSelectedSideDish(preSelectedSideDish)
        setSelectedSideDish(preSelectedSideDish)
        handleClose()
    }

    const handleSideDishAdd = () => {
        setPreSelectedSideDish(selectedSideDish)
        handleClose()
    }


    const handleCancel = (e) => {
        e.preventDefault()
        setAddSideDishModal(false)
        setSideDishModalOpen(true)
        handleClose();
    }

    const handleSideDish = () => {
        setAddSideDishModal(true);
        handleClose();
    }


    return (
        <>
        <Modal
            aria-labelledby='transition-modal-title'
            aria-describedby='transition-modal-description'
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Grid container alignItems='center'>
                        <Grid item xs={12}>
                            <h1 className="modal_heading">{t('admin.menu.addItem.selectSideDish')}</h1>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider style={{ margin: "10px 0" }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container style={{ overflow: "scroll", height: "calc(85vh - 170px)", marginBottom: "10px", marginTop:"10px" }}>
                                {sideDishArray?.map((sideDish,index) => {
                                    return (
                                        <Grid item xs={12} key={index}>
                                            <SideDishCardModal item={sideDish} selectedSideDish={selectedSideDish} setSelectedSideDish={setSelectedSideDish} langPref={langPref} showCheckbox={true} />
                                            <Divider style={{ margin: "20px 0" }} />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4} justifyContent="flex-end">
                                <Grid item>
                                    <Button className='btn_cancel' onClick={handleSideDishCancel}>Cancel</Button>
                                </Grid>
                                <Grid item>
                                    <Button className='btn_add' onClick={() =>{sideDishArray?.length ? handleSideDishAdd() : handleSideDish()}}>{sideDishArray?.length ? `Add` : t('admin.menu.addItem.addSideDish') }</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
         <AddSideDishModal
         open={addSideDishModal}
         handleClose={handleCancel}
         setAddSideDishModal={setAddSideDishModal}
         sideDishListRefetch={sideDishListRefetch}
         addItem={'addItem'}
       />
       </>
    )
}

export default SideDishListModal