import { Button, Grid, Typography, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';
import menuImage from 'Assets/menuimage.svg';
import AddMenuModal from './Components/AddMenuModal';
import { useState, useEffect } from 'react';
import AddLanguageModal from './Components/AddLanguageModal';
import MenuCard from './Components/MenuCard';
import { http } from 'Utils/Http/Http';
import { ITEMSLIST, MENULIST, USERPROFILE } from 'Constants/QueriesKeys';
import { useQuery } from 'react-query';
import { LocalStorage } from 'Utils/LocalStorage';
import { useTranslation } from 'react-i18next';
import { Btn } from 'Components';
import { useDispatch, useSelector } from 'react-redux';

const storage = new LocalStorage();

const useStyle = makeStyles((theme) => ({
  heading: {
    color: '#1A1A1A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '30px',
  },
  tagline: {
    color: '#767676',
  },
  addMenuButton: {
    color: '#fff',
    backgroundColor: '#133FE3 !important',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    padding: '8px 25px'
  },
  texts: {
    paddingBottom: '5%',
  },
  menuimage: {
    marginTop: '10%',
  },
}));

const MenuPage = () => {

  const storeData = useSelector((state) => state?.globalStore);

  const [addMenuModal, setAddMenuModal] = useState(false);
  const [addLanguageModal, setAddLanguageModal] = useState(false);
  const [promptModal, setPromptModal] = useState(false);
  const [langPref, setLangPref] = useState(storeData?.companyDetails?.languagePreference)
  const [menuList, setMenuList] = useState();
  const [menuListRefetch, setMenuListRefetch] = useState(true)
  const [selectedMenu, setSelectedMenu] = useState()
  const [profileRefetch, setProfileRefetch] = useState(true)

  const classes = useStyle();
  const { t } = useTranslation();
  const companyId = storage.get("user")?._id
  const dispatch = useDispatch();

 


  useEffect(() => {
    http.get(`/api/v1/company/${companyId}`, {}).then(({ data }) => {
      dispatch({
        type: 'SET_COMPANY_DETAILS',
        companyDetails: data?.data,
      });
      dispatch({
        type: 'SET_PRIMARY_LANG',
        primaryLanguage: data?.data?.languagePreference?.find((lang) => lang?.isPrimary)?.title?.toLowerCase(),
    });
      setProfileRefetch(false);
      setLangPref(data?.data?.languagePreference)
    })
  }, [addLanguageModal]);


  const handleAddMenu = (e) => {
    e.preventDefault();
    if (langPref?.length === 0) {
      setAddLanguageModal(true);
    } else {
      setSelectedMenu()
      setAddMenuModal(true);
    }
  };


  const menuListRender = useQuery([MENULIST], () =>

    http.get(`/api/v1/menu`, {
      options: {
        // sort: 'english.title',
        page: 1,
        limit: 10
      }
    }).then((data) => {
      setMenuListRefetch(false)
      setMenuList(data?.data?.data);
      console.log("dat ",data?.data);
      return (data)
    }),
    {
      enabled: menuListRefetch ? true : false,
    },
  )

  useEffect(() => {

    if(storeData?.companyDetails?.languagePreference?.length == 0){
      setAddLanguageModal(true)
    }
    else{
      setAddLanguageModal(false)
    }

  },[storeData])


  return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={10}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item>
              <h1 className={classes.heading}>Menu</h1>
              <Typography className={classes.tagline}>
                Manage & Add Item list on your marketplace.
              </Typography>
            </Grid>
            <Grid item>
              <Btn className={classes.addMenuButton} onClick={handleAddMenu}>
                <AddIcon fontSize='small' /> Add New Menu
              </Btn>
            </Grid>
          </Grid>
        </Grid>
        {menuListRender?.isLoading ?
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 style={{ fontSize: "18px" }}><CircularProgress style={{ height: "18px", width: "18px" }} /> {t('common.loading')}</h3>
          </Grid>
          :
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              container
              justifyContent='flex-start'
              alignItems='center'
              spacing={2}
            >
              {menuListRender?.isRefetching ?
                <Grid container>
                  <h3 style={{ fontSize: "18px" }}><CircularProgress style={{ height: "18px", width: "18px" }} /> {t('common.loading')}</h3>
                </Grid>
                :
                menuList?.length ? menuList?.map((item,index) => {
                  return (
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3} key={index}>
                        <MenuCard item={item} setSelectedMenu={setSelectedMenu} menuListRefetch={() => menuListRender.refetch()} langPref={langPref ? langPref : storeData?.companyDetails?.languagePreference} setAddMenuModal={setAddMenuModal} />
                      </Grid>
                  );
                })
                  :
                  (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid
                        container
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                      >
                        <Grid item>
                          <img className={classes.menuimage} alt="Menu" src={menuImage} />
                        </Grid>
                        <Grid item>
                          <div className={classes.texts}>
                            <h2 className={classes.heading}>No Menu Added</h2>
                            <p className={classes.tagline}>
                              Tap bellow to create new menu
                            </p>
                          </div>
                        </Grid>
                        <Grid item>
                          <Btn
                            className={classes.addMenuButton}
                            onClick={(e) => {langPref?.length ? handleAddMenu(e) : setAddLanguageModal(true)}}
                          >
                            <AddIcon fontSize='small' /> Add New Menu
                          </Btn>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
              }
            </Grid>
          </Grid>
        }
      </Grid>
      {addMenuModal && langPref.length !== 0 && <AddMenuModal
        open={true}
        handleClose={() => setAddMenuModal(false)}
        // handleAddMenu={handleAddMenu}
        menuListRefetch={() => menuListRender.refetch()}
        item={selectedMenu}
        langPref={langPref}
        setSelectedMenu={setSelectedMenu}
        promptModal={promptModal}
        setPromptModal={setPromptModal}
      />}
      {addLanguageModal && langPref?.length === 0 && <AddLanguageModal
        open={addLanguageModal}
        handleClose={() => {
          setAddLanguageModal(false)
          setAddMenuModal(true)
        }}
        menuListRefetch={() => menuListRender.refetch()}
      />}
    </>
  );
};
export default MenuPage;
