// export const FUNDS = 'FUNDS';
// export const ACCOUNTS = 'ACCOUNTS';
// export const ACCOUNTDETAIL = 'ACCOUNTDETAIL';
export const VIDEODETAIL='VIDEODETAIL'
export const VIDEOLIST='VIDEOLIST'
export const MAGAZINEDETAIL='MAGAZINEDETAIL'
export const MAGAZINELIST='MAGAZINELIST'
export const SHORTREADDETAIL='SHORTREADDETAIL'
export const SHORTREADLIST='SHORTREADLIST'
export const USERDETAIL='USERDETAIL'
export const USERLIST='USERLIST'

export const LANGLIST='LANGLIST'
export const USERPROFILE='USERPROFILE'

export const SIDEDISHLIST='SIDEDISHLIST'
export const SIDEDISHDETAILS='SIDEDISHDETAILS'

export const CATEGORYLIST='CATEGORYLIST'
export const SUBCATEGORYLIST='SUBCATEGORYLIST'
export const MENULIST='MENULIST'
export const MENUDETAILS='MENUDETAILS'

export const ITEMSLIST='ITEMSLIST'

