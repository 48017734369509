import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';

export default function BtnBase({ children, isLoading, variant = 'contained', ...otherProps }) {
    return (
        <LoadingButton
            size="large"
            fullWidth
            style={{ color: 'white' }}
            variant={variant ? variant : "outlined"}
            disableElevation
            disabled={isLoading}
            {...otherProps}
            loading={isLoading}
            loadingPosition="start"
        >
            {children}
        </LoadingButton>
    );
}

BtnBase.propTypes = {
    isLoading: PropTypes.bool,
};
