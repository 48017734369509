import { Grid, IconButton } from '@mui/material'
import React from 'react'
import { Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ReactSelect from 'react-select';
import { InputController } from 'Components';
import { makeStyles } from '@mui/styles';
import { LocalStorage } from 'Utils/LocalStorage';


const storage = new LocalStorage();

const useStyle = makeStyles((theme) => ({
    label: {
        marginBottom: "6px",
        fontWeight: "400",
        fontSize: '14px',
        lineHeight: '18px',
    },
    price:{
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none",
          },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
    }
}));

const ItemPrice = ({ control, form, errors, watch }) => {
    const { t } = useTranslation();
    const classes = useStyle();

    const { fields, append, remove } = useFieldArray({
        control,
        name: "type"
    });

    if (fields?.length < 1) {
        append({
            price: "",
            size: "",
            calories: "",
        })
    }
    const typeArray = [
        { title: "Small" },
        { title: "Medium" },
        { title: "Large" },
        { title: "Others" },
    ]
    // const watchFieldArray = watch("type");
    // const controlledFields = fields.map((field, index) => {
    //     return {
    //         ...field,
    //         ...watchFieldArray[index]
    //     };
    // });




    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container spacing={4}>
                    {fields.map((data, index) => {
                        return (
                            <Grid item xs={12} key={index}>
                                <Grid container spacing={3} key={data.id} alignItems='flex-end'>
                                    <Grid item xs={11}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <h6 className={classes.label}>{t('admin.menu.addItem.itemPrice')+"*"}</h6>
                                                <InputController
                                                    size="small"
                                                    control={control}
                                                    className={classes.price}
                                                    placeholder={t('admin.menu.addItem.enterItemPrice')}
                                                    name={`type[${index}].price`}
                                                    type="number"
                                                    InputProps={{
                                                        endAdornment: storage?.get('companyDetails')?.currency
                                                      }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <h6 className={classes.label}>{t('admin.menu.addItem.itemType')}</h6>
                                                <Controller
                                                    name={`type[${index}].size`}
                                                    isClearable
                                                    control={control}
                                                    className="reactSelectStyle"
                                                    render={({ field }) => (
                                                        <ReactSelect
                                                            {...field}
                                                            placeholder={t('admin.menu.addItem.selectType')}
                                                            className="reactSelectStyle"
                                                            options={typeArray}
                                                            getOptionValue={(options) => options}
                                                            getOptionLabel={(options) => options.title}
                                                        />
                                                    )}
                                                />
                                                {/* {controlledFields[index]?.size?.title === "Others" ?
                                                    <InputController
                                                        size="small"
                                                        control={control}
                                                        placeholder={t('admin.menu.addItem.selectType')}
                                                        name={`type[${index}].others`}
                                                    />
                                                    :
                                                    null} */}
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <h6 className={classes.label}>{t('admin.menu.addItem.addCalories')+"*"}</h6>
                                                <InputController
                                                    size="small"
                                                    control={control}
                                                    placeholder={t('admin.menu.addItem.enterCalories')}
                                                    name={`type[${index}].calories`}
                                                    type="number"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1}>
                                        {fields?.length > 1 ?
                                            <IconButton className="btn_delete" onClick={() => remove(index)}><DeleteOutlineIcon /></IconButton>
                                            : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-end">
                            <Grid item
                                onClick={(e) => {
                                    e.preventDefault();
                                    append({
                                        price: "",
                                        size: "",
                                        calories: "",
                                    });
                                }}
                                className="btn_addMore"
                                style={{ cursor: 'pointer' }}
                            >
                                + {t("admin.menu.addItem.addMore")}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ItemPrice