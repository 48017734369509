import {
  useState, useEffect
} from "react";
import { http } from "Utils/Http/Http";
import themeAPI from './themeAPI';

const useGetTemplates = () => {
  const [themeList, setThemeList] = useState([]);
  const [defaultTheme, setDefaultTheme] = useState(themeAPI?.themes?.find((theme) => theme?.title == 'theme1'));
  const [activeTheme, setActiveTheme] = useState(themeAPI?.themes?.find((theme) => theme?.title == 'theme1'));
  const [profileTheme, setProfileTheme] = useState(null);

  useEffect(() => {
    http.get(`/api/v1/template`).then(({ data }) => {
      setThemeList(data?.data);
    }).catch((err) => {
      console.log(err);
    });
    // setThemeList(themeAPI?.themes);
    setDefaultTheme(themeAPI?.themes?.find((theme) => theme?.title == 'theme1'));
  }, []);

  return {
    themeList,
    defaultTheme,
    activeTheme,
    setActiveTheme,
    profileTheme,
    setProfileTheme
  };
};

export default useGetTemplates;
