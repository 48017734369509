import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Grid, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'Utils/Yup';
import { Btn, InputController, setProgress } from 'Components';
import { countries_list } from 'Constants/CountryArray';
import ImageController from 'Components/FormControls/Controllers/ImageController';
import { LocalStorage } from 'Utils/LocalStorage';
import { useQuery } from 'react-query';
import { LANGLIST, USERPROFILE } from 'Constants/QueriesKeys';
import { http } from 'Utils/Http/Http';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

const SAVE_BTN = 'SAVE_BTN'
const storage = new LocalStorage();


const useStyle = makeStyles((theme) => ({
    title: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '30px',
        color: '#000022',
    },
    tabContainer: {
        padding: "50px"
    },
    tabs: {
        fontSize: '20px !important',
        lineHeight: '22px',
        textTransform: "capitalize",
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: "500",
        color: '#8B8B8B'
    },
    tabPanel: {
        padding: "30px 0",
    },
    label: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '10px'
    },
    title2: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '26px'
    },
    tagline: {
        color: '#767676',
        fontFamily: 'Inter',
        fontWeight: '400',
        fontStyle: 'normal',
        fontSize: '13px',
        lineHeight: '26px',
    },
    addBtn: {
        color: '#fff',
        backgroundColor: '#133FE3 !important',
        borderRadius: '8px',
        padding: '8px 34px'
    },
    cancelBtn: {
        color: '#133FE3 !important',
        borderRadius: '8px',
        background: '#fff',
        border: '1px solid #133FE3',
        padding: '6px 25px',
        '&:hover': {
            background: '#fff',
        }
    },
}));

const schema = yup.object().shape({

});
const Settings = () => {
    const classes = useStyle();
    const { t } = useTranslation();
    const [value, setValue] = useState('1');
    const [disabled, setDisabled] = useState(false)
    const companyId = storage.get("user")?._id
    const [langArray, setLangArray] = useState()
    const [langRefetch, setLangRefetch] = useState(true)
    const [profileRefetch, setProfileRefetch] = useState(true)
    const maxSecondaryLang = 2

    const dispatch = useDispatch();

    const storeData = useSelector((state) => state?.globalStore);

    const bussinessCurrency = storeData?.companyDetails?.currency


    const form = useForm({
        resolver: yupResolver(schema),
    });
    const { control, handleSubmit, setError, reset, errors, watch } = form;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const languageGetQuery = useQuery([LANGLIST], () =>
        http.get(`/api/v1/language`, {}).then(({ data }) => {
            setLangRefetch(false);
            setLangArray(data?.data)
            return data;
        }),
        {
            enabled: langRefetch ? true : false,
        },
    );

    const profileDetailsQuery = useQuery([USERPROFILE, { langArray }], () =>
        http.get(`/api/v1/company/${companyId}`, {}).then(({ data }) => {
           dispatch({
                        type: 'SET_COMPANY_DETAILS',
                        companyDetails: data?.data,
                    });
            if (langArray) {
                setProfileRefetch(false);
            }
            reset({
                primaryLanguage: langArray.filter((item) => item?.title === data?.data?.languagePreference[0]?.title)[0],
                secondaryLang: langArray.filter(o => data?.data?.languagePreference.some(({ title }) => o.title === title && title !== data.data?.languagePreference[0].title)),
                title: data?.data?.title,
                currency: countries_list.filter((currency) => currency?.Symbol == data?.data?.currency)[0]?.Symbol,
                country: data?.data?.country,
            });
            return data;
        }),
        {
            enabled: profileRefetch ? true : false,
        },
    );

    const assignedPrimary = useWatch({
        name: "primaryLanguage",
        control
    })
    const assignedSecondary = useWatch({
        name: "secondaryLang",
        control
    })
    var unassignedPrimary = langArray
    var unassignedSecondary = langArray?.filter((item) => item?._id !== assignedPrimary?._id)
    for (let i = 0; i < assignedSecondary?.length; i++) {
        unassignedPrimary = unassignedPrimary?.filter((item) => item?._id !== assignedSecondary[i]?._id)
    }

    const handleFormSubmit = async (data) => {
        try {
            await http
                .put(`/api/v1/company/${companyId}`, { ...data })
                .then(async (res) => {
                    toast.success('Settings Updated successfully');
                    storage.set("languagePreference", res?.data?.data?.languagePreference);
                    dispatch({
                        type: 'SET_COMPANY_DETAILS',
                        companyDetails: res?.data?.data,
                    });
                    storage.set('companyDetails', res?.data?.data)
                })
                .catch((res) => {
                    toast.error(res?.response?.data?.message);
                });
        } catch (res) {
            toast.error(res?.response?.data?.message);
        }
        setDisabled(false)
        setProgress(SAVE_BTN, false);
    }

    const handleFileUpload = async (values) => {
        setDisabled(true)
        setProgress(SAVE_BTN, true);

        let data = {
            title: values?.title,
            currency: countries_list.filter((currency) => currency?.Symbol == values?.currency)[0]?.Symbol,
            country: values?.country,
            languagePreference: [
                {
                    title: values?.primaryLanguage?.title,
                    direction: values?.primaryLanguage?.direction,
                    isPrimary: true
                }
            ]
        }
        for (let i = 0; i < values?.secondaryLang?.length; i++) {
            data.languagePreference[i + 1] = {
                title: values?.secondaryLang[i]?.title,
                direction: values?.secondaryLang[i]?.direction,
                isPrimary: false
            }
        }

        let fileFormData = new FormData();
        if (values?.businessLogo?.length) {
            for (var i = 0; i < values?.businessLogo?.length; i++) {
                fileFormData.append("attachment", values?.businessLogo[i]);
            }
            try {
                await http.post(`/api/v1/file-upload`, fileFormData)
                    .then(async (res) => {
                        data['logo'] = res?.data?.data[0]?.location
                        handleFormSubmit(data)
                    });
            } catch (res) {
                toast.error(res?.response?.data?.message)
                setDisabled(false)
                setProgress(SAVE_BTN, false);
            }
        }
        else {
            handleFormSubmit(data)
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <h3 className={classes.title}>{t('settings.settings')}</h3>
            </Grid>
            <Grid item xs={12}>
                <form className="space-y-4" onSubmit={handleSubmit(handleFileUpload)}>
                    <Box sx={{ width: '100%', typography: 'body1' }} className={classes.tabContainer}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label={t('settings.languages')} value="1" className={classes.tabs} />
                                    <Tab label={t('settings.businessInfo')} value="2" className={classes.tabs} />
                                </TabList>
                            </Box>
                            <TabPanel value="1" className={classes.tabPanel}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} >
                                        <h6 className={classes.label}>{t('settings.selectPrimaryLang')}</h6>
                                        <Controller
                                            name='primaryLanguage'
                                            isClearable
                                            control={control}
                                            disabled
                                            className={classes.primary}
                                            render={({ field }) => (
                                                <ReactSelect
                                                    {...field}
                                                    placeholder='Select Primary Language'
                                                    className={classes.primary}
                                                    options={unassignedPrimary}
                                                    getOptionValue={(options) => options}
                                                    getOptionLabel={(options) => options.title}
                                                    isDisabled
                                                />
                                            )}
                                        />
                                        {errors?.primaryLanguage && (
                                            <p
                                                style={{
                                                    color: '#f44336',
                                                    margin: '4px 14px 0 14px',
                                                    fontSize: '0.75rem',
                                                }}
                                            >
                                                {errors?.primaryLanguage?.message}
                                            </p>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h6 className={classes.label}>{t('settings.selectSecondaryLang')}</h6>
                                        <Controller
                                            name='secondaryLang'
                                            isClearable
                                            control={control}
                                            render={({ field }) => (
                                                <ReactSelect
                                                    {...field}
                                                    isMulti
                                                    placeholder='Select Other Languages'
                                                    options={unassignedSecondary}
                                                    getOptionValue={(options) => options}
                                                    getOptionLabel={(options) => options.title}
                                                    noOptionsMessage={() => {
                                                        return assignedSecondary?.length === maxSecondaryLang
                                                            ? 'You have choosen all available languages'
                                                            : 'No options available';
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors?.secondaryLang && (
                                            <p
                                                style={{
                                                    color: '#f44336',
                                                    margin: '4px 14px 0 14px',
                                                    fontSize: '0.75rem',
                                                }}
                                            >
                                                {errors?.secondaryLang?.message}
                                            </p>
                                        )}
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value="2" className={classes.tabPanel}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} >
                                        <InputController
                                            control={control}
                                            fullWidth
                                            select
                                            name="country"
                                            label={t('settings.selectCountry')}
                                            style={{ marginBottom: '9px' }}
                                        >
                                            {countries_list?.map((item,index) => {
                                                return (
                                                    <MenuItem value={item?.Name} key={index}>
                                                        {item?.Name} &nbsp;
                                                        {item?.Unicode}
                                                    </MenuItem>
                                                );
                                            })}
                                        </InputController>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputController
                                            control={control}
                                            fullWidth
                                            select
                                            name="currency"
                                            defaultValue={"$"}
                                            label={t('settings.selectcurrency')}
                                            style={{ marginBottom: '9px' }}
                                        >
                                            {countries_list?.map((item,index) => {
                                                return (
                                                    <MenuItem value={item?.Symbol} defaultValue={bussinessCurrency} key={index}>
                                                        {`${item?.Currency} (${item?.Symbol})`}
                                                    </MenuItem>
                                                );
                                            })}

                                            {/* {uniqueCurrencyArray.map((item) => {
                                                return (
                                                    <MenuItem key={`${item.Currency}_${item.Symbol}`} value={item.Symbol}>
                                                        {`${item.Currency} (${item.Symbol})`}
                                                    </MenuItem>
                                                );
                                            })} */}
                                        </InputController>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputController
                                            control={control}
                                            fullWidth
                                            errors={errors}
                                            name="title"
                                            label={t('formLabels.businessName')}
                                            style={{ marginBottom: '9px' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container
                                            justifyContent='flex-start'
                                            alignItems='center' spacing={3}>
                                            <Grid item>
                                                <ImageController
                                                    name="businessLogo"
                                                    filesLimit={1}
                                                    acceptedFiles
                                                    form={form}
                                                    errors={errors}
                                                    image={profileDetailsQuery?.data?.data?.logo ? profileDetailsQuery?.data?.data?.logo : storeData?.companyDetails?.logo}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Grid
                                                    container
                                                    direction='row'
                                                    justifyContent='center'
                                                    alignItems='center'
                                                    spacing={10}
                                                >
                                                    <Grid item >
                                                        <h1 className={classes.title2}>{t("formLabels.businessLogo")}</h1>
                                                        <Typography className={classes.tagline}>
                                                            Upload Picture following this format PNG, JPG (max.
                                                            800x800px)
                                                        </Typography>
                                                    </Grid>
                                                    {/* <Grid item >
                                                        <Button className={classes.chooseFileBtn} onClick={ImageController.thumbs}>Choose File</Button>
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </TabContext>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container justifyContent="flex-end" spacing={2}>
                                    <Grid item>
                                        <Btn className={classes.cancelBtn} fullWidth={false} color="primary" variant='outlined' style={{ height: "100%" }} disabled={disabled}>
                                            Cancel
                                        </Btn>
                                    </Grid>
                                    <Grid item>
                                        <Btn type="submit" className={classes.addBtn} fullWidth={false} name={SAVE_BTN}>
                                            Save
                                        </Btn>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </Grid>
        </Grid >
    )
}

export default Settings