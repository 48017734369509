import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BtnBase from '../Buttons/BtnBase';
import { makeStyles } from '@mui/styles';


const useStyle = makeStyles((theme) => ({
addBtn: {
    color: '#fff',
    backgroundColor: '#133FE3 !important',
    borderRadius: '8px',
    padding: '8px 34px'
  },
  cancelBtn: {
    color: '#133FE3 !important',
    borderRadius: '8px',
    background:'#fff',
    border: '1px solid #133FE3',
    padding: '6px 25px',
    '&:hover': {
      background:'#fff',
  }
  },
}));

function ConfirmationDialog({ isOpen, title, text, onReject, onResolve, yes, no }) {

    const classes = useStyle();
    const { t } = useTranslation();

    return (
        <Dialog open={isOpen} onClose={onReject} fullWidth maxWidth="sm">
            <DialogContent>
                <div className="space-y-10">
                    <div className="pt-3">
                        <Typography variant="h6">{title}</Typography>
                        <Typography variant="body1" className="mt-1">{text}</Typography>
                    </div>

                    <div className="flex justify-end pb-1.5 pr-1">
                        <BtnBase variant="text" onClick={onReject} className={classes.cancelBtn} fullWidth={false}>
                        {no ? no : t('common.cancel')}
                        </BtnBase> &nbsp;
                        <BtnBase variant="text" onClick={onResolve} className={classes.addBtn} fullWidth={false}>
                        {yes ? yes : t('common.continue')}
                        </BtnBase>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default ConfirmationDialog;
