import { Card, Grid, IconButton, Switch, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { http } from 'Utils/Http/Http';
import { toast } from 'react-toastify';
import { LocalStorage } from 'Utils/LocalStorage';
import defaultImage from '../../../Assets/default.png'
import { useSelector } from 'react-redux';


const useStyle = makeStyles((theme) => ({
    card: {
        padding: '10px',
        boxShadow: '0px 1px 16px rgba(189, 189, 189, 0.25)',
        border: '2px solid #EFEFF4',
        borderRadius: '10px',
    },
    mainDiv: {
        position: 'relative',
    },
    editIcon: {
        cursor: 'pointer',
    },
    menuImage: {
        width: '100px',
        height: '100px',
        borderRadius: '10px',
    },
    title: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '16px'
    }
}));

const storage = new LocalStorage();

const ItemCard = ({ itemData, langPref, setSelectedItem, setAddItemModal,refetchItemsList }) => {

    const storeData = useSelector((state) => state?.globalStore);

    const classes = useStyle();

    const bussinessCurrency = storeData?.companyDetails?.currency

    const handleEdit = () => {
        setSelectedItem(itemData)
        setAddItemModal(true)
    }
    const handleDisable = (event) => {
        try {
            http.put(`/api/v1/item/${itemData?._id}`, { isEnabled: event.target.checked })
                .then(async (res) => {
                    if (res?.data?.data?.isEnabled === true) {
                        toast.success("Item Enabled");
                        refetchItemsList()
                    } else {
                        toast.success("Item Disabled");
                        refetchItemsList()
                    }
                });
        } catch (res) {
            toast.error(res?.response?.data?.message)
        }
    }



    return (
        <>
            <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
            >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card className={classes.card}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
                            <Grid item>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={5}>
                                    <Grid item >
                                        <img src={itemData?.images[0]?.url ? itemData?.images[0]?.url : defaultImage} className={classes.menuImage} />
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent='space-between'
                                            alignItems="flex-start"
                                        >
                                            <Grid item>
                                                <Typography>{itemData[storeData?.primaryLanguage]?.title}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>{bussinessCurrency} {itemData?.type[0]?.price}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={handleEdit}>
                                    <EditOutlinedIcon />
                                </IconButton>
                                <Switch defaultChecked={itemData?.isEnabled} onChange={(event) => handleDisable(event)} />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};
export default ItemCard;
