// export const countries_list = [
//     {
//         Name: "Saudi Arabia",
//         Iso2: "SA",
//         Iso3: "SAU",
//         Unicode: "🇸🇦",
//         Dial: "966",
//         Currency: "SAR",
//         Capital: "Riyadh",
//         Continent: "AS",
//         Symbol: "﷼"
//     },
//     {
//         Name: "United States",
//         Iso2: "US",
//         Iso3: "USA",
//         Unicode: "🇺🇸",
//         Dial: "1",
//         Currency: "USD",
//         Capital: "Washington",
//         Continent: "NA",
//         Symbol: "$"
//     },
//         {
//             Name: "Afghanistan",
//             Iso2: "AF",
//             Iso3: "AFG",
//             Unicode: "🇦🇫",
//             Dial: "93",
//             Currency: "AFN",
//             Capital: "Kabul",
//             Continent: "AS",
//             Symbol: "؋"
//         },
//         // {
//         //     Name: "Aland Islands",
//         //     Iso2: "AX",
//         //     Iso3: "ALA",
//         //     Unicode: "🇦🇽",
//         //     Dial: "358-18",
//         //     Currency: "EUR",
//         //     Capital: "Mariehamn",
//         //     Continent: "EU",
//         //     Symbol: ""
//         // },
//         {
//             Name: "Albania",
//             Iso2: "AL",
//             Iso3: "ALB",
//             Unicode: "🇦🇱",
//             Dial: "355",
//             Currency: "ALL",
//             Capital: "Tirana",
//             Continent: "EU",
//             Symbol: "Lek"
//         },
//         {
//             Name: "Algeria",
//             Iso2: "DZ",
//             Iso3: "DZA",
//             Unicode: "🇩🇿",
//             Dial: "213",
//             Currency: "DZD",
//             Capital: "Algiers",
//             Continent: "AF",
//             Symbol: ""
//         },
//         // {
//         //     Name: "American Samoa",
//         //     Iso2: "AS",
//         //     Iso3: "ASM",
//         //     Unicode: "🇦🇸",
//         //     Dial: "1-684",
//         //     Currency: "USD",
//         //     Capital: "Pago Pago",
//         //     Continent: "OC",
//         //     Symbol: "$"
//         // },
//         // {
//         //     Name: "Andorra",
//         //     Iso2: "AD",
//         //     Iso3: "AND",
//         //     Unicode: "🇦🇩",
//         //     Dial: "376",
//         //     Currency: "EUR",
//         //     Capital: "Andorra la Vella",
//         //     Continent: "EU",
//         //     Symbol: "€"
//         // },
//         {
//             Name: "Angola",
//             Iso2: "AO",
//             Iso3: "AGO",
//             Unicode: "🇦🇴",
//             Dial: "244",
//             Currency: "AOA",
//             Capital: "Luanda",
//             Continent: "AF",
//             Symbol: "Kz"
//         },
//         {
//             Name: "Anguilla",
//             Iso2: "AI",
//             Iso3: "AIA",
//             Unicode: "🇦🇮",
//             Dial: "1-264",
//             Currency: "XCD",
//             Capital: "The Valley",
//             Continent: "NA",
//             Symbol: "$"
//         },
//         {
//             Name: "Antarctica",
//             Iso2: "AQ",
//             Iso3: "ATA",
//             Unicode: "🇦🇶",
//             Dial: "672",
//             Currency: "",
//             Capital: "",
//             Continent: "AN",
//             Symbol: ""
//         },
//         {
//             Name: "Antigua and Barbuda",
//             Iso2: "AG",
//             Iso3: "ATG",
//             Unicode: "🇦🇬",
//             Dial: "1-268",
//             Currency: "XCD",
//             Capital: "St. John's",
//             Continent: "NA",
//             Symbol: "$"
//         },
//         {
//             Name: "Argentina",
//             Iso2: "AR",
//             Iso3: "ARG",
//             Unicode: "🇦🇷",
//             Dial: "54",
//             Currency: "ARS",
//             Capital: "Buenos Aires",
//             Continent: "SA",
//             Symbol: "$"
//         },
//         {
//             Name: "Armenia",
//             Iso2: "AM",
//             Iso3: "ARM",
//             Unicode: "🇦🇲",
//             Dial: "374",
//             Currency: "AMD",
//             Capital: "Yerevan",
//             Continent: "AS",
//             Symbol: ""
//         },
//         {
//             Name: "Aruba",
//             Iso2: "AW",
//             Iso3: "ABW",
//             Unicode: "🇦🇼",
//             Dial: "297",
//             Currency: "AWG",
//             Capital: "Oranjestad",
//             Continent: "NA",
//             Symbol: "ƒ"
//         },
//         {
//             Name: "Australia",
//             Iso2: "AU",
//             Iso3: "AUS",
//             Unicode: "🇦🇺",
//             Dial: "61",
//             Currency: "AUD",
//             Capital: "Canberra",
//             Continent: "OC",
//             Symbol: "$"
//         },
//         // {
//         //     Name: "Austria",
//         //     Iso2: "AT",
//         //     Iso3: "AUT",
//         //     Unicode: "🇦🇹",
//         //     Dial: "43",
//         //     Currency: "EUR",
//         //     Capital: "Vienna",
//         //     Continent: "EU",
//         //     Symbol: "€"
//         // },
//         {
//             Name: "Azerbaijan",
//             Iso2: "AZ",
//             Iso3: "AZE",
//             Unicode: "🇦🇿",
//             Dial: "994",
//             Currency: "AZN",
//             Capital: "Baku",
//             Continent: "AS",
//             Symbol: "ман"
//         },
//         {
//             Name: "Bahamas",
//             Iso2: "BS",
//             Iso3: "BHS",
//             Unicode: "🇧🇸",
//             Dial: "1-242",
//             Currency: "BSD",
//             Capital: "Nassau",
//             Continent: "NA",
//             Symbol: "$"
//         },
//         {
//             Name: "Bahrain",
//             Iso2: "BH",
//             Iso3: "BHR",
//             Unicode: "🇧🇭",
//             Dial: "973",
//             Currency: "BHD",
//             Capital: "Manama",
//             Continent: "AS",
//             Symbol: ""
//         },
//         {
//             Name: "Bangladesh",
//             Iso2: "BD",
//             Iso3: "BGD",
//             Unicode: "🇧🇩",
//             Dial: "880",
//             Currency: "BDT",
//             Capital: "Dhaka",
//             Continent: "AS",
//             Symbol: ""
//         },
//         {
//             Name: "Barbados",
//             Iso2: "BB",
//             Iso3: "BRB",
//             Unicode: "🇧🇧",
//             Dial: "1-246",
//             Currency: "BBD",
//             Capital: "Bridgetown",
//             Continent: "NA",
//             Symbol: "$"
//         },
//         {
//             Name: "Belarus",
//             Iso2: "BY",
//             Iso3: "BLR",
//             Unicode: "🇧🇾",
//             Dial: "375",
//             Currency: "BYR",
//             Capital: "Minsk",
//             Continent: "EU",
//             Symbol: "p."
//         },
//         // {
//         //     Name: "Belgium",
//         //     Iso2: "BE",
//         //     Iso3: "BEL",
//         //     Unicode: "🇧🇪",
//         //     Dial: "32",
//         //     Currency: "EUR",
//         //     Capital: "Brussels",
//         //     Continent: "EU",
//         //     Symbol: "€"
//         // },
//         {
//             Name: "Belize",
//             Iso2: "BZ",
//             Iso3: "BLZ",
//             Unicode: "🇧🇿",
//             Dial: "501",
//             Currency: "BZD",
//             Capital: "Belmopan",
//             Continent: "NA",
//             Symbol: "BZ$"
//         },
//         {
//             Name: "Benin",
//             Iso2: "BJ",
//             Iso3: "BEN",
//             Unicode: "🇧🇯",
//             Dial: "229",
//             Currency: "XOF",
//             Capital: "Porto-Novo",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Bermuda",
//             Iso2: "BM",
//             Iso3: "BMU",
//             Unicode: "🇧🇲",
//             Dial: "1-441",
//             Currency: "BMD",
//             Capital: "Hamilton",
//             Continent: "NA",
//             Symbol: "$"
//         },
//         {
//             Name: "Bhutan",
//             Iso2: "BT",
//             Iso3: "BTN",
//             Unicode: "🇧🇹",
//             Dial: "975",
//             Currency: "BTN",
//             Capital: "Thimphu",
//             Continent: "AS",
//             Symbol: ""
//         },
//         {
//             Name: "Bolivia",
//             Iso2: "BO",
//             Iso3: "BOL",
//             Unicode: "🇧🇴",
//             Dial: "591",
//             Currency: "BOB",
//             Capital: "Sucre",
//             Continent: "SA",
//             Symbol: "$b"
//         },
//         // {
//         //     Name: "Bonaire, Saint Eustatius and Saba ",
//         //     Iso2: "BQ",
//         //     Iso3: "BES",
//         //     Unicode: "🇧🇶",
//         //     Dial: "599",
//         //     Currency: "USD",
//         //     Capital: "",
//         //     Continent: "NA",
//         //     Symbol: ""
//         // },
//         {
//             Name: "Bosnia and Herzegovina",
//             Iso2: "BA",
//             Iso3: "BIH",
//             Unicode: "🇧🇦",
//             Dial: "387",
//             Currency: "BAM",
//             Capital: "Sarajevo",
//             Continent: "EU",
//             Symbol: "KM"
//         },
//         {
//             Name: "Botswana",
//             Iso2: "BW",
//             Iso3: "BWA",
//             Unicode: "🇧🇼",
//             Dial: "267",
//             Currency: "BWP",
//             Capital: "Gaborone",
//             Continent: "AF",
//             Symbol: "P"
//         },
//         {
//             Name: "Brazil",
//             Iso2: "BR",
//             Iso3: "BRA",
//             Unicode: "🇧🇷",
//             Dial: "55",
//             Currency: "BRL",
//             Capital: "Brasilia",
//             Continent: "SA",
//             Symbol: "R$"
//         },
//         // {
//         //     Name: "British Indian Ocean Territory",
//         //     Iso2: "IO",
//         //     Iso3: "IOT",
//         //     Unicode: "🇮🇴",
//         //     Dial: "246",
//         //     Currency: "USD",
//         //     Capital: "Diego Garcia",
//         //     Continent: "AS",
//         //     Symbol: "$"
//         // },
//         // {
//         //     Name: "British Virgin Islands",
//         //     Iso2: "VG",
//         //     Iso3: "VGB",
//         //     Unicode: "🇻🇬",
//         //     Dial: "1-284",
//         //     Currency: "USD",
//         //     Capital: "Road Town",
//         //     Continent: "NA",
//         //     Symbol: "$"
//         // },
//         {
//             Name: "Brunei",
//             Iso2: "BN",
//             Iso3: "BRN",
//             Unicode: "🇧🇳",
//             Dial: "673",
//             Currency: "BND",
//             Capital: "Bandar Seri Begawan",
//             Continent: "AS",
//             Symbol: "$"
//         },
//         {
//             Name: "Bulgaria",
//             Iso2: "BG",
//             Iso3: "BGR",
//             Unicode: "🇧🇬",
//             Dial: "359",
//             Currency: "BGN",
//             Capital: "Sofia",
//             Continent: "EU",
//             Symbol: "лв"
//         },
//         {
//             Name: "Burkina Faso",
//             Iso2: "BF",
//             Iso3: "BFA",
//             Unicode: "🇧🇫",
//             Dial: "226",
//             Currency: "XOF",
//             Capital: "Ouagadougou",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Burundi",
//             Iso2: "BI",
//             Iso3: "BDI",
//             Unicode: "🇧🇮",
//             Dial: "257",
//             Currency: "BIF",
//             Capital: "Bujumbura",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Cambodia",
//             Iso2: "KH",
//             Iso3: "KHM",
//             Unicode: "🇰🇭",
//             Dial: "855",
//             Currency: "KHR",
//             Capital: "Phnom Penh",
//             Continent: "AS",
//             Symbol: "៛"
//         },
//         {
//             Name: "Cameroon",
//             Iso2: "CM",
//             Iso3: "CMR",
//             Unicode: "🇨🇲",
//             Dial: "237",
//             Currency: "XAF",
//             Capital: "Yaounde",
//             Continent: "AF",
//             Symbol: "FCF"
//         },
//         {
//             Name: "Canada",
//             Iso2: "CA",
//             Iso3: "CAN",
//             Unicode: "🇨🇦",
//             Dial: "1",
//             Currency: "CAD",
//             Capital: "Ottawa",
//             Continent: "NA",
//             Symbol: "$"
//         },
//         {
//             Name: "Cape Verde",
//             Iso2: "CV",
//             Iso3: "CPV",
//             Unicode: "🇨🇻",
//             Dial: "238",
//             Currency: "CVE",
//             Capital: "Praia",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Cayman Islands",
//             Iso2: "KY",
//             Iso3: "CYM",
//             Unicode: "🇰🇾",
//             Dial: "1-345",
//             Currency: "KYD",
//             Capital: "George Town",
//             Continent: "NA",
//             Symbol: "$"
//         },
//         {
//             Name: "Central African Republic",
//             Iso2: "CF",
//             Iso3: "CAF",
//             Unicode: "🇨🇫",
//             Dial: "236",
//             Currency: "XAF",
//             Capital: "Bangui",
//             Continent: "AF",
//             Symbol: "FCF"
//         },
//         {
//             Name: "Chad",
//             Iso2: "TD",
//             Iso3: "TCD",
//             Unicode: "🇹🇩",
//             Dial: "235",
//             Currency: "XAF",
//             Capital: "N'Djamena",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Chile",
//             Iso2: "CL",
//             Iso3: "CHL",
//             Unicode: "🇨🇱",
//             Dial: "56",
//             Currency: "CLP",
//             Capital: "Santiago",
//             Continent: "SA",
//             Symbol: ""
//         },
//         {
//             Name: "China",
//             Iso2: "CN",
//             Iso3: "CHN",
//             Unicode: "🇨🇳",
//             Dial: "86",
//             Currency: "CNY",
//             Capital: "Beijing",
//             Continent: "AS",
//             Symbol: "¥"
//         },
//         // {
//         //     Name: "Christmas Island",
//         //     Iso2: "CX",
//         //     Iso3: "CXR",
//         //     Unicode: "🇨🇽",
//         //     Dial: "61",
//         //     Currency: "AUD",
//         //     Capital: "Flying Fish Cove",
//         //     Continent: "AS",
//         //     Symbol: "$"
//         // },
//         // {
//         //     Name: "Cocos Islands",
//         //     Iso2: "CC",
//         //     Iso3: "CCK",
//         //     Unicode: "🇨🇨",
//         //     Dial: "61",
//         //     Currency: "AUD",
//         //     Capital: "West Island",
//         //     Continent: "AS",
//         //     Symbol: "$"
//         // },
//         {
//             Name: "Colombia",
//             Iso2: "CO",
//             Iso3: "COL",
//             Unicode: "🇨🇴",
//             Dial: "57",
//             Currency: "COP",
//             Capital: "Bogota",
//             Continent: "SA",
//             Symbol: "$"
//         },
//         {
//             Name: "Comoros",
//             Iso2: "KM",
//             Iso3: "COM",
//             Unicode: "🇰🇲",
//             Dial: "269",
//             Currency: "KMF",
//             Capital: "Moroni",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Cook Islands",
//             Iso2: "CK",
//             Iso3: "COK",
//             Unicode: "🇨🇰",
//             Dial: "682",
//             Currency: "NZD",
//             Capital: "Avarua",
//             Continent: "OC",
//             Symbol: "$"
//         },
//         {
//             Name: "Costa Rica",
//             Iso2: "CR",
//             Iso3: "CRI",
//             Unicode: "🇨🇷",
//             Dial: "506",
//             Currency: "CRC",
//             Capital: "San Jose",
//             Continent: "NA",
//             Symbol: "₡"
//         },
//         {
//             Name: "Croatia",
//             Iso2: "HR",
//             Iso3: "HRV",
//             Unicode: "🇭🇷",
//             Dial: "385",
//             Currency: "HRK",
//             Capital: "Zagreb",
//             Continent: "EU",
//             Symbol: "kn"
//         },
//         {
//             Name: "Cuba",
//             Iso2: "CU",
//             Iso3: "CUB",
//             Unicode: "🇨🇺",
//             Dial: "53",
//             Currency: "CUP",
//             Capital: "Havana",
//             Continent: "NA",
//             Symbol: "₱"
//         },
//         {
//             Name: "Curacao",
//             Iso2: "CW",
//             Iso3: "CUW",
//             Unicode: "🇨🇼",
//             Dial: "599",
//             Currency: "ANG",
//             Capital: "Willemstad",
//             Continent: "NA",
//             Symbol: ""
//         },
//         // {
//         //     Name: "Cyprus",
//         //     Iso2: "CY",
//         //     Iso3: "CYP",
//         //     Unicode: "🇨🇾",
//         //     Dial: "357",
//         //     Currency: "EUR",
//         //     Capital: "Nicosia",
//         //     Continent: "EU",
//         //     Symbol: ""
//         // },
//         {
//             Name: "Czech Republic",
//             Iso2: "CZ",
//             Iso3: "CZE",
//             Unicode: "🇨🇿",
//             Dial: "420",
//             Currency: "CZK",
//             Capital: "Prague",
//             Continent: "EU",
//             Symbol: "Kč"
//         },
//         {
//             Name: "Democratic Republic of the Congo",
//             Iso2: "CD",
//             Iso3: "COD",
//             Unicode: "🇨🇩",
//             Dial: "243",
//             Currency: "CDF",
//             Capital: "Kinshasa",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Denmark",
//             Iso2: "DK",
//             Iso3: "DNK",
//             Unicode: "🇩🇰",
//             Dial: "45",
//             Currency: "DKK",
//             Capital: "Copenhagen",
//             Continent: "EU",
//             Symbol: "kr"
//         },
//         {
//             Name: "Djibouti",
//             Iso2: "DJ",
//             Iso3: "DJI",
//             Unicode: "🇩🇯",
//             Dial: "253",
//             Currency: "DJF",
//             Capital: "Djibouti",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Dominica",
//             Iso2: "DM",
//             Iso3: "DMA",
//             Unicode: "🇩🇲",
//             Dial: "1-767",
//             Currency: "XCD",
//             Capital: "Roseau",
//             Continent: "NA",
//             Symbol: "$"
//         },
//         {
//             Name: "Dominican Republic",
//             Iso2: "DO",
//             Iso3: "DOM",
//             Unicode: "🇩🇴",
//             Dial: "1-809 and 1-829",
//             Currency: "DOP",
//             Capital: "Santo Domingo",
//             Continent: "NA",
//             Symbol: "RD$"
//         },
//         // {
//         //     Name: "East Timor",
//         //     Iso2: "TL",
//         //     Iso3: "TLS",
//         //     Unicode: "🇹🇱",
//         //     Dial: "670",
//         //     Currency: "USD",
//         //     Capital: "Dili",
//         //     Continent: "OC",
//         //     Symbol: "$"
//         // },
//         // {
//         //     Name: "Ecuador",
//         //     Iso2: "EC",
//         //     Iso3: "ECU",
//         //     Unicode: "🇪🇨",
//         //     Dial: "593",
//         //     Currency: "USD",
//         //     Capital: "Quito",
//         //     Continent: "SA",
//         //     Symbol: "$"
//         // },
//         {
//             Name: "Egypt",
//             Iso2: "EG",
//             Iso3: "EGY",
//             Unicode: "🇪🇬",
//             Dial: "20",
//             Currency: "EGP",
//             Capital: "Cairo",
//             Continent: "AF",
//             Symbol: "£"
//         },
//         // {
//         //     Name: "El Salvador",
//         //     Iso2: "SV",
//         //     Iso3: "SLV",
//         //     Unicode: "🇸🇻",
//         //     Dial: "503",
//         //     Currency: "USD",
//         //     Capital: "San Salvador",
//         //     Continent: "NA",
//         //     Symbol: "$"
//         // },
//         {
//             Name: "Equatorial Guinea",
//             Iso2: "GQ",
//             Iso3: "GNQ",
//             Unicode: "🇬🇶",
//             Dial: "240",
//             Currency: "XAF",
//             Capital: "Malabo",
//             Continent: "AF",
//             Symbol: "FCF"
//         },
//         {
//             Name: "Eritrea",
//             Iso2: "ER",
//             Iso3: "ERI",
//             Unicode: "🇪🇷",
//             Dial: "291",
//             Currency: "ERN",
//             Capital: "Asmara",
//             Continent: "AF",
//             Symbol: "Nfk"
//         },
//         // {
//         //     Name: "Estonia",
//         //     Iso2: "EE",
//         //     Iso3: "EST",
//         //     Unicode: "🇪🇪",
//         //     Dial: "372",
//         //     Currency: "EUR",
//         //     Capital: "Tallinn",
//         //     Continent: "EU",
//         //     Symbol: "kr"
//         // },
//         {
//             Name: "Ethiopia",
//             Iso2: "ET",
//             Iso3: "ETH",
//             Unicode: "🇪🇹",
//             Dial: "251",
//             Currency: "ETB",
//             Capital: "Addis Ababa",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Falkland Islands",
//             Iso2: "FK",
//             Iso3: "FLK",
//             Unicode: "🇫🇰",
//             Dial: "500",
//             Currency: "FKP",
//             Capital: "Stanley",
//             Continent: "SA",
//             Symbol: "£"
//         },
//         {
//             Name: "Faroe Islands",
//             Iso2: "FO",
//             Iso3: "FRO",
//             Unicode: "🇫🇴",
//             Dial: "298",
//             Currency: "DKK",
//             Capital: "Torshavn",
//             Continent: "EU",
//             Symbol: "kr"
//         },
//         {
//             Name: "Fiji",
//             Iso2: "FJ",
//             Iso3: "FJI",
//             Unicode: "🇫🇯",
//             Dial: "679",
//             Currency: "FJD",
//             Capital: "Suva",
//             Continent: "OC",
//             Symbol: "$"
//         },
//         // {
//         //     Name: "Finland",
//         //     Iso2: "FI",
//         //     Iso3: "FIN",
//         //     Unicode: "🇫🇮",
//         //     Dial: "358",
//         //     Currency: "EUR",
//         //     Capital: "Helsinki",
//         //     Continent: "EU",
//         //     Symbol: "€"
//         // },
//         // {
//         //     Name: "France",
//         //     Iso2: "FR",
//         //     Iso3: "FRA",
//         //     Unicode: "🇫🇷",
//         //     Dial: "33",
//         //     Currency: "EUR",
//         //     Capital: "Paris",
//         //     Continent: "EU",
//         //     Symbol: "€"
//         // },
//         // {
//         //     Name: "French Guiana",
//         //     Iso2: "GF",
//         //     Iso3: "GUF",
//         //     Unicode: "🇬🇫",
//         //     Dial: "594",
//         //     Currency: "EUR",
//         //     Capital: "Cayenne",
//         //     Continent: "SA",
//         //     Symbol: "€"
//         // },
//         {
//             Name: "French Polynesia",
//             Iso2: "PF",
//             Iso3: "PYF",
//             Unicode: "🇵🇫",
//             Dial: "689",
//             Currency: "XPF",
//             Capital: "Papeete",
//             Continent: "OC",
//             Symbol: ""
//         },
//         {
//             Name: "Gabon",
//             Iso2: "GA",
//             Iso3: "GAB",
//             Unicode: "🇬🇦",
//             Dial: "241",
//             Currency: "XAF",
//             Capital: "Libreville",
//             Continent: "AF",
//             Symbol: "FCF"
//         },
//         {
//             Name: "Gambia",
//             Iso2: "GM",
//             Iso3: "GMB",
//             Unicode: "🇬🇲",
//             Dial: "220",
//             Currency: "GMD",
//             Capital: "Banjul",
//             Continent: "AF",
//             Symbol: "D"
//         },
//         {
//             Name: "Georgia",
//             Iso2: "GE",
//             Iso3: "GEO",
//             Unicode: "🇬🇪",
//             Dial: "995",
//             Currency: "GEL",
//             Capital: "Tbilisi",
//             Continent: "AS",
//             Symbol: ""
//         },
//         {
//             Name: "Germany",
//             Iso2: "DE",
//             Iso3: "DEU",
//             Unicode: "🇩🇪",
//             Dial: "49",
//             Currency: "EUR",
//             Capital: "Berlin",
//             Continent: "EU",
//             Symbol: "€"
//         },
//         {
//             Name: "Ghana",
//             Iso2: "GH",
//             Iso3: "GHA",
//             Unicode: "🇬🇭",
//             Dial: "233",
//             Currency: "GHS",
//             Capital: "Accra",
//             Continent: "AF",
//             Symbol: "¢"
//         },
//         {
//             Name: "Gibraltar",
//             Iso2: "GI",
//             Iso3: "GIB",
//             Unicode: "🇬🇮",
//             Dial: "350",
//             Currency: "GIP",
//             Capital: "Gibraltar",
//             Continent: "EU",
//             Symbol: "£"
//         },
//         // {
//         //     Name: "Greece",
//         //     Iso2: "GR",
//         //     Iso3: "GRC",
//         //     Unicode: "🇬🇷",
//         //     Dial: "30",
//         //     Currency: "EUR",
//         //     Capital: "Athens",
//         //     Continent: "EU",
//         //     Symbol: "€"
//         // },
//         {
//             Name: "Greenland",
//             Iso2: "GL",
//             Iso3: "GRL",
//             Unicode: "🇬🇱",
//             Dial: "299",
//             Currency: "DKK",
//             Capital: "Nuuk",
//             Continent: "NA",
//             Symbol: "kr"
//         },
//         {
//             Name: "Grenada",
//             Iso2: "GD",
//             Iso3: "GRD",
//             Unicode: "🇬🇩",
//             Dial: "1-473",
//             Currency: "XCD",
//             Capital: "St. George's",
//             Continent: "NA",
//             Symbol: "$"
//         },
//         // {
//         //     Name: "Guadeloupe",
//         //     Iso2: "GP",
//         //     Iso3: "GLP",
//         //     Unicode: "🇬🇵",
//         //     Dial: "590",
//         //     Currency: "EUR",
//         //     Capital: "Basse-Terre",
//         //     Continent: "NA",
//         //     Symbol: "€"
//         // },
//         // {
//         //     Name: "Guam",
//         //     Iso2: "GU",
//         //     Iso3: "GUM",
//         //     Unicode: "🇬🇺",
//         //     Dial: "1-671",
//         //     Currency: "USD",
//         //     Capital: "Hagatna",
//         //     Continent: "OC",
//         //     Symbol: "$"
//         // },
//         {
//             Name: "Guatemala",
//             Iso2: "GT",
//             Iso3: "GTM",
//             Unicode: "🇬🇹",
//             Dial: "502",
//             Currency: "GTQ",
//             Capital: "Guatemala City",
//             Continent: "NA",
//             Symbol: "Q"
//         },
//         {
//             Name: "Guernsey",
//             Iso2: "GG",
//             Iso3: "GGY",
//             Unicode: "🇬🇬",
//             Dial: "44-1481",
//             Currency: "GBP",
//             Capital: "St Peter Port",
//             Continent: "EU",
//             Symbol: ""
//         },
//         {
//             Name: "Guinea",
//             Iso2: "GN",
//             Iso3: "GIN",
//             Unicode: "🇬🇳",
//             Dial: "224",
//             Currency: "GNF",
//             Capital: "Conakry",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Guinea-Bissau",
//             Iso2: "GW",
//             Iso3: "GNB",
//             Unicode: "🇬🇼",
//             Dial: "245",
//             Currency: "XOF",
//             Capital: "Bissau",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Guyana",
//             Iso2: "GY",
//             Iso3: "GUY",
//             Unicode: "🇬🇾",
//             Dial: "592",
//             Currency: "GYD",
//             Capital: "Georgetown",
//             Continent: "SA",
//             Symbol: "$"
//         },
//         {
//             Name: "Haiti",
//             Iso2: "HT",
//             Iso3: "HTI",
//             Unicode: "🇭🇹",
//             Dial: "509",
//             Currency: "HTG",
//             Capital: "Port-au-Prince",
//             Continent: "NA",
//             Symbol: "G"
//         },
//         {
//             Name: "Honduras",
//             Iso2: "HN",
//             Iso3: "HND",
//             Unicode: "🇭🇳",
//             Dial: "504",
//             Currency: "HNL",
//             Capital: "Tegucigalpa",
//             Continent: "NA",
//             Symbol: "L"
//         },
//         {
//             Name: "Hong Kong",
//             Iso2: "HK",
//             Iso3: "HKG",
//             Unicode: "🇭🇰",
//             Dial: "852",
//             Currency: "HKD",
//             Capital: "Hong Kong",
//             Continent: "AS",
//             Symbol: "$"
//         },
//         {
//             Name: "Hungary",
//             Iso2: "HU",
//             Iso3: "HUN",
//             Unicode: "🇭🇺",
//             Dial: "36",
//             Currency: "HUF",
//             Capital: "Budapest",
//             Continent: "EU",
//             Symbol: "Ft"
//         },
//         {
//             Name: "Iceland",
//             Iso2: "IS",
//             Iso3: "ISL",
//             Unicode: "🇮🇸",
//             Dial: "354",
//             Currency: "ISK",
//             Capital: "Reykjavik",
//             Continent: "EU",
//             Symbol: "kr"
//         },
//         {
//             Name: "India",
//             Iso2: "IN",
//             Iso3: "IND",
//             Unicode: "🇮🇳",
//             Dial: "91",
//             Currency: "INR",
//             Capital: "New Delhi",
//             Continent: "AS",
//             Symbol: "₹"
//         },
//         {
//             Name: "Indonesia",
//             Iso2: "ID",
//             Iso3: "IDN",
//             Unicode: "🇮🇩",
//             Dial: "62",
//             Currency: "IDR",
//             Capital: "Jakarta",
//             Continent: "AS",
//             Symbol: "Rp"
//         },
//         {
//             Name: "Iran",
//             Iso2: "IR",
//             Iso3: "IRN",
//             Unicode: "🇮🇷",
//             Dial: "98",
//             Currency: "IRR",
//             Capital: "Tehran",
//             Continent: "AS",
//             Symbol: "﷼"
//         },
//         {
//             Name: "Iraq",
//             Iso2: "IQ",
//             Iso3: "IRQ",
//             Unicode: "🇮🇶",
//             Dial: "964",
//             Currency: "IQD",
//             Capital: "Baghdad",
//             Continent: "AS",
//             Symbol: ""
//         },
//         // {
//         //     Name: "Ireland",
//         //     Iso2: "IE",
//         //     Iso3: "IRL",
//         //     Unicode: "🇮🇪",
//         //     Dial: "353",
//         //     Currency: "EUR",
//         //     Capital: "Dublin",
//         //     Continent: "EU",
//         //     Symbol: "€"
//         // },
//         {
//             Name: "Isle of Man",
//             Iso2: "IM",
//             Iso3: "IMN",
//             Unicode: "🇮🇲",
//             Dial: "44-1624",
//             Currency: "GBP",
//             Capital: "Douglas, Isle of Man",
//             Continent: "EU",
//             Symbol: ""
//         },
//         // {
//         //     Name: "Italy",
//         //     Iso2: "IT",
//         //     Iso3: "ITA",
//         //     Unicode: "🇮🇹",
//         //     Dial: "39",
//         //     Currency: "EUR",
//         //     Capital: "Rome",
//         //     Continent: "EU",
//         //     Symbol: "€"
//         // },
//         {
//             Name: "Ivory Coast",
//             Iso2: "CI",
//             Iso3: "CIV",
//             Unicode: "🇨🇮",
//             Dial: "225",
//             Currency: "XOF",
//             Capital: "Yamoussoukro",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Jamaica",
//             Iso2: "JM",
//             Iso3: "JAM",
//             Unicode: "🇯🇲",
//             Dial: "1-876",
//             Currency: "JMD",
//             Capital: "Kingston",
//             Continent: "NA",
//             Symbol: "$"
//         },
//         {
//             Name: "Japan",
//             Iso2: "JP",
//             Iso3: "JPN",
//             Unicode: "🇯🇵",
//             Dial: "81",
//             Currency: "JPY",
//             Capital: "Tokyo",
//             Continent: "AS",
//             Symbol: "¥"
//         },
//         {
//             Name: "Jersey",
//             Iso2: "JE",
//             Iso3: "JEY",
//             Unicode: "🇯🇪",
//             Dial: "44-1534",
//             Currency: "GBP",
//             Capital: "Saint Helier",
//             Continent: "EU",
//             Symbol: ""
//         },
//         {
//             Name: "Jordan",
//             Iso2: "JO",
//             Iso3: "JOR",
//             Unicode: "🇯🇴",
//             Dial: "962",
//             Currency: "JOD",
//             Capital: "Amman",
//             Continent: "AS",
//             Symbol: ""
//         },
//         {
//             Name: "Kazakhstan",
//             Iso2: "KZ",
//             Iso3: "KAZ",
//             Unicode: "🇰🇿",
//             Dial: "7",
//             Currency: "KZT",
//             Capital: "Astana",
//             Continent: "AS",
//             Symbol: "лв"
//         },
//         {
//             Name: "Kenya",
//             Iso2: "KE",
//             Iso3: "KEN",
//             Unicode: "🇰🇪",
//             Dial: "254",
//             Currency: "KES",
//             Capital: "Nairobi",
//             Continent: "AF",
//             Symbol: ""
//         },
//         // {
//         //     Name: "Kiribati",
//         //     Iso2: "KI",
//         //     Iso3: "KIR",
//         //     Unicode: "🇰🇮",
//         //     Dial: "686",
//         //     Currency: "AUD",
//         //     Capital: "Tarawa",
//         //     Continent: "OC",
//         //     Symbol: "$"
//         // },
//         {
//             Name: "Kosovo",
//             Iso2: "XK",
//             Iso3: "XKX",
//             Unicode: "🇽🇰",
//             Dial: "383",
//             Currency: "EUR",
//             Capital: "Pristina",
//             Continent: "EU",
//             Symbol: ""
//         },
//         {
//             Name: "Kuwait",
//             Iso2: "KW",
//             Iso3: "KWT",
//             Unicode: "🇰🇼",
//             Dial: "965",
//             Currency: "KWD",
//             Capital: "Kuwait City",
//             Continent: "AS",
//             Symbol: ""
//         },
//         {
//             Name: "Kyrgyzstan",
//             Iso2: "KG",
//             Iso3: "KGZ",
//             Unicode: "🇰🇬",
//             Dial: "996",
//             Currency: "KGS",
//             Capital: "Bishkek",
//             Continent: "AS",
//             Symbol: "лв"
//         },
//         {
//             Name: "Laos",
//             Iso2: "LA",
//             Iso3: "LAO",
//             Unicode: "🇱🇦",
//             Dial: "856",
//             Currency: "LAK",
//             Capital: "Vientiane",
//             Continent: "AS",
//             Symbol: "₭"
//         },
//         {
//             Name: "Latvia",
//             Iso2: "LV",
//             Iso3: "LVA",
//             Unicode: "🇱🇻",
//             Dial: "371",
//             Currency: "EUR",
//             Capital: "Riga",
//             Continent: "EU",
//             Symbol: "Ls"
//         },
//         {
//             Name: "Lebanon",
//             Iso2: "LB",
//             Iso3: "LBN",
//             Unicode: "🇱🇧",
//             Dial: "961",
//             Currency: "LBP",
//             Capital: "Beirut",
//             Continent: "AS",
//             Symbol: "£"
//         },
//         {
//             Name: "Lesotho",
//             Iso2: "LS",
//             Iso3: "LSO",
//             Unicode: "🇱🇸",
//             Dial: "266",
//             Currency: "LSL",
//             Capital: "Maseru",
//             Continent: "AF",
//             Symbol: "L"
//         },
//         {
//             Name: "Liberia",
//             Iso2: "LR",
//             Iso3: "LBR",
//             Unicode: "🇱🇷",
//             Dial: "231",
//             Currency: "LRD",
//             Capital: "Monrovia",
//             Continent: "AF",
//             Symbol: "$"
//         },
//         {
//             Name: "Libya",
//             Iso2: "LY",
//             Iso3: "LBY",
//             Unicode: "🇱🇾",
//             Dial: "218",
//             Currency: "LYD",
//             Capital: "Tripolis",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Liechtenstein",
//             Iso2: "LI",
//             Iso3: "LIE",
//             Unicode: "🇱🇮",
//             Dial: "423",
//             Currency: "CHF",
//             Capital: "Vaduz",
//             Continent: "EU",
//             Symbol: "CHF"
//         },
//         {
//             Name: "Lithuania",
//             Iso2: "LT",
//             Iso3: "LTU",
//             Unicode: "🇱🇹",
//             Dial: "370",
//             Currency: "LTL",
//             Capital: "Vilnius",
//             Continent: "EU",
//             Symbol: "Lt"
//         },
//         // {
//         //     Name: "Luxembourg",
//         //     Iso2: "LU",
//         //     Iso3: "LUX",
//         //     Unicode: "🇱🇺",
//         //     Dial: "352",
//         //     Currency: "EUR",
//         //     Capital: "Luxembourg",
//         //     Continent: "EU",
//         //     Symbol: "€"
//         // },
//         {
//             Name: "Macao",
//             Iso2: "MO",
//             Iso3: "MAC",
//             Unicode: "🇲🇴",
//             Dial: "853",
//             Currency: "MOP",
//             Capital: "Macao",
//             Continent: "AS",
//             Symbol: "MOP"
//         },
//         {
//             Name: "Macedonia",
//             Iso2: "MK",
//             Iso3: "MKD",
//             Unicode: "🇲🇰",
//             Dial: "389",
//             Currency: "MKD",
//             Capital: "Skopje",
//             Continent: "EU",
//             Symbol: "ден"
//         },
//         {
//             Name: "Madagascar",
//             Iso2: "MG",
//             Iso3: "MDG",
//             Unicode: "🇲🇬",
//             Dial: "261",
//             Currency: "MGA",
//             Capital: "Antananarivo",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Malawi",
//             Iso2: "MW",
//             Iso3: "MWI",
//             Unicode: "🇲🇼",
//             Dial: "265",
//             Currency: "MWK",
//             Capital: "Lilongwe",
//             Continent: "AF",
//             Symbol: "MK"
//         },
//         {
//             Name: "Malaysia",
//             Iso2: "MY",
//             Iso3: "MYS",
//             Unicode: "🇲🇾",
//             Dial: "60",
//             Currency: "MYR",
//             Capital: "Kuala Lumpur",
//             Continent: "AS",
//             Symbol: "RM"
//         },
//         {
//             Name: "Maldives",
//             Iso2: "MV",
//             Iso3: "MDV",
//             Unicode: "🇲🇻",
//             Dial: "960",
//             Currency: "MVR",
//             Capital: "Male",
//             Continent: "AS",
//             Symbol: "Rf"
//         },
//         {
//             Name: "Mali",
//             Iso2: "ML",
//             Iso3: "MLI",
//             Unicode: "🇲🇱",
//             Dial: "223",
//             Currency: "XOF",
//             Capital: "Bamako",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Malta",
//             Iso2: "MT",
//             Iso3: "MLT",
//             Unicode: "🇲🇹",
//             Dial: "356",
//             Currency: "EUR",
//             Capital: "Valletta",
//             Continent: "EU",
//             Symbol: ""
//         },
//         // {
//         //     Name: "Marshall Islands",
//         //     Iso2: "MH",
//         //     Iso3: "MHL",
//         //     Unicode: "🇲🇭",
//         //     Dial: "692",
//         //     Currency: "USD",
//         //     Capital: "Majuro",
//         //     Continent: "OC",
//         //     Symbol: "$"
//         // },
//         // {
//         //     Name: "Martinique",
//         //     Iso2: "MQ",
//         //     Iso3: "MTQ",
//         //     Unicode: "🇲🇶",
//         //     Dial: "596",
//         //     Currency: "EUR",
//         //     Capital: "Fort-de-France",
//         //     Continent: "NA",
//         //     Symbol: "€"
//         // },
//         {
//             Name: "Mauritania",
//             Iso2: "MR",
//             Iso3: "MRT",
//             Unicode: "🇲🇷",
//             Dial: "222",
//             Currency: "MRO",
//             Capital: "Nouakchott",
//             Continent: "AF",
//             Symbol: "UM"
//         },
//         {
//             Name: "Mauritius",
//             Iso2: "MU",
//             Iso3: "MUS",
//             Unicode: "🇲🇺",
//             Dial: "230",
//             Currency: "MUR",
//             Capital: "Port Louis",
//             Continent: "AF",
//             Symbol: "₨"
//         },
//         // {
//         //     Name: "Mayotte",
//         //     Iso2: "YT",
//         //     Iso3: "MYT",
//         //     Unicode: "🇾🇹",
//         //     Dial: "262",
//         //     Currency: "EUR",
//         //     Capital: "Mamoudzou",
//         //     Continent: "AF",
//         //     Symbol: "€"
//         // },
//         {
//             Name: "Mexico",
//             Iso2: "MX",
//             Iso3: "MEX",
//             Unicode: "🇲🇽",
//             Dial: "52",
//             Currency: "MXN",
//             Capital: "Mexico City",
//             Continent: "NA",
//             Symbol: "$"
//         },
//         // {
//         //     Name: "Micronesia",
//         //     Iso2: "FM",
//         //     Iso3: "FSM",
//         //     Unicode: "🇫🇲",
//         //     Dial: "691",
//         //     Currency: "USD",
//         //     Capital: "Palikir",
//         //     Continent: "OC",
//         //     Symbol: "$"
//         // },
//         {
//             Name: "Moldova",
//             Iso2: "MD",
//             Iso3: "MDA",
//             Unicode: "🇲🇩",
//             Dial: "373",
//             Currency: "MDL",
//             Capital: "Chisinau",
//             Continent: "EU",
//             Symbol: ""
//         },
//         // {
//         //     Name: "Monaco",
//         //     Iso2: "MC",
//         //     Iso3: "MCO",
//         //     Unicode: "🇲🇨",
//         //     Dial: "377",
//         //     Currency: "EUR",
//         //     Capital: "Monaco",
//         //     Continent: "EU",
//         //     Symbol: "€"
//         // },
//         {
//             Name: "Mongolia",
//             Iso2: "MN",
//             Iso3: "MNG",
//             Unicode: "🇲🇳",
//             Dial: "976",
//             Currency: "MNT",
//             Capital: "Ulan Bator",
//             Continent: "AS",
//             Symbol: "₮"
//         },
//         {
//             Name: "Montenegro",
//             Iso2: "ME",
//             Iso3: "MNE",
//             Unicode: "🇲🇪",
//             Dial: "382",
//             Currency: "EUR",
//             Capital: "Podgorica",
//             Continent: "EU",
//             Symbol: ""
//         },
//         {
//             Name: "Montserrat",
//             Iso2: "MS",
//             Iso3: "MSR",
//             Unicode: "🇲🇸",
//             Dial: "1-664",
//             Currency: "XCD",
//             Capital: "Plymouth",
//             Continent: "NA",
//             Symbol: "$"
//         },
//         {
//             Name: "Morocco",
//             Iso2: "MA",
//             Iso3: "MAR",
//             Unicode: "🇲🇦",
//             Dial: "212",
//             Currency: "MAD",
//             Capital: "Rabat",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Mozambique",
//             Iso2: "MZ",
//             Iso3: "MOZ",
//             Unicode: "🇲🇿",
//             Dial: "258",
//             Currency: "MZN",
//             Capital: "Maputo",
//             Continent: "AF",
//             Symbol: "MT"
//         },
//         {
//             Name: "Myanmar",
//             Iso2: "MM",
//             Iso3: "MMR",
//             Unicode: "🇲🇲",
//             Dial: "95",
//             Currency: "MMK",
//             Capital: "Nay Pyi Taw",
//             Continent: "AS",
//             Symbol: "K"
//         },
//         {
//             Name: "Namibia",
//             Iso2: "NA",
//             Iso3: "NAM",
//             Unicode: "🇳🇦",
//             Dial: "264",
//             Currency: "NAD",
//             Capital: "Windhoek",
//             Continent: "AF",
//             Symbol: "$"
//         },
//         // {
//         //     Name: "Nauru",
//         //     Iso2: "NR",
//         //     Iso3: "NRU",
//         //     Unicode: "🇳🇷",
//         //     Dial: "674",
//         //     Currency: "AUD",
//         //     Capital: "Yaren",
//         //     Continent: "OC",
//         //     Symbol: "$"
//         // },
//         {
//             Name: "Nepal",
//             Iso2: "NP",
//             Iso3: "NPL",
//             Unicode: "🇳🇵",
//             Dial: "977",
//             Currency: "NPR",
//             Capital: "Kathmandu",
//             Continent: "AS",
//             Symbol: "₨"
//         },
//         // {
//         //     Name: "Netherlands",
//         //     Iso2: "NL",
//         //     Iso3: "NLD",
//         //     Unicode: "🇳🇱",
//         //     Dial: "31",
//         //     Currency: "EUR",
//         //     Capital: "Amsterdam",
//         //     Continent: "EU",
//         //     Symbol: "€"
//         // },
//         // {
//         //     Name: "New Caledonia",
//         //     Iso2: "NC",
//         //     Iso3: "NCL",
//         //     Unicode: "🇳🇨",
//         //     Dial: "687",
//         //     Currency: "XPF",
//         //     Capital: "Noumea",
//         //     Continent: "OC",
//         //     Symbol: ""
//         // },
//         {
//             Name: "New Zealand",
//             Iso2: "NZ",
//             Iso3: "NZL",
//             Unicode: "🇳🇿",
//             Dial: "64",
//             Currency: "NZD",
//             Capital: "Wellington",
//             Continent: "OC",
//             Symbol: "$"
//         },
//         {
//             Name: "Nicaragua",
//             Iso2: "NI",
//             Iso3: "NIC",
//             Unicode: "🇳🇮",
//             Dial: "505",
//             Currency: "NIO",
//             Capital: "Managua",
//             Continent: "NA",
//             Symbol: "C$"
//         },
//         {
//             Name: "Niger",
//             Iso2: "NE",
//             Iso3: "NER",
//             Unicode: "🇳🇪",
//             Dial: "227",
//             Currency: "XOF",
//             Capital: "Niamey",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Nigeria",
//             Iso2: "NG",
//             Iso3: "NGA",
//             Unicode: "🇳🇬",
//             Dial: "234",
//             Currency: "NGN",
//             Capital: "Abuja",
//             Continent: "AF",
//             Symbol: "₦"
//         },
//         {
//             Name: "Niue",
//             Iso2: "NU",
//             Iso3: "NIU",
//             Unicode: "🇳🇺",
//             Dial: "683",
//             Currency: "NZD",
//             Capital: "Alofi",
//             Continent: "OC",
//             Symbol: "$"
//         },
//         // {
//         //     Name: "Norfolk Island",
//         //     Iso2: "NF",
//         //     Iso3: "NFK",
//         //     Unicode: "🇳🇫",
//         //     Dial: "672",
//         //     Currency: "AUD",
//         //     Capital: "Kingston",
//         //     Continent: "OC",
//         //     Symbol: "$"
//         // },
//         {
//             Name: "North Korea",
//             Iso2: "KP",
//             Iso3: "PRK",
//             Unicode: "🇰🇵",
//             Dial: "850",
//             Currency: "KPW",
//             Capital: "Pyongyang",
//             Continent: "AS",
//             Symbol: "₩"
//         },
//         // {
//         //     Name: "Northern Mariana Islands",
//         //     Iso2: "MP",
//         //     Iso3: "MNP",
//         //     Unicode: "🇲🇵",
//         //     Dial: "1-670",
//         //     Currency: "USD",
//         //     Capital: "Saipan",
//         //     Continent: "OC",
//         //     Symbol: "$"
//         // },
//         {
//             Name: "Norway",
//             Iso2: "NO",
//             Iso3: "NOR",
//             Unicode: "🇳🇴",
//             Dial: "47",
//             Currency: "NOK",
//             Capital: "Oslo",
//             Continent: "EU",
//             Symbol: "kr"
//         },
//         {
//             Name: "Oman",
//             Iso2: "OM",
//             Iso3: "OMN",
//             Unicode: "🇴🇲",
//             Dial: "968",
//             Currency: "OMR",
//             Capital: "Muscat",
//             Continent: "AS",
//             Symbol: "﷼"
//         },
//         {
//             Name: "Pakistan",
//             Iso2: "PK",
//             Iso3: "PAK",
//             Unicode: "🇵🇰",
//             Dial: "92",
//             Currency: "PKR",
//             Capital: "Islamabad",
//             Continent: "AS",
//             Symbol: "₨"
//         },
//         // {
//         //     Name: "Palau",
//         //     Iso2: "PW",
//         //     Iso3: "PLW",
//         //     Unicode: "🇵🇼",
//         //     Dial: "680",
//         //     Currency: "USD",
//         //     Capital: "Melekeok",
//         //     Continent: "OC",
//         //     Symbol: "$"
//         // },
//         {
//             Name: "Palestine",
//             Iso2: "PS",
//             Iso3: "PSE",
//             Unicode: "🇵🇸",
//             Dial: "970",
//             Currency: "ILS",
//             Capital: "East Jerusalem",
//             Continent: "AS",
//             Symbol: ""
//         },
//         {
//             Name: "Panama",
//             Iso2: "PA",
//             Iso3: "PAN",
//             Unicode: "🇵🇦",
//             Dial: "507",
//             Currency: "PAB",
//             Capital: "Panama City",
//             Continent: "NA",
//             Symbol: "B/."
//         },
//         {
//             Name: "Papua New Guinea",
//             Iso2: "PG",
//             Iso3: "PNG",
//             Unicode: "🇵🇬",
//             Dial: "675",
//             Currency: "PGK",
//             Capital: "Port Moresby",
//             Continent: "OC",
//             Symbol: ""
//         },
//         {
//             Name: "Paraguay",
//             Iso2: "PY",
//             Iso3: "PRY",
//             Unicode: "🇵🇾",
//             Dial: "595",
//             Currency: "PYG",
//             Capital: "Asuncion",
//             Continent: "SA",
//             Symbol: "Gs"
//         },
//         {
//             Name: "Peru",
//             Iso2: "PE",
//             Iso3: "PER",
//             Unicode: "🇵🇪",
//             Dial: "51",
//             Currency: "PEN",
//             Capital: "Lima",
//             Continent: "SA",
//             Symbol: "S/."
//         },
//         {
//             Name: "Philippines",
//             Iso2: "PH",
//             Iso3: "PHL",
//             Unicode: "🇵🇭",
//             Dial: "63",
//             Currency: "PHP",
//             Capital: "Manila",
//             Continent: "AS",
//             Symbol: "Php"
//         },
//         {
//             Name: "Pitcairn",
//             Iso2: "PN",
//             Iso3: "PCN",
//             Unicode: "🇵🇳",
//             Dial: "870",
//             Currency: "NZD",
//             Capital: "Adamstown",
//             Continent: "OC",
//             Symbol: "$"
//         },
//         {
//             Name: "Poland",
//             Iso2: "PL",
//             Iso3: "POL",
//             Unicode: "🇵🇱",
//             Dial: "48",
//             Currency: "PLN",
//             Capital: "Warsaw",
//             Continent: "EU",
//             Symbol: "zł"
//         },
//         // {
//         //     Name: "Portugal",
//         //     Iso2: "PT",
//         //     Iso3: "PRT",
//         //     Unicode: "🇵🇹",
//         //     Dial: "351",
//         //     Currency: "EUR",
//         //     Capital: "Lisbon",
//         //     Continent: "EU",
//         //     Symbol: "€"
//         // },
//         // {
//         //     Name: "Puerto Rico",
//         //     Iso2: "PR",
//         //     Iso3: "PRI",
//         //     Unicode: "🇵🇷",
//         //     Dial: "1-787 and 1-939",
//         //     Currency: "USD",
//         //     Capital: "San Juan",
//         //     Continent: "NA",
//         //     Symbol: "$"
//         // },
//         {
//             Name: "Qatar",
//             Iso2: "QA",
//             Iso3: "QAT",
//             Unicode: "🇶🇦",
//             Dial: "974",
//             Currency: "QAR",
//             Capital: "Doha",
//             Continent: "AS",
//             Symbol: "﷼"
//         },
//         {
//             Name: "Republic of the Congo",
//             Iso2: "CG",
//             Iso3: "COG",
//             Unicode: "🇨🇬",
//             Dial: "242",
//             Currency: "XAF",
//             Capital: "Brazzaville",
//             Continent: "AF",
//             Symbol: "FCF"
//         },
//         // {
//         //     Name: "Reunion",
//         //     Iso2: "RE",
//         //     Iso3: "REU",
//         //     Unicode: "🇷🇪",
//         //     Dial: "262",
//         //     Currency: "EUR",
//         //     Capital: "Saint-Denis",
//         //     Continent: "AF",
//         //     Symbol: "€"
//         // },
//         {
//             Name: "Romania",
//             Iso2: "RO",
//             Iso3: "ROU",
//             Unicode: "🇷🇴",
//             Dial: "40",
//             Currency: "RON",
//             Capital: "Bucharest",
//             Continent: "EU",
//             Symbol: "lei"
//         },
//         {
//             Name: "Russia",
//             Iso2: "RU",
//             Iso3: "RUS",
//             Unicode: "🇷🇺",
//             Dial: "7",
//             Currency: "RUB",
//             Capital: "Moscow",
//             Continent: "EU",
//             Symbol: "py6"
//         },
//         {
//             Name: "Rwanda",
//             Iso2: "RW",
//             Iso3: "RWA",
//             Unicode: "🇷🇼",
//             Dial: "250",
//             Currency: "RWF",
//             Capital: "Kigali",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Saint Barthelemy",
//             Iso2: "BL",
//             Iso3: "BLM",
//             Unicode: "🇧🇱",
//             Dial: "590",
//             Currency: "EUR",
//             Capital: "Gustavia",
//             Continent: "NA",
//             Symbol: ""
//         },
//         {
//             Name: "Saint Helena",
//             Iso2: "SH",
//             Iso3: "SHN",
//             Unicode: "🇸🇭",
//             Dial: "290",
//             Currency: "SHP",
//             Capital: "Jamestown",
//             Continent: "AF",
//             Symbol: "£"
//         },
//         {
//             Name: "Saint Kitts and Nevis",
//             Iso2: "KN",
//             Iso3: "KNA",
//             Unicode: "🇰🇳",
//             Dial: "1-869",
//             Currency: "XCD",
//             Capital: "Basseterre",
//             Continent: "NA",
//             Symbol: "$"
//         },
//         {
//             Name: "Saint Lucia",
//             Iso2: "LC",
//             Iso3: "LCA",
//             Unicode: "🇱🇨",
//             Dial: "1-758",
//             Currency: "XCD",
//             Capital: "Castries",
//             Continent: "NA",
//             Symbol: "$"
//         },
//         {
//             Name: "Saint Martin",
//             Iso2: "MF",
//             Iso3: "MAF",
//             Unicode: "🇲🇫",
//             Dial: "590",
//             Currency: "EUR",
//             Capital: "Marigot",
//             Continent: "NA",
//             Symbol: ""
//         },
//         // {
//         //     Name: "Saint Pierre and Miquelon",
//         //     Iso2: "PM",
//         //     Iso3: "SPM",
//         //     Unicode: "🇵🇲",
//         //     Dial: "508",
//         //     Currency: "EUR",
//         //     Capital: "Saint-Pierre",
//         //     Continent: "NA",
//         //     Symbol: "€"
//         // },
//         {
//             Name: "Saint Vincent and the Grenadines",
//             Iso2: "VC",
//             Iso3: "VCT",
//             Unicode: "🇻🇨",
//             Dial: "1-784",
//             Currency: "XCD",
//             Capital: "Kingstown",
//             Continent: "NA",
//             Symbol: "$"
//         },
//         {
//             Name: "Samoa",
//             Iso2: "WS",
//             Iso3: "WSM",
//             Unicode: "🇼🇸",
//             Dial: "685",
//             Currency: "WST",
//             Capital: "Apia",
//             Continent: "OC",
//             Symbol: "WS$"
//         },
//         // {
//         //     Name: "San Marino",
//         //     Iso2: "SM",
//         //     Iso3: "SMR",
//         //     Unicode: "🇸🇲",
//         //     Dial: "378",
//         //     Currency: "EUR",
//         //     Capital: "San Marino",
//         //     Continent: "EU",
//         //     Symbol: "€"
//         // },
//         {
//             Name: "Sao Tome and Principe",
//             Iso2: "ST",
//             Iso3: "STP",
//             Unicode: "🇸🇹",
//             Dial: "239",
//             Currency: "STD",
//             Capital: "Sao Tome",
//             Continent: "AF",
//             Symbol: "Db"
//         },
//         {
//             Name: "Senegal",
//             Iso2: "SN",
//             Iso3: "SEN",
//             Unicode: "🇸🇳",
//             Dial: "221",
//             Currency: "XOF",
//             Capital: "Dakar",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Serbia",
//             Iso2: "RS",
//             Iso3: "SRB",
//             Unicode: "🇷🇸",
//             Dial: "381",
//             Currency: "RSD",
//             Capital: "Belgrade",
//             Continent: "EU",
//             Symbol: ""
//         },
//         {
//             Name: "Seychelles",
//             Iso2: "SC",
//             Iso3: "SYC",
//             Unicode: "🇸🇨",
//             Dial: "248",
//             Currency: "SCR",
//             Capital: "Victoria",
//             Continent: "AF",
//             Symbol: "₨"
//         },
//         {
//             Name: "Sierra Leone",
//             Iso2: "SL",
//             Iso3: "SLE",
//             Unicode: "🇸🇱",
//             Dial: "232",
//             Currency: "SLL",
//             Capital: "Freetown",
//             Continent: "AF",
//             Symbol: "Le"
//         },
//         {
//             Name: "Singapore",
//             Iso2: "SG",
//             Iso3: "SGP",
//             Unicode: "🇸🇬",
//             Dial: "65",
//             Currency: "SGD",
//             Capital: "Singapur",
//             Continent: "AS",
//             Symbol: "$"
//         },
//         {
//             Name: "Sint Maarten",
//             Iso2: "SX",
//             Iso3: "SXM",
//             Unicode: "🇸🇽",
//             Dial: "599",
//             Currency: "ANG",
//             Capital: "Philipsburg",
//             Continent: "NA",
//             Symbol: ""
//         },
//         {
//             Name: "Slovakia",
//             Iso2: "SK",
//             Iso3: "SVK",
//             Unicode: "🇸🇰",
//             Dial: "421",
//             Currency: "EUR",
//             Capital: "Bratislava",
//             Continent: "EU",
//             Symbol: "Sk"
//         },
//         // {
//         //     Name: "Slovenia",
//         //     Iso2: "SI",
//         //     Iso3: "SVN",
//         //     Unicode: "🇸🇮",
//         //     Dial: "386",
//         //     Currency: "EUR",
//         //     Capital: "Ljubljana",
//         //     Continent: "EU",
//         //     Symbol: "€"
//         // },
//         {
//             Name: "Solomon Islands",
//             Iso2: "SB",
//             Iso3: "SLB",
//             Unicode: "🇸🇧",
//             Dial: "677",
//             Currency: "SBD",
//             Capital: "Honiara",
//             Continent: "OC",
//             Symbol: "$"
//         },
//         {
//             Name: "Somalia",
//             Iso2: "SO",
//             Iso3: "SOM",
//             Unicode: "🇸🇴",
//             Dial: "252",
//             Currency: "SOS",
//             Capital: "Mogadishu",
//             Continent: "AF",
//             Symbol: "S"
//         },
//         {
//             Name: "South Africa",
//             Iso2: "ZA",
//             Iso3: "ZAF",
//             Unicode: "🇿🇦",
//             Dial: "27",
//             Currency: "ZAR",
//             Capital: "Pretoria",
//             Continent: "AF",
//             Symbol: "R"
//         },
//         {
//             Name: "South Korea",
//             Iso2: "KR",
//             Iso3: "KOR",
//             Unicode: "🇰🇷",
//             Dial: "82",
//             Currency: "KRW",
//             Capital: "Seoul",
//             Continent: "AS",
//             Symbol: "₩"
//         },
//         {
//             Name: "South Sudan",
//             Iso2: "SS",
//             Iso3: "SSD",
//             Unicode: "🇸🇸",
//             Dial: "211",
//             Currency: "SSP",
//             Capital: "Juba",
//             Continent: "AF",
//             Symbol: ""
//         },
//         // {
//         //     Name: "Spain",
//         //     Iso2: "ES",
//         //     Iso3: "ESP",
//         //     Unicode: "🇪🇸",
//         //     Dial: "34",
//         //     Currency: "EUR",
//         //     Capital: "Madrid",
//         //     Continent: "EU",
//         //     Symbol: "€"
//         // },
//         {
//             Name: "Sri Lanka",
//             Iso2: "LK",
//             Iso3: "LKA",
//             Unicode: "🇱🇰",
//             Dial: "94",
//             Currency: "LKR",
//             Capital: "Colombo",
//             Continent: "AS",
//             Symbol: "₨"
//         },
//         {
//             Name: "Sudan",
//             Iso2: "SD",
//             Iso3: "SDN",
//             Unicode: "🇸🇩",
//             Dial: "249",
//             Currency: "SDG",
//             Capital: "Khartoum",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Suriname",
//             Iso2: "SR",
//             Iso3: "SUR",
//             Unicode: "🇸🇷",
//             Dial: "597",
//             Currency: "SRD",
//             Capital: "Paramaribo",
//             Continent: "SA",
//             Symbol: "$"
//         },
//         {
//             Name: "Svalbard and Jan Mayen",
//             Iso2: "SJ",
//             Iso3: "SJM",
//             Unicode: "🇸🇯",
//             Dial: "47",
//             Currency: "NOK",
//             Capital: "Longyearbyen",
//             Continent: "EU",
//             Symbol: "kr"
//         },
//         {
//             Name: "Swaziland",
//             Iso2: "SZ",
//             Iso3: "SWZ",
//             Unicode: "🇸🇿",
//             Dial: "268",
//             Currency: "SZL",
//             Capital: "Mbabane",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Sweden",
//             Iso2: "SE",
//             Iso3: "SWE",
//             Unicode: "🇸🇪",
//             Dial: "46",
//             Currency: "SEK",
//             Capital: "Stockholm",
//             Continent: "EU",
//             Symbol: "kr"
//         },
//         {
//             Name: "Switzerland",
//             Iso2: "CH",
//             Iso3: "CHE",
//             Unicode: "🇨🇭",
//             Dial: "41",
//             Currency: "CHF",
//             Capital: "Berne",
//             Continent: "EU",
//             Symbol: "CHF"
//         },
//         {
//             Name: "Syria",
//             Iso2: "SY",
//             Iso3: "SYR",
//             Unicode: "🇸🇾",
//             Dial: "963",
//             Currency: "SYP",
//             Capital: "Damascus",
//             Continent: "AS",
//             Symbol: "£"
//         },
//         {
//             Name: "Taiwan",
//             Iso2: "TW",
//             Iso3: "TWN",
//             Unicode: "🇹🇼",
//             Dial: "886",
//             Currency: "TWD",
//             Capital: "Taipei",
//             Continent: "AS",
//             Symbol: "NT$"
//         },
//         {
//             Name: "Tajikistan",
//             Iso2: "TJ",
//             Iso3: "TJK",
//             Unicode: "🇹🇯",
//             Dial: "992",
//             Currency: "TJS",
//             Capital: "Dushanbe",
//             Continent: "AS",
//             Symbol: ""
//         },
//         {
//             Name: "Tanzania",
//             Iso2: "TZ",
//             Iso3: "TZA",
//             Unicode: "🇹🇿",
//             Dial: "255",
//             Currency: "TZS",
//             Capital: "Dodoma",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Thailand",
//             Iso2: "TH",
//             Iso3: "THA",
//             Unicode: "🇹🇭",
//             Dial: "66",
//             Currency: "THB",
//             Capital: "Bangkok",
//             Continent: "AS",
//             Symbol: "฿"
//         },
//         {
//             Name: "Togo",
//             Iso2: "TG",
//             Iso3: "TGO",
//             Unicode: "🇹🇬",
//             Dial: "228",
//             Currency: "XOF",
//             Capital: "Lome",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Tokelau",
//             Iso2: "TK",
//             Iso3: "TKL",
//             Unicode: "🇹🇰",
//             Dial: "690",
//             Currency: "NZD",
//             Capital: "",
//             Continent: "OC",
//             Symbol: "$"
//         },
//         {
//             Name: "Tonga",
//             Iso2: "TO",
//             Iso3: "TON",
//             Unicode: "🇹🇴",
//             Dial: "676",
//             Currency: "TOP",
//             Capital: "Nuku'alofa",
//             Continent: "OC",
//             Symbol: "T$"
//         },
//         {
//             Name: "Trinidad and Tobago",
//             Iso2: "TT",
//             Iso3: "TTO",
//             Unicode: "🇹🇹",
//             Dial: "1-868",
//             Currency: "TTD",
//             Capital: "Port of Spain",
//             Continent: "NA",
//             Symbol: "TT$"
//         },
//         // {
//         //     Name: "Tunisia",
//         //     Iso2: "TN",
//         //     Iso3: "TUN",
//         //     Unicode: "🇹🇳",
//         //     Dial: "216",
//         //     Currency: "TND",
//         //     Capital: "Tunis",
//         //     Continent: "AF",
//         //     Symbol: ""
//         // },
//         {
//             Name: "Turkey",
//             Iso2: "TR",
//             Iso3: "TUR",
//             Unicode: "🇹🇷",
//             Dial: "90",
//             Currency: "TRY",
//             Capital: "Ankara",
//             Continent: "AS",
//             Symbol: "YTL"
//         },
//         {
//             Name: "Turkmenistan",
//             Iso2: "TM",
//             Iso3: "TKM",
//             Unicode: "🇹🇲",
//             Dial: "993",
//             Currency: "TMT",
//             Capital: "Ashgabat",
//             Continent: "AS",
//             Symbol: "m"
//         },
//         // {
//         //     Name: "Turks and Caicos Islands",
//         //     Iso2: "TC",
//         //     Iso3: "TCA",
//         //     Unicode: "🇹🇨",
//         //     Dial: "1-649",
//         //     Currency: "USD",
//         //     Capital: "Cockburn Town",
//         //     Continent: "NA",
//         //     Symbol: "$"
//         // },
//         // {
//         //     Name: "Tuvalu",
//         //     Iso2: "TV",
//         //     Iso3: "TUV",
//         //     Unicode: "🇹🇻",
//         //     Dial: "688",
//         //     Currency: "AUD",
//         //     Capital: "Funafuti",
//         //     Continent: "OC",
//         //     Symbol: "$"
//         // },
//         // {
//         //     Name: "U.S. Virgin Islands",
//         //     Iso2: "VI",
//         //     Iso3: "VIR",
//         //     Unicode: "🇻🇮",
//         //     Dial: "1-340",
//         //     Currency: "USD",
//         //     Capital: "Charlotte Amalie",
//         //     Continent: "NA",
//         //     Symbol: "$"
//         // },
//         {
//             Name: "Uganda",
//             Iso2: "UG",
//             Iso3: "UGA",
//             Unicode: "🇺🇬",
//             Dial: "256",
//             Currency: "UGX",
//             Capital: "Kampala",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Ukraine",
//             Iso2: "UA",
//             Iso3: "UKR",
//             Unicode: "🇺🇦",
//             Dial: "380",
//             Currency: "UAH",
//             Capital: "Kiev",
//             Continent: "EU",
//             Symbol: "₴"
//         },
//         {
//             Name: "United Arab Emirates",
//             Iso2: "AE",
//             Iso3: "ARE",
//             Unicode: "🇦🇪",
//             Dial: "971",
//             Currency: "AED",
//             Capital: "Abu Dhabi",
//             Continent: "AS",
//             Symbol: ""
//         },
//         {
//             Name: "United Kingdom",
//             Iso2: "GB",
//             Iso3: "GBR",
//             Unicode: "🇬🇧",
//             Dial: "44",
//             Currency: "GBP",
//             Capital: "London",
//             Continent: "EU",
//             Symbol: "£"
//         },
//         // {
//         //     Name: "United States Minor Outlying Islands",
//         //     Iso2: "UM",
//         //     Iso3: "UMI",
//         //     Unicode: "🇺🇲",
//         //     Dial: "1",
//         //     Currency: "USD",
//         //     Capital: "",
//         //     Continent: "OC",
//         //     Symbol: "$"
//         // },
//         {
//             Name: "Uruguay",
//             Iso2: "UY",
//             Iso3: "URY",
//             Unicode: "🇺🇾",
//             Dial: "598",
//             Currency: "UYU",
//             Capital: "Montevideo",
//             Continent: "SA",
//             Symbol: "$U"
//         },
//         {
//             Name: "Uzbekistan",
//             Iso2: "UZ",
//             Iso3: "UZB",
//             Unicode: "🇺🇿",
//             Dial: "998",
//             Currency: "UZS",
//             Capital: "Tashkent",
//             Continent: "AS",
//             Symbol: "лв"
//         },
//         {
//             Name: "Vanuatu",
//             Iso2: "VU",
//             Iso3: "VUT",
//             Unicode: "🇻🇺",
//             Dial: "678",
//             Currency: "VUV",
//             Capital: "Port Vila",
//             Continent: "OC",
//             Symbol: "Vt"
//         },
//         // {
//         //     Name: "Vatican",
//         //     Iso2: "VA",
//         //     Iso3: "VAT",
//         //     Unicode: "🇻🇦",
//         //     Dial: "379",
//         //     Currency: "EUR",
//         //     Capital: "Vatican City",
//         //     Continent: "EU",
//         //     Symbol: "€"
//         // },
//         {
//             Name: "Venezuela",
//             Iso2: "VE",
//             Iso3: "VEN",
//             Unicode: "🇻🇪",
//             Dial: "58",
//             Currency: "VEF",
//             Capital: "Caracas",
//             Continent: "SA",
//             Symbol: "Bs"
//         },
//         {
//             Name: "Vietnam",
//             Iso2: "VN",
//             Iso3: "VNM",
//             Unicode: "🇻🇳",
//             Dial: "84",
//             Currency: "VND",
//             Capital: "Hanoi",
//             Continent: "AS",
//             Symbol: "₫"
//         },
//         {
//             Name: "Wallis and Futuna",
//             Iso2: "WF",
//             Iso3: "WLF",
//             Unicode: "🇼🇫",
//             Dial: "681",
//             Currency: "XPF",
//             Capital: "Mata Utu",
//             Continent: "OC",
//             Symbol: ""
//         },
//         {
//             Name: "Western Sahara",
//             Iso2: "EH",
//             Iso3: "ESH",
//             Unicode: "🇪🇭",
//             Dial: "212",
//             Currency: "MAD",
//             Capital: "El-Aaiun",
//             Continent: "AF",
//             Symbol: ""
//         },
//         {
//             Name: "Yemen",
//             Iso2: "YE",
//             Iso3: "YEM",
//             Unicode: "🇾🇪",
//             Dial: "967",
//             Currency: "YER",
//             Capital: "Sanaa",
//             Continent: "AS",
//             Symbol: "﷼"
//         },
//         {
//             Name: "Zambia",
//             Iso2: "ZM",
//             Iso3: "ZMB",
//             Unicode: "🇿🇲",
//             Dial: "260",
//             Currency: "ZMK",
//             Capital: "Lusaka",
//             Continent: "AF",
//             Symbol: "ZK"
//         },
//         {
//             Name: "Zimbabwe",
//             Iso2: "ZW",
//             Iso3: "ZWE",
//             Unicode: "🇿🇼",
//             Dial: "263",
//             Currency: "ZWL",
//             Capital: "Harare",
//             Continent: "AF",
//             Symbol: "Z$"
//         }
//     ]







export const countries_list = [
  {
    "Name": "Saudi Arabia",
    "Unicode": "🇸🇦",
    "Dial": "+966",
    "Currency": "SAR",
    "currency": "Saudi riyal",
    "Symbol": "﷼"
  },
  {
    "Name": "United Arab Emirates",
    "Unicode": "🇦🇪",
    "Dial": "+971",
    "Currency": "AED",
    "currency": "UAE dirham",
    "Symbol": "د.إ"
  },
  {
    "Name": "South Africa",
    "Unicode": "🇿🇦",
    "Dial": "+27",
    "Currency": "ZAR",
    "currency": "South African rand",
    "Symbol": "R"
  },
  {
    "Name": "Egypt",
    "Unicode": "🇪🇬",
    "Dial": "+20",
    "Currency": "EGP",
    "currency": "Egyptian pound",
    "Symbol": "E£"
  },
  {
    "Name": "Switzerland",
    "Unicode": "🇨🇭",
    "Dial": "+41",
    "Currency": "CHF",
    "currency": "Swiss franc",
    "Symbol": "CHF"
  },
  {
    "Name": "United States",
    "Unicode": "🇺🇸",
    "Dial": "+1",
    "Currency": "USD",
    "Symbol": "$"
  },
  {
    "Name": "Sweden",
    "Unicode": "🇸🇪",
    "Dial": "+46",
    "Currency": "SEK",
    "currency": "Swedish krona",
    "Symbol": "kr"
  },
  {
    "Name": "Japan",
    "Unicode": "🇯🇵",
    "Dial": "+81",
    "Currency": "JPY",
    "currency": "Japanese yen",
    "Symbol": "¥"
  },
  {
    "Name": "South Korea",
    "Unicode": "🇰🇷",
    "Dial": "+82",
    "Currency": "KRW",
    "currency": "South Korean won",
    "Symbol": "₩"
  },
  {
    "Name": "Germany",
    "Unicode": "🇩🇪",
    "Dial": "+49",
    "Currency": "EUR",
    "currency": "Euro",
    "Symbol": "€"
  },
  {
    "Name": "United Kingdom",
    "Unicode": "🇬🇧",
    "Dial": "+44",
    "Currency": "GBP",
    "currency": "Pound sterling",
    "Symbol": "£"
  },
  {
    "Name": "India",
    "Unicode": "🇮🇳",
    "Dial": "+91",
    "Currency": "INR",
    "currency": "Indian rupee",
    "Symbol": "₹"
  },
  {
    "Name": "Brazil",
    "Unicode": "🇧🇷",
    "Dial": "+55",
    "Currency": "BRL",
    "currency": "Brazilian real",
    "Symbol": "R$"
  },
  {
    "Name": "South Korea",
    "Unicode": "🇰🇷",
    "Dial": "+82",
    "Currency": "KRW",
    "currency": "South Korean won",
    "Symbol": "₩"
  },
  {
    "Name": "Russia",
    "Unicode": "🇷🇺",
    "Dial": "+7",
    "Currency": "RUB",
    "Symbol": "₽"
  },
]
