import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';
import menuImage from 'Assets/menuimage.svg';
import { useState } from 'react';
import SideDishCard from './Components/SideDishCard';
import AddSideDishModal from './Components/AddSideDishModal';
import { useQuery } from 'react-query';
import { SIDEDISHLIST } from 'Constants/QueriesKeys';
import { http } from 'Utils/Http/Http';
import { LocalStorage } from 'Utils/LocalStorage';
import { useTranslation } from 'react-i18next';
import { Btn } from 'Components';
import { useSelector } from 'react-redux';

const storage = new LocalStorage();

const useStyle = makeStyles((theme) => ({
  heading: {
    color: '#1A1A1A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '30px',
  },
  tagline: {
    color: '#767676',
  },
  addSideDishButton: {
    color: '#fff',
    backgroundColor: '#133FE3 !important',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '22px',
    padding: '8px 25px'
  },
  texts: {
    paddingBottom: '5%',
  },
  menuimage: {
    marginTop: '10%',
  },
}));

const SideDish = () => {
  const classes = useStyle();
  const { t } = useTranslation();

  const storeData = useSelector((state) => state?.globalStore);

  const langPref = storeData?.companyDetails?.languagePreference;

  const [addSideDishModal, setAddSideDishModal] = useState(false);
  const [sideDishArray, setSideDishArray] = useState()
  const [sideDishLishRefetch, setSideDishLishRefetch] = useState(true)
  const [selectedSideDish, setSelectedSideDish] = useState()

  const sideDishListRender = useQuery([SIDEDISHLIST], () =>
    http.get(`api/v1/side-dish`, {
      params: {
        page: 1,
        limit: 10
      }
    }).then(({ data }) => {
      setSideDishLishRefetch(false)
      setSideDishArray(data?.data?.records);
      return (data)
    }),
    {
      enabled: sideDishLishRefetch ? true : false,
    },
  );


  const handleAddMenu = (e) => {
    setSelectedSideDish()
    setAddSideDishModal(true);
  };

  return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={10}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item>
              <h1 className={classes.heading}>{t('admin.sideDish.sideDish')}</h1>
              <Typography className={classes.tagline}>
                {t('admin.sideDish.sideDishText')}
              </Typography>
            </Grid>
            <Grid item>
              <Btn className={classes.addSideDishButton} onClick={handleAddMenu}>
                <AddIcon fontSize='small' /> {t('admin.sideDish.addNewSideDIsh')}
              </Btn>
            </Grid>
          </Grid>
        </Grid>
        {sideDishListRender?.isLoading ?
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h3 style={{ fontSize: "18px" }}><CircularProgress style={{ height: "18px", width: "18px" }} /> {t('common.loading')}</h3>
          </Grid>
          :
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              container
              justifyContent='flex-start'
              alignItems='center'
              spacing={2}
            >
              {sideDishListRender?.isRefetching ?
                <Grid container>
                  <h3 style={{ fontSize: "18px" }}><CircularProgress style={{ height: "18px", width: "18px" }} /> {t('common.loading')}</h3>
                </Grid>
                :
                sideDishArray?.length ? sideDishArray?.map((item,index) => {
                  return (
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3} key={index}>
                        <SideDishCard
                          item={item}
                          setSelectedSideDish={setSelectedSideDish}
                          langPref={langPref}
                          setAddSideDishModal={setAddSideDishModal}
                          sideDishListRefetch={() => sideDishListRender.refetch()}
                        />
                      </Grid>
                  );
                })
                  :
                  (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid
                        container
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                      >
                        <Grid item>
                          <img className={classes.menuimage} alt="Menu" src={menuImage} />
                        </Grid>
                        <Grid item>
                          <div className={classes.texts}>
                            <h2 className={classes.heading}>{t('admin.sideDish.noSideDish')}</h2>
                            <p className={classes.tagline}>
                              {t('admin.sideDish.tapBelow')}
                            </p>
                          </div>
                        </Grid>
                        <Grid item>
                          <Button
                            className={classes.addMenuButton}
                            onClick={handleAddMenu}
                          >
                            <AddIcon fontSize='small' /> {t('admin.sideDish.addNewSideDIsh')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
              }
            </Grid>
          </Grid>
        }
      </Grid>
      <AddSideDishModal
        open={addSideDishModal}
        handleClose={() => setAddSideDishModal(false)}
        sideDishListRefetch={() => sideDishListRender.refetch()}
        item={selectedSideDish}
        languages={langPref}
        setSelectedSideDish={setSelectedSideDish}
      />
    </>
  );
};
export default SideDish;
