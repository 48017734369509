import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Container, Grid, InputAdornment, SvgIcon, Hidden, CircularProgress } from '@mui/material';
import { http } from 'Utils/Http/Http';
import yup from 'Utils/Yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Btn, InputController, setProgress } from 'Components';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import styles from "./Styles.module.css"
import EmenuLogo from "../../Assets/EmenuLogo.svg"
import { makeStyles } from '@mui/styles';


const useStyle = makeStyles((theme) => ({
addBtn: {
    color: '#fff',
    backgroundColor: '#133FE3 !important',
    borderRadius: '8px',
    padding: '8px 34px'
  }
}));

const RESET_LINK_BTN = 'RESET_LINK_BTN';

const schema = yup.object().shape({
    password: yup.string().required(),
    password_confirmation: yup.string().required().oneOf([yup.ref('password')], 'confirmNewPassword'),
    // password: yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'passwordRule'),
    // password_confirmation: yup.string().matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    //     'passwordRule',
    // ).oneOf([yup.ref('password')], 'Password and Confirm Password do not match.'),
});


export default function ResetPasswordPage(props) {

    const classes = useStyle();
    const { t } = useTranslation();
    const history = useHistory();
    const { match } = props;
    const { params } = match;
    const [successfully, setSuccessfully] = useState(false)
    const [passwordType, setPasswordType] = useState('password')
    const [confirmType, setConfirmType] = useState('password')
    const togglePasswordType = (val) => {
        if (val === 'password') {
            if (passwordType === 'password') {
                setPasswordType('text')
            } else if (passwordType === 'text') {
                setPasswordType('password')
            }
        } else if (val === 'confirm') {
            if (confirmType === 'password') {
                setConfirmType('text')
            } else if (confirmType === 'text') {
                setConfirmType('password')
            }
        }
    }

    const { control, handleSubmit, setError } = useForm({
        resolver: yupResolver(schema),
    });

    const handleForgotPassword = (values) => {
        const data = {
            email: params.emailId,
            resetPasswordToken: params.token,
            password: values.password
        }
        setProgress(RESET_LINK_BTN, true);
        http.post('/api/v1/session/reset-password', data, {
            baseURL: process.env.REACT_APP_BASE_URL,
        })
            .then(async (res) => {
                setSuccessfully(true)
                setTimeout(() => {
                    history.push('/sign-in');
                }, 3000);
            }).catch((res) => {
                toast.error(res.message)
            })
        setProgress(RESET_LINK_BTN, false);
    }

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center px-2 md:px-0">
            {successfully ?
                <Container className="max-w-md">
                    <img className="block w-auto mx-auto" src={EmenuLogo} alt="CX" style={{ height: "40px", marginBottom: "10px" }} />
                    <Card style={{ padding: '25px 20px', borderRadius: '20px', boxShadow: '1px 1px 10px 1px rgba(21,124,15,0.4)' }}>
                        <Grid container style={{ marginTop: "10px", display: 'flex', justifyContent: 'center', flexDirection: "column" }} spacing={2}>
                            <h6 style={{ textAlign: 'center' }}>{t("password.passwordChangedSuccessfully")}</h6>
                            <br/>
                            <h7 style={{ color: "rgba(0,0,0,0.6)", display: "flex", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ width: '20px', height: '20px' }} /> &nbsp; Redirecting to Login Page</h7>
                        </Grid>
                    </Card>
                </Container>
                :
                <Grid container className={styles.main} alignItems="center">
                    <Grid item xs={12} sm={6} className={styles.left}>
                        <Grid container>
                        <Grid item xs={12}><img src={EmenuLogo} alt="EmenuLogo" style={{ position: "absolute", top: "30px", cursor:"pointer" }} onClick={()=>history.push("/")}/></Grid>
                            <Grid item xs={12}>
                                <form onSubmit={handleSubmit(handleForgotPassword)}>
                                    <Grid container spacing={9}>
                                        <Grid item xs={12}>
                                            <h3 className={styles.title}>{t('password.setNewPassword')}</h3>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputController
                                                control={control}
                                                name="password"
                                                label={t('password.newPassword')}
                                                type={passwordType}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('password')} style={{ cursor: 'pointer' }}>
                                                                {passwordType === 'password' ?
                                                                    <VisibilityOutlinedIcon />
                                                                    :
                                                                    <VisibilityOffOutlinedIcon />
                                                                }
                                                            </SvgIcon>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputController
                                                control={control}
                                                name="password_confirmation"
                                                label={t('password.confirmationPass')}
                                                type={confirmType}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <SvgIcon fontSize="small" color="action" onClick={() => togglePasswordType('confirm')} style={{ cursor: 'pointer' }}>
                                                                {confirmType === 'password' ?
                                                                    <VisibilityOutlinedIcon />
                                                                    :
                                                                    <VisibilityOffOutlinedIcon />
                                                                }
                                                            </SvgIcon>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Btn type="submit" name={RESET_LINK_BTN} fullWidth={true} className={classes.addBtn}>
                                                {t('common.update')}
                                            </Btn>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Hidden only={['xs']}>
                        <Grid item xs={12} sm={6} className={styles.right}>
                        </Grid>
                    </Hidden>
                </Grid>
            }
        </div>
    );
}
