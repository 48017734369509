import './index.css';
import { useTranslation } from 'react-i18next';
import { Route, Switch, Redirect } from 'react-router-dom';
import { setLocale } from 'yup';
import SignInPage from 'Pages/Auth/SignInPage';
import { useEffect, useState } from 'react';
import { http } from 'Utils/Http/Http';
import App from 'App';
import { LinearProgress } from '@mui/material';
import { AuthContext } from 'Contexts/AuthContext';
import GuardedRoute from 'Utils/Guards/GuardedRoute';
import AuthGuard from 'Utils/Guards/AuthGuard';
import GuestGuard from 'Utils/Guards/GuestGuard';
import SendResetLinkPage from 'Pages/Auth/SendResetLinkPage';
import ResetPasswordPage from 'Pages/Auth/ResetPasswordPage';
import HomePage from 'Pages/HomePage/HomePage';
import SignUpPage from 'Pages/Auth/SignUp/SignUpPage';
import { LocalStorage } from 'Utils/LocalStorage';
import useGetTemplates from 'Hooks/useGetTemplates';
import EmailVerification from 'Pages/Auth/Components/EmailVerification';



const storage = new LocalStorage();

setLocale({
  mixed: {
    required: 'required',
  },
  string: {
    required: 'required',
  },
});


function Entry() {

  const companyId = storage.get("user")?._id;

  const { t } = useTranslation();

  const [isFetchingUserData, setIsFetchingUserData] = useState(true);

  const {
    defaultTheme
  } = useGetTemplates();


  const [auth, setAuth] = useState({
    user: null,
    fetchUserProfile: async () => {
      return await http
        .get(`/api/v1/company/${companyId}`)
        .then((response) => {
          setAuth({ ...auth, user: response?.data?.data });
        })
        .catch(() => {
          setAuth({ ...auth, user: null });
        });
    }
  });

  useEffect(() => {
    setIsFetchingUserData(true);
    auth.fetchUserProfile().finally(() => setIsFetchingUserData(false));
    // storage.set("user",auth?.user)
    if (!auth?.user) {
      return;
    }
    if (auth?.user?.template?.title === undefined) {
      http
        .put(`/api/v1/company/${companyId}`, {
          template: {
            title: defaultTheme?.title,
            previewImage: defaultTheme?.previewImage,
            textColor: defaultTheme?.textColor,
            highlightColor: defaultTheme?.highlightColor,
            bgColor: defaultTheme?.bgColor,
            bgImage: defaultTheme?.bgImage,
            default: {
              textColor: defaultTheme?.textColor,
              highlightColor: defaultTheme?.highlightColor,
              bgColor: defaultTheme?.bgColor,
              bgImage: defaultTheme?.bgImage,
            },
          }
        })
        .then(() => {
          console.log("done");
        }
        )
        .catch(() => {
          console.log("error");
        }
        )
    }
  },[]);

  return (
    <AuthContext.Provider value={auth}>
      {isFetchingUserData ? (
        <div className="h-screen flex justify-center items-center">
          <LinearProgress variant="query" className="w-72" />
        </div>) : (
        <Switch>
          <GuardedRoute
            guards={[GuestGuard]}
            exact
            path="/sign-in"
            component={SignInPage}
          />
          <GuardedRoute
            guards={[GuestGuard]}
            exact
            path="/api/v1/company/email_verified/:email/:companyId"
            component={EmailVerification}
          />
          <GuardedRoute
            guards={[GuestGuard]}
            exact
            path="/"
            component={HomePage}
          />
          <Route exact path="/sign-up" component={SignUpPage} />
          <Route exact path="/forgot-password" component={SendResetLinkPage} />
          <Route exact path="/forget-password/:emailId/:token" component={ResetPasswordPage} />
          <Route exact path="/" render={() => {
            return (
              <Redirect to="/admin" />
            )
          }} />
          <GuardedRoute guards={[AuthGuard]} path="/" component={App} />
        </Switch>
      )}
    </AuthContext.Provider>
  );
}

export default Entry;
