import React, { useEffect, useState } from 'react';
import { Card, Container, Grid, CircularProgress } from '@mui/material';
import { http } from 'Utils/Http/Http';
import { useHistory, useParams } from 'react-router-dom';
import EmenuLogo from "../../../Assets/EmenuLogo.svg"
import { toast } from 'react-toastify';

const EmailVerification = () => {

    const { email, companyId } = useParams();
    const history = useHistory();

    const [successfully, setSuccessfully] = useState(false)


    useEffect(() => {
        http
            .get(`/api/v1/company/email_verified/${email}/${companyId}`)
            .then((res) => {
                console.log("res : ",res,res?.data,res?.statusCode);
                if (res?.data?.statusCode == 200 || res?.statusCode == 200) {
                    setSuccessfully(true)
                    toast.success("Email verified successfully")
                    setTimeout(() => {
                        history.push('/sign-in');
                    }, 3000);
                }
                else {
                    setSuccessfully(false)
                    toast.error("Email verification failed")
                    setTimeout(() => {
                        history.push('/sign-in'); 
                    }, 3000);
                }
            })
            .catch((err) => {
                toast.error("Email verification failed ",err)
                console.log("error failed :",err);
            });
    }, [])

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center px-2 md:px-0">
            {successfully ?
                <Container className="max-w-md">
                    <img className="block w-auto mx-auto" src={EmenuLogo} alt="CX" style={{ height: "40px", marginBottom: "10px" }} />
                    <Card style={{ padding: '25px 20px', borderRadius: '20px', boxShadow: '1px 1px 10px 1px rgba(21,124,15,0.4)' }}>
                        <Grid container style={{ marginTop: "10px", display: 'flex', justifyContent: 'center', flexDirection: "column" }} spacing={2}>
                            <h6 style={{ textAlign: 'center' }}>Email Verified Successfully.</h6>
                            <br />
                            <h7 style={{ color: "rgba(0,0,0,0.6)", display: "flex", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ width: '20px', height: '20px' }} /> &nbsp; Redirecting to Login Page</h7>
                        </Grid>
                    </Card>
                </Container>
                :
                <CircularProgress style={{ width: '20px', height: '20px' }} />
            }
        </div>
    )
}

export default EmailVerification;