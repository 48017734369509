import { Grid } from '@mui/material';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { makeStyles } from '@mui/styles';



const useStyle = makeStyles((theme) => ({
    desktopNav: {
        marginTop: "124px",
        flexWrap:"initial",
        minHeight: "100vh",
        [theme.breakpoints.down("md")]: {   
            marginTop: "164px",
        },
    },
    desktopNavLeft: {
        padding: '40px 0px 40px 50px',
        minHeight: "100vh",
        backgroundColor: "#FCFCFC",
        maxWidth: "calc(332px)",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down("md")]: {
            display: "none",
            padding: '20px 10px',
        },
    },
    desktopNavRight: {
        padding: '40px 50px 40px 0',
        flex: "1",
        backgroundColor: "rgba(255,255,255,1)",
        [theme.breakpoints.down("md")]: {
            padding: '20px 10px',
        },
    },
    desktop: {
        display: "block",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
}));


const Index = ({ children, links }) => {

    const classes = useStyle();
    

    return (
        <>
            <div className="" style={{ backgroundColor: 'rgba(255, 255, 255, 1)' }}>
                <div style={{ boxshadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', position: "absolute", top: "0", width: "100%" }}>
                    <Navbar links={links} />
                </div>
                {/* <div className="py-10 mt-1" >
                <div className="max-w-4xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-10 lg:gap-8">
                    <div className="hidden lg:block lg:col-span-2"> */}
                <Grid container direction="row" className={classes.desktopNav} >
                    <Grid item xs={12} md={2} className={classes.desktopNavLeft} style={{ alignItems: "flex-start" }} >
                        <div className={classes.desktop}>
                            <Sidebar links={links} />
                        </div>
                    </Grid>
                    {/* </div>
                    <div className="lg:col-span-8"> */}
                    <Grid item xs={12} md={10} className={classes.desktopNavRight}>
                        {children}
                    </Grid>
                </Grid>
                {/* </div>
                </div>
            </div> */}
            </div>
        </>
    );
}

export default Index
