import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import AppLayout from 'Layouts/AppLayout';
import { NavLinks } from 'Constants/NavLinks';
import ThemePage from 'Pages/ThemePage/ThemePage';
import QRPage from 'Pages/QRPage/QRPage';
import Dashboard from 'Pages/Dashboard/Dashboard';
import MenuPage from 'Pages/Menupage/MenuPage';
import ChangePassword from 'Pages/ChangePassword/ChangePassword';
import Profile from 'Pages/Profile/Profile';
import Settings from 'Pages/Settings/Settings';
import SideDish from 'Pages/SideDish/SideDish';
import MenuDetails from 'Pages/Menupage/Components/MenuDetails';
import { persistor, store } from './Utils/Store/Store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
// import EmailVerification from 'Pages/Auth/Components/EmailVerification';

export default function App() {
    let { path } = useRouteMatch();


    return (
        <Provider store={store}>
       <PersistGate loading={null} persistor={persistor}>
        <AppLayout links={NavLinks}>
            <Switch>
                <Route exact path="/" render={() => {
                    return (
                        <Redirect to={`${path}/user`} />
                    )
                }} />
                <Route exact path={`${path}admin/change-password`} component={ChangePassword} />
                <Route exact path={`${path}admin/profile`} component={Profile} />
                {/* <Route exact path={`${path}admin/dashboard`} component={Dashboard} /> */}
                <Route exact path={`${path}admin/menu`} component={MenuPage} />
                <Route exact path={`${path}admin/menu/:menuId`} component={MenuDetails} />
                <Route exact path={`${path}admin/theme`} component={ThemePage} />
                <Route exact path={`${path}admin/side-dish`} component={SideDish} />
                <Route exact path={`${path}admin/theme`} component={ThemePage} />
                <Route exact path={`${path}admin/qr-code`} component={QRPage} />
                <Route exact path={`${path}admin/settings`} component={Settings} />
                {/* <Route exact path={`${path}/api/v1/company/email_verified/:email/:companyId`} component={EmailVerification} /> */}
            </Switch>
        </AppLayout>
        </PersistGate>
        </Provider>
    );
}
