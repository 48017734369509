import React, { useEffect, useState } from 'react'
import styles from "./Signup.module.css"
import EmenuLogo from "../../../Assets/EmenuLogo.svg"
import { Button, Card, CircularProgress, Container, Divider, Grid, Hidden, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material'
import FirstStep from './Steps/FirstStep'
import SecondStep from './Steps/SecondStep'
import { Link, useHistory } from 'react-router-dom'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import yup from 'Utils/Yup'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';
import { toast } from 'react-toastify'
import { Btn, setProgress } from 'Components'
import { http } from 'Utils/Http/Http'
import { useTranslation } from 'react-i18next'
import SignUpSuccessModal from '../Components/SignUpSuccessModal'
import { countries_list } from 'Constants/CountryArray'

const SIGN_UP_BTN = 'SIGN_UP_BTN';





const SignUpPage = () => {
  const { t } = useTranslation();
  const [successfull, setSuccessfull] = useState(false)
  const [activeStep, setActiveStep] = useState(0);
  const [detailedSubmited,setDetailedSubmited] = useState(false);
  const [countryCodeValid,setCountryCodeValid] = useState()
  

  const history = useHistory()

  const schema1 = yup.object().shape({
    country: yup.string().required("country"),
    title: yup.string().required("businessName")
  });
  
  // const schema2 = yup.object().shape({
  //   firstName: yup.string().required("firstName"),
  //   lastName: yup.string().required("lastName"),
  //   email: yup.string().email().required("emailId"),
  //   // mobileNo: yup.string().required("phoneNo").matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, 'phoneNoValid'),
  //   // mobileNo: yup.string().required("phoneNo").matches(/^(?:(\+966|0)?5\d{8}|(\+91|0)?[6789]\d{9})$/, 'phoneNoValid'),
  //   mobileNo: yup.string().required("phoneNo").matches(/^(\+\d{1,3}[- ]?)?(?:\d{8}|\d{10})$/, 'phoneNoValid'),
  //   password: yup.string().required("password"),
  //   confirmPassword: yup.string().oneOf([yup.ref('password'),null], 'passwordMatch'),
  //   countryCode: yup.string().required("countryCode"),
  //   // password: yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'passwordRule'),
  //   // confirmPassword: yup.string().matches(
  //   //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //   //     'passwordRule',
  //   // ).oneOf([yup.ref('password')], 'Password and Confirm Password do not match.'),
  // });


  const schema2 = yup.object().shape({
    firstName: yup.string().required("firstName"),
    lastName: yup.string().required("lastName"),
    email: yup.string().email().required("emailId"),
    mobileNo: yup.string()
      .required("phoneNo")
      .test("phoneNoValid", 'phoneNoValid', function (value) {
        const countryCodes = {
          // "+91": 10,
          "+966": 9,
        };
        
        if (!countryCodeValid) {
          return true;
        }
        const digits = countryCodes[countryCodeValid] ? countryCodes[countryCodeValid] : 10;
        console.log("test : ",countryCodeValid,countryCodes[countryCodeValid],digits);
        const regex = new RegExp(`^\\d{${digits}}$`);
        return regex.test(value);
      }),
    password: yup.string().required("password"),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'passwordMatch'),
    countryCode: yup.string().required("countryCode"),
  });


  const form = useForm({
    resolver: yupResolver(
      activeStep === 0 ? schema1 : schema2
    ),
  });
  const { control, handleSubmit, setError, reset, errors, getValues, watch, setValue } = form;


  useEffect(() => {
  
      setCountryCodeValid(countries_list?.filter((item) => item?.Name == getValues('country'))[0]?.Dial);
  
  },[watch('country')])


  useEffect(() => {
   
      setCountryCodeValid(getValues('countryCode'));
   
  },[watch('countryCode')])

  console.log("countryCodeValid : ",countryCodeValid,watch('countryCode'),getValues('countryCode'));


  const watchBusinessLogo = useWatch({
    name: ["businessLogo"],
    control
  })


  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <FirstStep
            control={control}
            form={form}
            errors={errors}
          />
        );
      case 1:
        return (
          <SecondStep
            control={control}
            form={form}
            errors={errors}
            countryCode={countries_list?.filter((item) => item?.Name == getValues('country'))}
          />
        );
      default:
        return <FirstStep />;
    }
  }
  const steps = [
    "Business Details",
    "Owner Details"
  ]
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFormSubmit = async (values) => {
    setProgress(SIGN_UP_BTN, true);
    setDetailedSubmited(true)
    let formData = new FormData();

    for (var i = 0; i < values.businessLogo?.length; i++) {
      formData.append("attachment", values.businessLogo[i]);
    }

    const countryCurrency = countries_list?.find((item) => item?.Name === values?.country)

    const data = {
      title: values?.title,
      firstName: values?.firstName,
      lastName: values?.lastName,
      mobileNo: values?.mobileNo,
      email: values?.email,
      password: values?.password,
      country: values?.country,
      currency: countryCurrency?.Symbol,
      countryCode: values?.countryCode
    }

    try {
      await http.post(`/api/v1/file-upload`, formData)
        .then(async (res) => {
          data['logo'] = res.data.data[0].location
          try {
            await http.post(`/api/v1/company`, data)
              .then(async (res) => {
                setSuccessfull(true)
                setTimeout(() => {
                  history.push('/sign-in');
                }, 3000);
              });
            reset({});
          } catch (res) {
            setDetailedSubmited(false)
            toast.error(res?.response?.data?.message)
          }
        });
    } catch (res) {
      setDetailedSubmited(false)
      toast.error(res?.response?.data?.message)
    }
    setProgress(SIGN_UP_BTN, false);
  }
  
  const handleNext = (data) => {
    if (activeStep === 0) {
      if (watchBusinessLogo?.length) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      } else {
        toast.error("Please Select Business Logo.")
      }
    } else if (steps?.length === activeStep + 1) {
      handleFormSubmit(data);
    }
  };
  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
    return (
      completed ?
        <CircleIcon />
        :
        active ?
          <RadioButtonCheckedIcon />
          :
          <RadioButtonUncheckedIcon />
    );
  }


  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center px-2 md:px-0">
      <Grid container className={styles.main}>
        <Grid container className={styles.container}>
          <Hidden only={["xs"]}>
            <Grid item xs={false} sm={5} md={4} lg={4}>
              <Paper className={styles.paper_left}>
                <div className={styles.headerMargin}>
                  <img style={{ cursor: "pointer" }} src={EmenuLogo} alt="logo" onClick={() => history.push("/")} />
                </div>
                <Stepper
                  className={styles.verticalStep}
                  activeStep={activeStep}
                  orientation="vertical"
                >
                  {steps.map((label, index) => (
                    <Step className={styles.verticalStepLabel} key={label}>
                      <StepLabel StepIconComponent={ColorlibStepIcon} className={styles.verticalStepLabel}>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <p className={styles.rightsReserved}>@2022 Emenu, All Right Reserved</p>
              </Paper>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={7} md={8} lg={8}>
            <form className={styles.form} onSubmit={handleSubmit(handleNext)}>
              <Grid container className={styles.paper_right_data}>
                <Grid item xs={12} >
                  <p className={`${styles.signInLink} ${styles.headerMargin}`} style={{ textAlign: "end" }}>Already have an account ? <Link to="/sign-in">Log in</Link></p>
                  <Grid container>
                    <Grid item xs={12} sm={11} md={11} lg={9}>
                      {getStepContent(activeStep)}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                className={styles.paper_right_bottom}
              >
                <Grid item xs={12}>
                  <Divider width="100%" />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="space-between" className={styles.paper_right_btn} style={{ padding: "16px 16px", maxWidth: "90%" }} >
                    {activeStep === 0 ? (
                      <Button
                        color="primary"
                        className={`${styles.prevBtn}`}
                        onClick={() => history.push("/sign-in")}
                      >
                        Back To Sign In
                      </Button>
                    ) : (
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        color="primary"
                        className={`${styles.prevBtn}`}
                      >
                        Previous Step
                      </Button>
                    )}
                    {activeStep < steps.length - 1 ? (
                      <Button
                        type="submit"
                        color="primary"
                        className={styles.navigation}
                        variant="contained"
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        color="primary"
                        fullWidth={false}
                        className={styles.navigation}
                        variant="contained"
                        name={SIGN_UP_BTN}
                        disabled={detailedSubmited}
                        >
                        Finish
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
      <SignUpSuccessModal open={successfull} handleClose={() => setSuccessfull(false)} />
    </div>
  )
}

export default SignUpPage