import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {
  Card,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import {
  Btn,
  ConfirmationDialog,
  InputController,
  RadioGroupController,
  setProgress,
} from 'Components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import yup from 'Utils/Yup';
import { handleError, http } from 'Utils/Http/Http';
import { LocalStorage } from 'Utils/LocalStorage';
import TimePicker from '@mui/lab/TimePicker';
import ImageController from 'Components/FormControls/Controllers/ImageController';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";


import "react-datepicker/dist/react-datepicker.css";

var moment = require('moment');

const storage = new LocalStorage();
const SAVE_BTN = 'SAVE_BTN';

const CHANGE_LINK_BTN = 'CHANGE_LINK_BTN';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '65%',
  borderRadius: '10px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const useStyle = makeStyles((theme) => ({
  card: {
    padding: '10px',
    boxShadow: 'none',
    border: '2px solid #EFEFF4',
    borderRadius: '5px',
  },
  heading: {
    color: '#1A1A1A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '30px',
  },
  tagline: {
    color: '#767676',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '26px',
  },
  divider: {
    margin: '10px 0px 5px 0px',
  },
  time: {
    padding: '0px 15px 0px 15px',
  },
  autoFill: {
    color: '#133FE3',
    cursor: 'pointer',
  },
  spacing: {
    padding: '10px',
  },
  addBtn: {
    color: '#fff',
    backgroundColor: '#133FE3 !important',
    borderRadius: '8px',
    padding: '8px 34px'
  },
  cancelBtn: {
    color: '#133FE3 !important',
    borderRadius: '8px',
    background: '#fff',
    border: '1px solid #133FE3',
    padding: '6px 25px',
    '&:hover': {
      background: '#fff',
    }
  },
  langPrefelect: {
    padding: '5px',
  },
  language: {
    border: '1px solid #D0D5DD',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },
  radioBtn: {
    '& .MuiTypography-root': {
      fontFamily: 'Inter !important',
      fontStyle: 'normal !important',
      fontWeight: '400 !important',
      fontSize: '14px !important',
      lineHeight: '18px !important',
    },
    '& .MuiRadio-root': {
      color: '#D8D8D8',
    },
    '& .MuiRadio-root.Mui-checked': {
      color: '#000000',
    },
  },
  deleteBtn: {
    color: '#E31313',
    cursor: 'pointer'
  },
  timePicker: {
    width: '120px',
    height: '40px',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    textAlign: 'center',
    '& .MuiOutlinedInput-root': {
      width: '150px',
      height: '65px',
      border: '1px solid #D0D5DD',
      borderRadius: '8px',
      flexDirection: 'row-reverse !important'
    }
  },
  chooseFileBtn: {
    border: '1px solid #1967D2',
    padding: '5px 25px',
    textTransform: 'none'
  }
}));


const AddMenuModal = ({
  open,
  handleClose,
  promptModal,
  setPromptModal,
  id,
  item,
  setSelectedMenu,
  langPref,
  menuListRefetch
}) => {

  const classes = useStyle();
  const { t } = useTranslation();
  const history = useHistory();

  const storeData = useSelector((state) => state?.globalStore);

  const primaryLanguage = storeData?.primaryLanguage.charAt(0).toUpperCase() + storeData?.primaryLanguage.slice(1);

  const [lang, setLang] = useState([]);
  const [autoFill, setAutoFill] = useState(false);
  const [language, setLanguage] = useState();
  const [cancelDisabled, setCancelDisabled] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(primaryLanguage)
  const [direction, setDirection] = useState(storeData?.primaryLanguage == "arabic" ? "rtl" : "ltr");
  const [deleteModal, setDeleteModal] = useState(false)
  const [startTime, setStartTime] = useState("07:00 AM");
  const [endTime, setEndTime] = useState("10:00 PM");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());


  const companyId = storage.get('user')?._id;

  const userDetails = storage.get('user');

  const currentTime = moment();

  // const form = useForm({
  //   resolver: yupResolver(schema),
  // });

  const schema = yup.object().shape({

    // title: yup.string().when('/', {
    //   is: (val) => !val?.english?.title,
    //   then: yup.string().required(`Title is required in ${langPref[0].title?.toLowerCase()}`),
    //   otherwise: yup.string(),
    // })

    // title : yup.string().required(),
    // time: yup.string().required(),
    // images: yup.required()
    // menuImage: yup.required()
    // confirmNewPassword: yup.string().oneOf([yup.ref('newPassword')], 'Password and Confirm Password do not match.'),
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: item ? item : null
  });

  const timeOptions = [
    {
      label: t('admin.menu.always'),
      value: 'always',
    },
    {
      label: t('admin.menu.custom'),
      value: 'custom',
    },
  ];

  const {
    control,
    handleSubmit,
    reset,
    errors,
    setValue,
    watch,
  } = form;




  useEffect(() => {
    if (autoFill === true) {
      setValue(`${storeData?.primaryLanguage}.title`, 'Breakfast');
    }
  }, [autoFill]);



  const handleSoftDelete = async () => {
    try {
      await http.delete(`/api/v1/menu/${item?._id}`)
        .then(async (res) => {
          setSelectedLanguage(primaryLanguage)
          setDirection(storeData?.primaryLanguage == "arabic" ? "rtl" : "ltr")
          toast.success(t('toast.success.deletedSuccessfully'))
          setDeleteModal(false)
          handleClose();
          menuListRefetch()
          history.push(`/admin/menu`);
        });
    } catch (error) {
      handleError(error);
    }
  };




  const handleLanguage = (e) => {
    const selectedLanguages = langPref.find(
      (item) => item?.title === e.target.value
    );
    setSelectedLanguage(e.target.value);
    setDirection(selectedLanguages?.direction);
  };


  const handlePrompt = () => {
    try {
      setPromptModal(false);
      handleClose();
      toast.success('Menu Saved Successfully');
      history.push('/admin/menu');
    } catch (error) {
      toast.error('Error, failed !! ');
    }
  }

  const handleDelete = () => {
    setDeleteModal(true)
  }


  useEffect(() => {
    setSelectedLanguage(primaryLanguage)
    setDirection(storeData?.primaryLanguage == "arabic" ? "rtl" : "ltr")
    if (item) {
      reset(item)
    } else {
      reset({})
    }
  }, [open])


  const handleFormSubmit = async (data) => {

    setProgress(SAVE_BTN, true);

    if (item) {
      try {
        await http.put(`/api/v1/menu/${item?._id}`, data)
          .then(async (res) => {
            toast.success("Menu Updated")
            setSelectedLanguage(primaryLanguage)
            setDirection(storeData?.primaryLanguage == "arabic" ? "rtl" : "ltr")
            userDetails.logo = res?.data?.data?.image;
            storage.set('user', userDetails);
            handleClose()
            menuListRefetch()
          });
      } catch (res) {
        toast.error(res?.response?.data?.message)
      }
    }
    else {
      try {
        await http.post(`/api/v1/menu`, data)
          .then(async (res) => {
            setSelectedLanguage(primaryLanguage)
            setDirection(storeData?.primaryLanguage == "arabic" ? "rtl" : "ltr")
            toast.success("Menu Added");
            handleClose();
            menuListRefetch();
          });
      } catch (res) {
        toast.error(res?.response?.data?.message)
      }
    }
    setCancelDisabled(false)
    setProgress(SAVE_BTN, false);
  }


  const fileUploadApi = async (values) => {

    if (values[langPref[0].title?.toLowerCase()]?.title?.length == 0) {
      toast?.error('Please fill primary language details.')
    }
    else {

      for (let i = 0; i < langPref.length; i++) {
        const langTitle = langPref[i].title?.toLowerCase();
        if (langTitle && values.hasOwnProperty(langTitle) && values[langTitle].title === "") {
          delete values[langTitle];
        }
      }

      // if(values.hasOwnProperty(langPref[1].title?.toLowerCase()) && values[langPref[1].title?.toLowerCase()].title === ""){
      //   delete values[langPref[1].title?.toLowerCase()]
      // }
      // if(values.hasOwnProperty(langPref[2].title?.toLowerCase()) && values[langPref[2].title?.toLowerCase()].title === ""){
      //   delete values[langPref[2].title?.toLowerCase()]
      // }

      var data = {}

      if (values?.time === 'always') {
        data["visibilityTime"] = [
          {
            "start": "07:00 AM",
            "end": "10:00 PM"
          }
        ]
      }
      else {
        data["visibilityTime"] = [
          {
            "start": startTime ? startTime : currentTime.format("h:mm A"),
            "end": endTime ? endTime : currentTime.format("h:mm A")
          }
        ]
      }

      for (let d = 0; d < langPref.length; d++) {
        data[langPref[d].title?.toLowerCase()] = values[langPref[d]?.title?.toLowerCase()]
      }
      setProgress(SAVE_BTN, true);
      setCancelDisabled(true)

      let formData = new FormData();

      for (var i = 0; i < values?.images?.length; i++) {
        formData.append("attachment", values?.images[i]);
      }

      if (values?.images?.length) {
        try {
          await http.post(`/api/v1/file-upload`, formData)
            .then(async (res) => {
              // for (let j = 0; j < res?.data?.data?.length; j++) {
              data['image'] = res?.data?.data[0]?.location;
              // }
              handleFormSubmit(data)
            });
        } catch (res) {
          toast.error(res?.response?.data?.message)
        }
      } else {
        if (item) {
          data['image'] = item?.image
          handleFormSubmit(data)
        } else {
          toast.error("Please select image")
          setCancelDisabled(false)
          setProgress(SAVE_BTN, false);
        }
      }
    }
  }


  const formatTime = (date) => {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };


  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form className='space-y-4' onSubmit={handleSubmit(fileUploadApi)}>
              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
                className={classes.spacing}
                spacing={5}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item>
                      <h1 className={classes.heading}>{item ? "Edit Menu" : "Add Menu"}</h1>
                      <Typography className={classes.tagline}>
                        {item ? "Edit " : "Add"} a menu to your account.
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Grid item>

                          <FormControl
                            variant='standard'
                            sx={{ m: 1, minWidth: 100 }}
                          >
                            <Select
                              variant='outlined'
                              control={control}
                              className={classes.language}
                              name="language"
                              value={selectedLanguage}
                              size='small'
                              onChange={handleLanguage}
                            >
                              {langPref?.map((item, index) => {
                                return (
                                  <MenuItem value={item?.title} key={index}>
                                    {item?.title == primaryLanguage ? item?.title + " " + "(primary)" : item?.title}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        {
                          !item ?
                            <Grid item>
                              <span
                                className={classes.autoFill}
                                onClick={() => setAutoFill(true)}
                              >
                                Auto fill
                              </span>
                            </Grid>
                            : null
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {langPref?.map((langItem, index) => {
                    return (
                      selectedLanguage === langItem?.title ?
                        <InputController
                          key={index}
                          dir={direction}
                          control={control}
                          label={t('admin.menu.addMenuTitle') + '' + "*"}
                          name={`${langItem?.title?.toLowerCase()}.title`}
                        />
                        : null
                    )
                  })}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Card className={classes.card}>
                    <Grid
                      container
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h2 className={classes.heading}>
                          Add time for visibility of menu*
                        </h2>
                        <Divider variant='middle' className={classes.divider} />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <RadioGroupController
                          control={control}
                          name='time'
                          row
                          defaultValue={'always'}
                          helperText={null}
                          options={timeOptions}
                          className={classes.radioBtn}
                        />
                      </Grid>
                      {watch('time') === 'custom' ? (
                        <>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid
                              container
                              direction='row'
                              justifyContent='flex-start'
                              alignItems='center'
                            >
                              <Grid item>
                                {/* <TimePicker
                                  value={startTime}
                                  control={control}
                                  name='start'
                                  onChange={(time) => {
                                    setStartTime(time);
                                    setValue('start', moment(time?.$d).format('hh:mm A'));
                                  }}
                                  renderInput={(params) => (
                                    <TextField control={control} {...params} className={classes.timePicker} />
                                  )}
                                /> */}
                                {/* <input 
                                type="time"
                                 value={startTime ? moment(startTime, 'hh:mm A').format('HH:mm') : ''}
                                  name='start'
                                  onChange={(time) => {
                                    setStartTime(moment(time.target.value, 'HH:mm').format('hh:mm A'));
                                    setValue('end', moment(time.target.value, 'HH:mm').format('hh:mm A'));
                                  }}
                                  className={classes.timePicker}
                                /> */}

                                <DatePicker
                                  value={startTime}
                                  name='start'
                                  onChange={(time) => {
                                    setStartTime(moment(time, 'HH:mm').format('hh:mm A'));
                                    setValue('start', moment(time, 'HH:mm').format('hh:mm A'));
                                    setStartDate(time);
                                  }}
                                  selected={startDate}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={1}
                                  dateFormat="HH:mm aa"
                                  // timeFormat="HH:mm aa"
                                  className={`${classes.timePicker} selectTime`}
                                />
                              </Grid>
                              <Grid item>
                                <Typography className={classes.time}>
                                  To
                                </Typography>
                              </Grid>
                              <Grid item>
                                {/* <TimePicker
                                  value={endTime}
                                  control={control}
                                  name='end'
                                  onChange={(time) => {
                                    setEndTime(time);
                                    setValue('end', moment(time?.$d).format('hh:mm A'));
                                  }}
                                  renderInput={(params) => (
                                    <TextField control={control} {...params} className={classes.timePicker} />
                                  )}
                                /> */}
                                {/* <input
                                  type="time"
                                  value={endTime ? moment(endTime, 'hh:mm A').format('HH:mm') : ''}
                                  name='endTime'
                                  onChange={(time) => {
                                    setEndTime(moment(time.target.value, 'HH:mm').format('hh:mm A'));
                                    setValue('end', moment(time.target.value, 'HH:mm').format('hh:mm A'));
                                  }}
                                  className={classes.timePicker}
                                /> */}

                                <DatePicker
                                  name='endTime'
                                  value={endTime}
                                  selected={endDate}
                                  onChange={(time) => {
                                    setEndTime(moment(time, 'HH:mm').format('hh:mm A'));
                                    setValue('end', moment(time, 'HH:mm').format('hh:mm A'));
                                    setEndDate(time)
                                  }}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={1}
                                  dateFormat='hh:mm aa'
                                  className={`${classes.timePicker} selectTime`}
                                />

                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ) : null
                      }
                    </Grid>
                  </Card>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Card className={classes.card}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid container direction="row" justifyContent="flex-start" alignItems='center' spacing={10}>
                        <Grid item>
                          <ImageController
                            name={`images`}
                            filesLimit={5}
                            acceptedFiles
                            form={form}
                            errors={errors}
                          />
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            direction='column'
                            justifyContent='space-between'
                            alignItems='flex-start'
                            spacing={10}
                          >
                            <Grid item >
                              <h1 className={classes.heading}>Add Menu Picture*</h1>
                              <Typography className={classes.tagline}>
                                Upload Picture following this format PNG, JPG (max.
                                800x800px)
                              </Typography>
                            </Grid>
                            <Grid item >
                              <Button className={classes.chooseFileBtn} onClick={ImageController.thumbs}>Choose File</Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid> */}

                <Grid item xs={12}>
                  <Card className={classes.card}>
                    <Grid container
                      justifyContent='flex-start'
                      alignItems='center' spacing={3}>
                      <Grid item>
                        <ImageController
                          name={`images`}
                          filesLimit={5}
                          acceptedFiles
                          form={form}
                          errors={errors}
                          image={item?.image}
                        />
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          direction='row'
                          justifyContent='center'
                          alignItems='center'
                          spacing={10}
                        >
                          <Grid item >
                            <h1 className={classes.title2}>Add Menu Picture</h1>
                            <Typography className={classes.tagline}>
                              Upload Picture following this format PNG, JPG (max.
                              800x800px)
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid
                    container
                    justifyContent={item ? 'space-between' : 'flex-end'}
                    alignItems='center'
                  >
                    {
                      item ?
                        <Grid item>
                          <span className={classes.deleteBtn} onClick={handleDelete}>Delete</span>
                        </Grid>
                        : null
                    }
                    <Grid item>
                      <Grid container justifyContent="flex-end" spacing={3}>
                        <Grid item>
                          <Btn
                            className={classes.cancelBtn}
                            onClick={handleClose}
                          >
                            Cancel
                          </Btn>{' '}
                        </Grid>
                        <Grid item>
                          <Btn type='submit' className={classes.addBtn} name={SAVE_BTN}>
                            {item ? "Update" : "Add"}
                          </Btn>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Fade>
      </Modal>
      <ConfirmationDialog isOpen={deleteModal} title={'Are you sure ?'} text={'Click continue to delete this menu'} onReject={() => setDeleteModal(false)} onResolve={handleSoftDelete} />
      <ConfirmationDialog
        isOpen={promptModal}
        title={'Are you sure ?'}
        text={
          'Do you want to submit menu without adding it in different langPref ?'
        }
        yes={'Yes'}
        no={'No'}
        onReject={() => setPromptModal(false)}
        onResolve={handlePrompt}
      />
    </>
  );
};

export default AddMenuModal;
