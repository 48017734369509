import React, { useEffect, useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormControl, Grid, InputAdornment, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { http } from 'Utils/Http/Http';
import { LocalStorage } from 'Utils/LocalStorage';
import { Btn, ConfirmationDialog, InputController, setProgress } from 'Components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'Utils/Yup';
import { toast } from 'react-toastify';
import ImageController from 'Components/FormControls/Controllers/ImageController';
import { useSelector } from 'react-redux';

const storage = new LocalStorage();
const SAVE_BTN = 'SAVE_BTN';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: "935px",
  maxHeight: "90vh",
  overflow: "scroll",
  borderRadius: '10px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '20px',
};

const useStyle = makeStyles((theme) => ({
  heading: {
    color: '#1A1A1A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '30px',
  },
  tagline: {
    color: '#767676',
    fontFamily: 'Inter',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '26px',
  },
  language: {
    border: '1px solid #D0D5DD',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },
  a3_title: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '22px',
    marginBottom: "4px"
  },
  a3_text: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#667085',
    marginBottom: "24px"
  },
}));

const AddSideDishModal = ({ open, handleClose, item, setSelectedSideDish, sideDishListRefetch, setAddSideDishModal, addItem, languages }) => {
  
  const { t } = useTranslation();

  const storeData = useSelector((state) => state?.globalStore);

  const [deleteModal, setDeleteModal] = useState(false)
  const langPref = languages ? languages : storeData?.companyDetails?.languagePreference;
  const [cancelDisabled, setCancelDisabled] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(storeData?.primaryLanguage)
  const [direction, setDirection] = useState(selectedLanguage == "arabic" ? "rtl" : "ltr")
  const schema = yup.object().shape({
  });



  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: item ? item : null
  });

  const { control, handleSubmit, errors, reset } = form;

  useEffect(() => {
    setSelectedLanguage(storeData?.primaryLanguage ? storeData?.primaryLanguage : null)
    setDirection(selectedLanguage !== "arabic" ? "ltr" : "rtl")
    if (item) {
      reset(item)
    } else {
      reset({})
    }
  }, [open])

  const handleLanguage = (e) => {
    const selectedLanguageTemp = langPref.find(
      (item) => item?.title === e.target.value
    );
    setSelectedLanguage(e.target.value);
    setDirection(selectedLanguageTemp?.direction);
  };

  const handleFormSubmit = async (data) => {

    if (item) {
      try {
        await http.put(`/api/v1/side-dish/${item._id}`, data)
          .then(async (res) => {
            setSelectedSideDish()
            toast.success("Side Dish Updated")
            handleClose()
            sideDishListRefetch()
          });
      } catch (res) {
        toast.error(res?.response?.data?.message)
      }
    } else {
      try {
        await http.post(`/api/v1/side-dish`, data)
          .then(async (res) => {
            toast.success("Side Dish Added")
            if(addItem){
              setAddSideDishModal(false)
            }
            else{
              handleClose();
            }
            sideDishListRefetch()
          });
      } catch (res) {
        toast.error(res?.response?.data?.message)
      }
    }
    setCancelDisabled(false)
    setProgress(SAVE_BTN, false);
  }

  const fileUploadApi = async (values) => {

    if(values[langPref[0].title?.toLowerCase()]?.title?.length == 0){
      toast?.error('Please fill primary language details.')
    }
    else{

      for (let i = 0; i < langPref.length; i++) {
        const langTitle = langPref[i].title?.toLowerCase();
        if (langTitle && values.hasOwnProperty(langTitle) && values[langTitle].title === "") {
          delete values[langTitle];
        }
      }

      // if(values.hasOwnProperty(langPref[1].title?.toLowerCase()) && values[langPref[1].title?.toLowerCase()].title === ""){
      //   delete values[langPref[1].title?.toLowerCase()]
      // }
      // if(values.hasOwnProperty(langPref[2].title?.toLowerCase()) && values[langPref[2].title?.toLowerCase()].title === ""){
      //   delete values[langPref[2].title?.toLowerCase()]
      // }

    var data = {
      price: values?.price,
      images: []
    }
    for (let d = 0; d < langPref.length; d++) {
      data[langPref[d].title?.toLowerCase()] = values[langPref[d]?.title?.toLowerCase()]
    }
    setProgress(SAVE_BTN, true);
    setCancelDisabled(true)
    let formData = new FormData();

    for (var i = 0; i < values.image?.length; i++) {
      formData.append("attachment", values.image[i]);
    }
    if (values.image?.length) {
      try {
        await http.post(`/api/v1/file-upload`, formData)
          .then(async (res) => {
            data['images'] = Array.from(res?.data?.data, (item) => {
              return {
                url: item.location,
                title: item.originalname,
              };
            });
            handleFormSubmit(data)
          });
      } catch (res) {
        toast.error(res?.response?.data?.message)
      }
    } else {
      if (item) {
        data['images'] = item?.images
        handleFormSubmit(data)
      } else {
        toast.error("please select image")
        setCancelDisabled(false)
        setProgress(SAVE_BTN, false);
      }
    }
  }
  }

  const handleDelete = async () => {
    try {
      await http.delete(`/api/v1/side-dish/${item._id}`)
        .then(async (res) => {
          toast.success("Side Dish Deleted.")
          handleClose()
          setSelectedSideDish()
          sideDishListRefetch()
        });
    } catch (res) {
      toast.error(res?.response?.data?.message)
    }
    setDeleteModal(false)
  }

  const classes = useStyle();


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form className='space-y-4' onSubmit={handleSubmit(fileUploadApi)}>
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
                style={{ marginBottom: "20px" }}
              >
                <Grid item>
                  <h1 className={classes.heading}>{item ? t('admin.sideDish.form.editSideDish') : t('admin.sideDish.form.addSideDish')}</h1>
                  <Typography className={classes.tagline}>
                    {t('admin.sideDish.form.addSideDishText')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item>
                      <FormControl
                        variant='standard'
                        sx={{ m: 1, minWidth: 100 }}
                      >
                        <Select
                          variant='outlined'
                          className={classes.language}
                          value={selectedLanguage}
                          size='small'
                          onChange={handleLanguage}
                        >
                          {langPref?.map((item,index) => {
                            return (
                              <MenuItem value={item?.title?.toLowerCase()} key={index}>
                                {item?.title} <span style={{ color: "rgba(0,0,0,0.5)" }}>{item?.isPrimary || item?.title === langPref[0]?.title ? "(Primary)" : null}</span>
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container style={{ direction: direction }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    {langPref?.map((langItem,index) => {
                      return (
                        selectedLanguage === langItem?.title?.toLowerCase() ?
                            <InputController
                              key={index}
                              size="small"
                              dir={direction}
                              control={control}
                              placeholder={t('admin.sideDish.form.addSideDishTitle')}
                              label={t('admin.sideDish.form.SideDishTitle')}
                              name={`${langItem?.title?.toLowerCase()}.title`}
                            />
                          : null
                      )
                    })}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputController
                      size="small"
                      type="number"
                      control={control}
                      placeholder={t('admin.sideDish.form.addSideDishPrice')}
                      label={t('admin.sideDish.form.SideDishPrice')}
                      name={`price`}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{storeData?.companyDetails?.currency}</InputAdornment>,
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} style={{ marginTop: "20px" }}>
                    <h3 className={classes.a3_title}>{t('admin.menu.addItem.itemImage')}*</h3>
                    <p className={classes.a3_text}> {t('admin.menu.addItem.itemImageText')}</p>
                    <ImageControllerFullWidth
                      name={`image`}
                      filesLimit={5}
                      acceptedFiles
                      form={form}
                      errors={errors}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <Grid container
                      justifyContent='flex-start'
                      alignItems='center' spacing={3}>
                      <Grid item>
                        <ImageController
                          name={`image`}
                          filesLimit={5}
                          acceptedFiles
                          form={form}
                          errors={errors}
                          image={item?.images[0]?.url}
                        />
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          direction='row'
                          justifyContent='center'
                          alignItems='center'
                          spacing={10}
                        >
                          <Grid item >
                            <h1 className={classes.title2}>{t('admin.menu.addItem.itemImage')}*</h1>
                            <Typography className={classes.tagline}>
                              {t('admin.menu.addItem.itemImageText')}
                            </Typography>
                          </Grid>
                          {/* <Grid item >
                                                        <Button className={classes.chooseFileBtn} onClick={ImageController.thumbs}>Choose File</Button>
                                                    </Grid> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" alignItems="center">
                {item?._id ?
                  <Grid item>
                    <Button className='btn_delete' onClick={() => setDeleteModal(true)}> Delete item</Button>
                  </Grid>
                  : <Grid item></Grid>
                }
                <Grid item>
                  <Grid container justifyContent="flex-end" spacing={3}>
                    <Grid item>
                      <Button
                        className="btn_cancel"
                        onClick={handleClose}
                        disabled={cancelDisabled}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Btn type='submit' className="btn_add" name={SAVE_BTN}>
                        {item ? 'Update' : 'Add'}
                      </Btn>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Fade>
      </Modal>
      <ConfirmationDialog isOpen={deleteModal} title={'Are you sure ?'} text={'Click continue to delete this Side Dish'} onReject={() => setDeleteModal(false)} onResolve={handleDelete} />
    </div>
  )
}

export default AddSideDishModal