import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Divider, Grid, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import signUpSuccess from "../../../Assets/signUpSuccess.svg"
import { useHistory } from 'react-router-dom';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    maxWidth: "500px",
    maxHeight: "100vh",
    borderRadius: "20px",
    bgcolor: 'background.paper',
    // borderRadius:"20px",
    boxShadow: 24,
    padding: "5%"
};

const useStyle = makeStyles((theme) => ({
    card: {
        padding: "10px",
        boxShadow: "1px 2px 6px 2px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
    },
    title: {
        fontFamily: 'Inter',
        fontWeight: "700",
        fontSize: "36px",
        lineHeight: "44px",
        textAlign: "center",
        color: "#000000",
        maxWidth: "300px",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "30px"
    },
}));


const SignUpSuccessModal = ({ open, handleClose }) => {
    const classes = useStyle();
    const history = useHistory()
    const { t } = useTranslation();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            // BackdropProps={{
            //     timeout: 500,
            // }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Grid container style={{ marginTop: "10px", justifyContent: 'center', overflow: 'scroll' }} spacing={5}>
                        <Grid item>
                            <img src={signUpSuccess} alt="signUpSuccess image" />
                        </Grid>
                        <Grid item xs={12}>
                            <h3 className={classes.title} >Account created successfully <br/> Please verify email</h3>
                            <Button variant='contained' color='primary' fullWidth onClick={() => history.push("/sign-in")}>Log In</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    )
}

export default SignUpSuccessModal

