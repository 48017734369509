
import { Grid } from '@mui/material';
import { useTheme } from '@mui/system';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    navLink: {
        // backgroundColor: theme.palette.grey[100],
        color: '#667085',
        display: 'flex',
        alignItems: 'center',
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "18px"
    },
    navLinkActive: {
        // background: 'rgba(229, 252, 242, 1)',
        color: theme.palette.primary.main,
    },
}));

function Sidebar({ links }) {
    const theme = useTheme();
    const classes = useStyles();

    return (
        <nav aria-label="Sidebar" className="sticky divide-y divide-gray-300">
            <div className="pb-8 space-y-1">
                {links.map(({ current, Icon, name, path }) => (
                    <NavLink key={name} to={path} activeClassName={classes.navLinkActive} className={classes.navLink} alignItems="flex-start">
                        <Grid container style={{
                            alignItems: 'center',
                            padding: "12px 0px"
                        }}>
                            <Grid item style={{
                                marginRight: '10px',
                            }}>
                                <Icon className="flex-shrink-0 -ml-1 h-6 w-6" aria-hidden="true" />
                            </Grid>
                            <Grid item>
                                <span className="truncate">{name}</span>
                            </Grid>
                        </Grid>
                    </NavLink>
                ))}
            </div>
        </nav>
    );
}

export default Sidebar;
