import { Button, Grid, Hidden } from '@mui/material'
import React from 'react'
import styles from "./Home.module.css"
import EmenuLogo from "../../Assets/EmenuLogo.svg"
import { useHistory } from 'react-router-dom'
const HomePage = () => {
    // const styles = useStyles()
    const history=useHistory()

    return (
        <div>
            <Grid container className={styles.main} alignItems="center">
                <Grid container className={styles.backGround} justifyContent="flex-end">
                    <Grid item xs={12} md={7} lg={8} className={styles.right}></Grid>
                </Grid>
                <Grid container className={styles.header} justifyContent="space-between">
                    <Grid item>
                        <img src={EmenuLogo} alt="EmenuLogo" />
                    </Grid>
                    <Grid item>
                        <Button className={styles.btn_logIn} color="primary" onClick={()=>history.push("/sign-in")}>Log In</Button>
                        <Button className={styles.btn_signUp} variant='contained' color="primary" onClick={()=>history.push("/sign-up")}>Sign Up</Button>
                    </Grid>
                </Grid>
                <Grid container className={styles.front}>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className={styles.left}>
                        <h2 className={styles.title}>Welcome To Vmenu</h2>
                        <p className={styles.text}>Create digital menu for your restaurant, coffee shops, in room dining, pool side menu and many others. In this new era let’s go paper free.</p>
                        <Button variant='contained' className={styles.btn_started} color="primary">Get Started</Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default HomePage