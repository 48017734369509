import { Button, CircularProgress, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import AddItemModal from './AddItem/AddItemModal'
import ItemCard from './ItemCard'
import AddIcon from '@mui/icons-material/Add';
import { ITEMSLIST } from 'Constants/QueriesKeys';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import { LocalStorage } from 'Utils/LocalStorage';
import { useSelector } from 'react-redux';

const storage = new LocalStorage();


const useStyle = makeStyles((theme) => ({
    heading: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '25px',
        lineHeight: '30px',
        letterSpacing: '-1.15556px'
    },
    addCategoryBtn: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '24px',
        textTransform: 'none'
    },
    spacing: {
        marginTop: '10%'
    }
}));

const ItemsList = ({ categoryId, subCategoryId, menuId, selectedCategory, setSelectedCategory, selectedSubCategory, setSelectedSubCategory }) => {
    const classes = useStyle();
    const { t } = useTranslation();

    const storeData = useSelector((state) => state?.globalStore);

    const langPref = storeData?.companyDetails?.languagePreference;


    const [addItemModal, setAddItemModal] = useState(false)
    const [itemData, setItemData] = useState('Veg Burger Item');
    const [itemsListArray, setItemsListArray] = useState()
    const [selectedItem, setSelectedItem] = useState()

    const itemsListRender = useQuery([ITEMSLIST, { categoryId,subCategoryId }], () =>
        http.get(`/api/v1/item`, {
            params: {
                holdingSort: true,
                page: 1,
                limit: 10000,
                categoryId: categoryId,
                subCategoryId: subCategoryId,
                menuId: menuId
            }
        }).then((data) => {
            // setCategoryListRefetch(false)
            if (categoryId) {
                setItemsListArray(data?.data?.data?.records);
            }
            return (data)
        }),
    )

    
    return (
        <Grid container>
            <Grid
                container
                justifyContent='space-between'
                alignItems='center'
            >
                <Grid item>
                    <h1 className={classes.heading}>{t('admin.item.item')} </h1>
                </Grid>
                <Grid item>
                    <Button className={classes.addCategoryBtn} onClick={() => setAddItemModal(true)}>
                        <AddIcon fontSize='small' />  {t('admin.item.addItem')}
                    </Button>
                </Grid>
            </Grid>
            {itemsListRender?.isLoading ?
                <Grid container style={{ marginTop: '10%' }}>
                    <h3 style={{ fontSize: "18px" }}><CircularProgress style={{ height: "18px", width: "18px" }} /> {t('common.loading')}</h3>
                </Grid>
                :
                itemsListArray?.length ?
                    <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.spacing}>
                        {itemsListArray && itemsListArray?.map((itemDetails,index) => {
                            return (
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom: "15px" }} key={index}>
                                    <ItemCard 
                                    itemData={itemDetails} 
                                    langPref={langPref} 
                                    setSelectedItem={setSelectedItem} 
                                    setAddItemModal={setAddItemModal} 
                                    refetchItemsList={() => itemsListRender.refetch()}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                    :
                    <Grid container style={{ marginTop: '10%', height: "100%" }} justifyContent='center' alignItems='center'>
                        <h2>No Results Found</h2>
                    </Grid>
            }
            <AddItemModal
                open={addItemModal}
                handleClose={() => { setAddItemModal(false); setSelectedItem() }}
                selectedItem={selectedItem}
                languages={langPref} 
                menuId={menuId}
                categoryId={categoryId}
                subCategoryId={subCategoryId}
                refetchItemsList={() => itemsListRender.refetch()}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                selectedSubCategory={selectedSubCategory}
                setSelectedSubCategory={setSelectedSubCategory}
            />
        </Grid>
    )
}

export default ItemsList